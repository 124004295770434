import React from 'react';

import {
  Form,
  Input,
  Tooltip,
  Icon,
  Button,
  Divider,
  Select,
  Tabs,
} from 'antd';

import { fireBase } from '../../../firebase/firebase';
import { isCPF, formatCPF } from '../../funcs/utils';
import { DEFAULT_ATATAR } from '../../funcs/constants';
import { getGestorId } from 'components/auth/auth-provider';

import API from '../../../services/api/api';
import mensagem from 'components/message/Message';

import './VendedorCRUD.css';
import 'antd/dist/antd.css';

const { Option } = Select;
const { TabPane } = Tabs;
const api = new API();

function makeid() {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

async function uploadAvatar(imagePath) {
  const fileName = makeid();
  imagePath = imagePath.replace('data:image/jpeg;base64,', '');
  imagePath = imagePath.replace('data:image/png;base64,', '');

  try {
    const res = await fireBase
      .storage()
      .ref('/vendedores/avatars/')
      .child(fileName)
      .putString(imagePath, 'base64', { contentType: 'image/jpeg' });

    const url = await res.task.snapshot.ref.getDownloadURL();
    return {
      success: true,
      url,
    };
  } catch (error) {
    console.clear();
    console.log(error);
    return {
      success: false,
      url: '',
    };
  }
}

class VendedorCRUD extends React.Component {
  state = {
    activeKey: 'geral',
    confirmDirty: false,
    autoCompleteResult: [],
    empresas: [],
    setores: [],
    selecteds: [],
    setorSelecinado: '',
    loading: false,
    loadingButton: false,
    edited: false,
    fileList: [],
    photoURL: DEFAULT_ATATAR,
    data: {
      key: this.props.record.key,
      ativo: true,
      ativoStr: 'Sim',
      apelido: '',
      nome: '',
      cpf: '',
      email: '',
      endereco: {
        logradouro: '',
        bairro: '',
        cidade: '',
        uf: '',
        telefone: '',
      },
    },
  };

  constructor(props) {
    super(props);
    this.cancelClick = this.cancelClick.bind(this);
    this.selectEmpresaChange = this.selectEmpresaChange.bind(this);
    this.selectSetorChange = this.selectSetorChange.bind(this);
    this.getBase64 = this.getBase64.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.btnUploadClick = this.btnUploadClick.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.changeLoadingStatus = this.changeLoadingStatus.bind(this);
  }

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  btnUploadClick() {
    this.refs.myFile.click();
  }

  tabChange(activeKey) {
    this.setState({ activeKey });
  }

  handleChange(e) {
    this.getBase64(e.target.files[0], (photoURL) => {
      this.setState({
        photoURL,
        loading: false,
        edited: true,
      });
    });
  }

  changeLoadingStatus(loading) {
    this.setState({ loadingButton: loading });
    if (this.props.onCloseHandle) {
      this.props.onCloseHandle(!loading);
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const _this = this;
        mensagem.printFormErrors(err, 3, function (requireds) {
          _this.setState({ activeKey: 'geral' });
        });

        return;
      }

      this.changeLoadingStatus(true);

      if (!this.state.photoURL || this.state.photoURL === DEFAULT_ATATAR) {
        mensagem.openNotificationWithIcon(
          'error',
          'Cadastro incompleto',
          'Faça o upload da foto do vendedor'
        );
        this.changeLoadingStatus(false);
        return;
      }

      if (this.state.selecteds.length === 0) {
        mensagem.openNotificationWithIcon(
          'error',
          'Cadastro incompleto',
          'Selecione a empresa'
        );
        this.changeLoadingStatus(false);
        return;
      }

      const empresaVendedor = [];

      if (!Array.isArray(this.state.selecteds)) {
        this.state.selecteds = [this.state.selecteds];
      }

      this.state.selecteds.forEach((obj) => {
        const empresa = this.state.empresas.filter((item) => {
          return item.key === obj;
        })[0];

        const empVendedor = {
          id: empresa.key,
          nome: empresa.props.children,
        }

        if (this.state.setorSelecinado) {
          empVendedor.setor = this.state.setorSelecinado;
        }
        empresaVendedor.push(empVendedor);
      });


      if (this.state.setores.length > 0 && !this.state.setorSelecinado) {
        mensagem.openNotificationWithIcon(
          "error",
          "Setor não informado",
          "A empresa selecionada utiliza setores. Você precisa definir o setor do vendedor, neste caso."
        );
        this.changeLoadingStatus(false);
        return;
      }

      if (!isCPF(values.cpf)) {
        mensagem.openNotificationWithIcon(
          'error',
          'CPF inválido',
          'O CPF informado não está correto.'
        );
        this.changeLoadingStatus(false);
        return;
      }
      values.cpf = formatCPF(values.cpf);

      let _upload = {};
      if (this.state.edited) {
        _upload = await uploadAvatar(this.state.photoURL);
      }

      if (this.state.edited && !_upload.success) {
        mensagem.openNotificationWithIcon(
          'error',
          'Erro',
          'Erro ao tentar salvar a foto do vendedor. Tente novamente'
        );
        this.changeLoadingStatus(false);
        return;
      }

      const item = {
        avatar: _upload.url ? _upload.url : this.state.photoURL,
        ativo: values.ativo.toLocaleLowerCase() === 'sim' ? true : false,
        apelido: values.apelido,
        nome: values.nome,
        cpf: values.cpf,
        email: values.email,
        empresa: {
          ...empresaVendedor[0],
        },
        endereco: {
          logradouro: values.endereco,
          bairro: values.bairro,
          cidade: values.cidade,
          uf: values.uf,
          telefone: values.telefone,
        },
        gestorId: getGestorId(),
      };

      let res;
      if (!this.props.editMode) {
        res = await api.cadastrarVendedor(item);
      } else {
        // Consulto o status do vendedor para ver se ele entrou na fila de atendimento
        // ou mesmo em atendimento durante o processo de edição

        const checkVendedor = await api.getVendedorById(this.props.record.key);

        if (
          checkVendedor.status.emAtendimento ||
          checkVendedor.status.descricao.includes('fila')
        ) {
          const message = checkVendedor.status.emAtendimento
            ? ` pois está em atendimento`
            : ` pois está na fila de atendimento`;
          mensagem.openNotificationWithIcon(
            'error',
            'O vendedor não pode receber alterações no momento',
            message
          );
          this.changeLoadingStatus(false);
          return;
        }

        // Se estamos editando e o vendedor já estiver em uma fila
        // de atendimento e foi trocado de empresa, os dados 'realtime'
        // precisam zerar, para que ele apareça na fila da nova empresa
        // com dados novinhos em folha, sem considerar a fila antiga
        const teveAlteracaoEmpresa =
          this.props.record.empresa.id !== item.empresa.id;
        if (teveAlteracaoEmpresa) {
          this.definirStatusZerado(item);
        }

        res = await api.atualizarVendedor(item, this.props.record.key);
      }

      if (!res.recorded) {
        const title = res.duplicated ? 'Duplicação' : 'Erro';
        mensagem.openNotificationWithIcon(
          'error',
          title,
          res.message
        );

        this.setState({ loadingButton: false });
      } else {
        mensagem.openNotificationWithIcon(
          'success',
          'Perfeito!',
          res.message,
          3
        );

        item['key'] = res.id ?? this.props.record.key;
        item.ativoStr = item.ativo ? 'Sim' : 'Não';

        this.setState({ loadingButton: true });
        this.props.handleOk(item);
      }
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  definirStatusZerado(item) {
    item['status'] = {
      data: new Date(),
      descricao: 'casa',
      icon: 'home',
      order: 0,
      posicao: 0,
      emAtendimento: false,
      furouFila: false,
    };
  }

  componentDidMount() {
    const { record, editMode } = this.props;
    const empresas = [];

    this.props.record.empresas.forEach((item) => {
      empresas.push(
        <Option key={item.key} loginName={item.login.login}>
          {item.nomeFantasia}
        </Option>
      );
    });

    this.setState({ empresas });

    const selecteds = [];
    const setores = [];
    let setorSelecinado = undefined;
    
    if (this.props.editMode && this.props.record) {
      selecteds.push(this.props.record.empresa.id);
      this.props.record.empresas.forEach(emp => {
        if (emp.key === this.props.record.empresa.id && emp.setores !== undefined) {         
          for (let index2 = 0; index2 < emp.setores.length; index2++) {
            const setor = emp.setores[index2];
            setores.push(<Option key={setor}>{setor}</Option>);
          }
        }
      });
    
      setorSelecinado = this.props.record.empresa.setor;

      this.setState({
        photoURL: record.avatar,
        selecteds,
        setorSelecinado,
        setores,
        data: {
          key: record.key,
          ativo: record.ativo,
          ativoStr: record.ativoStr,
          apelido: record.apelido,
          nome: record.nome,
          cpf: record.cpf,
          email: record.email,
          endereco: record.endereco
        },
      });
    } else {
      // Só seleciono automaticamente se o length for igual a 1
      // para evitar erros de cadastramento
      if (this.props.empresas.length === 1) {
        selecteds.push(this.props.empresas[0].id);
        this.setState({ selecteds });
      }
    }
  }

  cancelClick() {
    this.props.handleCancel();
  }

  selectEmpresaChange(value) {
    const setores = [];

    for (let index = 0; index < this.props.record.empresas.length; index++) {
      const empresa = this.props.record.empresas[index];
      if (empresa.key === value) {
        if (empresa.setores) {
          for (let index2 = 0; index2 < empresa.setores.length; index2++) {
            const setor = empresa.setores[index2];
            setores.push(<Option key={setor}>{setor}</Option>);
          }
        }
      }
    }
    this.setState({ selecteds: value, setores, setorSelecinado: '' });
  }

  selectSetorChange(value) {
    this.setState({ setorSelecinado: value });
  }

  render() {
    const { photoURL } = this.state;

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className='ant-upload-text'></div>
      </div>
    );

    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <div>
        <div className='clearfix avatarVendedorImgBckground'>
          <input
            className='uploadComponent'
            type='file'
            name='myFile'
            ref='myFile'
            onChange={this.handleChange}
          />
          {photoURL ? (
            <div>
              <img
                src={photoURL}
                alt='avatar'
                className='avatarVendedorImg'
                onClick={this.btnUploadClick}
              />
              <Icon
                type='camera'
                className='avatarVendedorIcon'
                onClick={this.btnUploadClick}
              />
            </div>
          ) : (
            uploadButton
          )}
        </div>

        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Tabs activeKey={this.state.activeKey} onChange={this.tabChange}>
            <TabPane tab='Geral' key='geral'>
              <Form.Item
                label={
                  <span>
                    Ativo&nbsp;
                    <Tooltip title='Esse vendedor está ativo?'>
                      <Icon type='question-circle-o' />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('ativo', {
                  initialValue: this.state.data.ativoStr,
                  rules: [
                    {
                      required: true,
                      message: 'Informe se o Vendedor está ativo',
                    },
                  ],
                })(
                  <Select style={{ width: 120 }}>
                    <Option value='sim'>Sim</Option>
                    <Option value='nao'>Não</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                ref='selectEmpresa'
                label={
                  <span>
                    Empresa/Loja&nbsp;
                    <Tooltip title='Escolha a empresa na qual o vendedor trabalha'>
                      <Icon type='question-circle-o' />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('empresas', {
                  initialValue: this.state.selecteds,
                  rules: [
                    {
                      required: true,
                      message: 'selecione pelo menos uma Empresa',
                    },
                  ],
                })(
                  <Select
                    mode="single"
                    style={{ width: "100%" }}
                    placeholder="Selecione pelo menos uma empresa"
                    onChange={this.selectEmpresaChange}
                  >
                    {this.state.empresas}
                  </Select>
                )}
              </Form.Item>

              {this.state.setores && this.state.setores.length > 0 ? (
                <Form.Item
                  ref="selectSetor"
                  label={
                    <span>
                      Setor&nbsp;
                      <Tooltip title="Escolha o setor no qual o vendedor trabalha">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator("setores", {
                    initialValue: this.state.setorSelecinado,
                    rules: [
                      {
                        required: this.state.setores.length > 0,
                        message: this.state.setores.length > 0 ? "selecione um setor" : "",
                      },
                    ],
                  })(
                    <Select
                      mode="single"
                      style={{ width: "100%" }}
                      placeholder="Selecione um setor"
                      onChange={this.selectSetorChange}
                    >
                      {this.state.setores}
                    </Select>
                  )}
                </Form.Item>
              ) : (
                ''
              )}


              <Form.Item
                label={
                  <span>
                    Apelido&nbsp;
                    <Tooltip title='Qual é o apelido?'>
                      <Icon type='question-circle-o' />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('apelido', {
                  initialValue: this.state.data.apelido,
                  rules: [
                    {
                      // required: true,
                      // whitespace: true,
                      message: 'Limite máximo de 15 caracteres para o apelido',
                      max: 15,
                    },
                    {
                      required: true,
                      message: 'Informe o apelido',
                      whitespace: true,
                    },
                  ],
                })(<Input />)}
              </Form.Item>

              <Form.Item
                ref='txtNome'
                label={
                  <span>
                    Nome Completo&nbsp;
                    <Tooltip title='Qual é o nome?'>
                      <Icon type='question-circle-o' />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('nome', {
                  initialValue: this.state.data.nome,
                  rules: [
                    {
                      required: true,
                      message: 'Informe o nome',
                      whitespace: true,
                    },
                  ],
                })(<Input />)}
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    CPF&nbsp;
                    <Tooltip title='Qual é o CPF?'>
                      <Icon type='question-circle-o' />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('cpf', {
                  initialValue: this.state.data.cpf,
                  rules: [
                    {
                      required: true,
                      message: 'Informe o CPF',
                      whitespace: false,
                    },
                  ],
                })(<Input />)}
              </Form.Item>

              <Form.Item label='E-mail' ref='txtEmail'>
                {getFieldDecorator('email', {
                  initialValue: this.state.data.email,
                  rules: [
                    {
                      type: 'email',
                      message: 'Email não é válido',
                    },
                    {
                      required: true,
                      message: 'Por favor, informe o email',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </TabPane>

            <TabPane tab='Endereço' key='endereco'>
              <Form.Item label='Telefone'>
                {getFieldDecorator('telefone', {
                  initialValue: this.state.data.endereco
                    ? this.state.data.endereco.telefone
                    : '',
                  rules: [{ required: false, message: 'Informe o telefone' }],
                })(<Input style={{ width: '100%' }} />)}
              </Form.Item>

              <Form.Item label='Endereço'>
                {getFieldDecorator('endereco', {
                  initialValue: this.state.data.endereco
                    ? this.state.data.endereco.logradouro
                    : '',
                  rules: [{ required: false, message: 'Informe o endereço' }],
                })(<Input style={{ width: '100%' }} />)}
              </Form.Item>

              <Form.Item label='Bairro'>
                {getFieldDecorator('bairro', {
                  initialValue: this.state.data.endereco
                    ? this.state.data.endereco.bairro
                    : '',
                  rules: [{ required: false, message: 'Informe o bairro' }],
                })(<Input style={{ width: '50%' }} />)}
              </Form.Item>

              <Form.Item label='Cidade'>
                {getFieldDecorator('cidade', {
                  initialValue: this.state.data.endereco
                    ? this.state.data.endereco.cidade
                    : '',
                  rules: [{ required: false, message: 'Informe a cidade' }],
                })(<Input style={{ width: '70%' }} />)}
              </Form.Item>

              <Form.Item label='UF'>
                {getFieldDecorator('uf', {
                  initialValue: this.state.data.endereco
                    ? this.state.data.endereco.uf
                    : '',
                  rules: [{ required: false, message: 'Informe a UF' }],
                })(<Input style={{ width: '20%' }} />)}
              </Form.Item>
            </TabPane>
          </Tabs>

          <Form.Item {...tailFormItemLayout}>
            <Button
              type='primary'
              htmlType='submit'
              loading={this.state.loadingButton}
            >
              Salvar
            </Button>
            <Divider type='vertical' />
            <Button onClick={this.cancelClick} disabled={this.state.loadingButton}>Cancelar</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create()(VendedorCRUD);
