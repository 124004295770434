import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { FormItem, Select, InputNumber, DatePicker } from "formik-antd";
import { Row, Col, Button, Icon, Switch } from "antd";
import Text from "antd/lib/typography/Text";
import { currencyFormatter, currencyParser, toLocale } from "../helperFunctions";
import PesosRow from "./PesosRow";
import vendedorDB from "dataManager/dtmVendedor";
import moment from "moment";
import metaDB from "dataManager/dtmMeta";
import "./labelOverwrite.css"
import mensagem from "components/message/Message";

const { Option } = Select;
const { MonthPicker } = DatePicker;
const { RangePicker } = DatePicker;

const validateRequired = (value) => {
  const ret = value ? undefined : "Preenchimento obrigatório";
  return ret;
}

const metaMessageExtra = (value, percent) => {
  let values = [];

  for (let i = 1; i <= 3; i++) {
    values.push(
      <span key={i}>{toLocale(value + (value * percent * i / 100))}{i < 3 ? " > " : ""}</span>
    );
  }

  return (<>
    <span>Metas secundárias: </span>
    <div>
      {values.map(item => item)}
    </div>
  </>);
}

const getPeriodos = (date, quantidade = 4) => {
  const totalDays = moment(date).daysInMonth();
  const diasPorPeriodo = Math.floor(totalDays / quantidade);
  const referenceDay = moment(date).subtract(1, "day");
  const base = new Array(quantidade).fill({});

  const periodos = base.map((item, index) => {
    const inicio = moment(referenceDay).add((index * diasPorPeriodo) + 1, "days");

    const fim = index < (quantidade - 1) ?
      moment(inicio).add(diasPorPeriodo - 1, "days") :
      moment(date).endOf("month");

    const dias = fim.diff(inicio, "days") + 1;
    const peso = Math.floor(dias * 100 / totalDays);

    return { inicio, fim, dias, peso };
  });

  return periodos;
};

const LojaStep = ({ empresas, selecionada, onChange, periodos }) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const [metaSecundaria, setMetaSecundaria] = useState(false);
  const metaAdicional = values.metaAdicional;

  useEffect(() => {
    if (values.pesoTotal > 0) {
      setFieldValue("periodos", values.periodos.map(item => {
        const meta = values.principal * item.peso / 100;
        const metaPorDia = item.metaPorDia ?? new Array(item.dias).fill(Number(Number(meta / item.dias).toFixed(2)));

        return { ...item, meta, metaPorDia };
      }));
    }
  }, [values.principal]);

  useEffect(() => {
    const calculateValue = () => {
      if (values.vendedores.length > 0) {
        const periodosMap = values.periodos.map((item, index) => {
          return {
            ...item,
            // Calcula o valor da meta diária dos vendendores de acordo com o período,
            // dividindo a meta do período pela soma de dias disponíveis (vendedores x dias)
            metaVendedorDia: item.meta > 0 ? item.meta / values.vendedores.reduce((soma, item) => soma + item.dias[index], 0) : 0
          };
        });

        setFieldValue("periodos", periodosMap);

        const vendedores = values.vendedores.map((item, index) => {
          return {
            ...item,
            metasPeriodos: item.dias.map((item, index) => item * periodosMap[index].metaVendedorDia),
            metaTotal: item.dias.map((item, index) => item * periodosMap[index].metaVendedorDia).reduce((soma, valor) => soma + valor, 0)
          };
        });

        setFieldValue("vendedores", vendedores);
      }
    };

    calculateValue();
  }, [values.pesoTotal]);

  useEffect(() => {
    if (values.vendedores.length) {
      setFieldValue('periodos', values.periodos.map(item => {
        const vendedorPorDia = item.vendedorPorDia ?? new Array(item.dias).fill(values.vendedores.length + 1);
        return { ...item, vendedorPorDia };
      }));
    }
  }, [values.vendedores]);

  const [empresa, setEmpresa] = useState(selecionada.key);

  const handleSelectChange = (value) => {
    setEmpresa(value);
    setFieldValue("loja", value);
    loadVendedores(value);
  };

  useEffect(() => {
    if (selecionada.values) {
      const getVendedores = async () => {
        const vendedoresEmpresa = await vendedorDB.loadByEmpresas([{ key: selecionada.key }], true);
        const vendedores = values.vendedores.map(item => {
          const vendedor = vendedoresEmpresa.find((vendedor) => vendedor.key === item.id);

          if (vendedor) {
            return {
              ...item,
              avatar: vendedor.avatar,
              nome: vendedor.nome,
              apelido: vendedor.apelido
            }
          }

          return item;
        });

        setFieldValue("vendedores", vendedores);
      };

      getVendedores();
    }
  }, [selecionada.values]);

  const loadVendedores = async (empresaId) => {
    empresaId = empresaId || empresa;

    const vendedoresEmpresa = await vendedorDB.loadByEmpresas([{ key: empresaId }], false);
    const calendarValue = moment(values.dataMeta).startOf("month");

    setFieldValue("vendedores",
      vendedoresEmpresa.map((item, index) => ({
        id: item.key,
        avatar: item.avatar,
        nome: item.nome,
        apelido: item.apelido,
        dias: getPeriodos(calendarValue).map(item => item.dias),
        metasPeriodos: [],
        metaTotal: 0.0
      })));
  };

  useEffect(() => {
    if (!values.loja) {
      setFieldValue("loja", empresa);
      loadVendedores();
    }
  }, [empresa]);

  const disabledDate = (date) => {
    return date.month() < moment().month() && date.year() <= moment().year();
  };

  const validateData = async (value) => {
    let error = "";
    const date = moment(value);

    const result = await metaDB
      .getByData(date.month() + 1, date.year(), values.loja);

    if (result && !selecionada.values) {
      error = "Essa data já possui meta cadastrada!";
    }

    return error;
  };

  const [periodoRangePicker, setPeriodo] = useState([moment(values.dataMeta).startOf('month'), moment(values.dataMeta).endOf('month')]);

  const handleRangePicker = (value) => {
    const [inicio, fim] = value;

    const dias = moment(fim).diff(inicio, 'days') + 1;

    if (inicio.month() !== moment(values.dataMeta).month()) {
      setFieldValue('dataMeta', inicio);
    }

    if (dias < 28 || dias > 31) {
      mensagem.avisar('Período não pode ser menor que 28 dias e maior que 31.');
      return;
    }

    const { periodos } = values;
    const diasPorPeriodo = 7;
    const diasPeriodoFinal = 7 + (dias - 28);

    for (let i = 0; i < periodos.length; i++) {
      const valueName = `periodos[${i}]`;

      const diasInicio = 6 * i + i;

      const diasFim = i < 3 ?
        6 * (i + 1) + i :
        dias - 1;

      const inicioP = moment(inicio).add(diasInicio, 'days');
      const fimP = moment(inicio).add(diasFim, 'days');

      setFieldValue(`${valueName}.dias`, i < 3 ? diasPorPeriodo : diasPeriodoFinal);
      setFieldValue(`${valueName}.inicio`, inicioP);
      setFieldValue(`${valueName}.fim`, fimP);
    }

    setPeriodo(value);
  }

  const validateRangePicker = async (value) => {
    const [inicio] = value ?? [moment(values.dataMeta).startOf('month')];
    let error = '';

    const result = await metaDB.getByFim(inicio, values.loja);
    if (result && !selecionada.values) {
      error = "O início dessa meta está dentro de outra meta"
    }

    return error;
  };

  const disabledDateRangePicker = (date) => {
    const mes = moment(values.dataMeta).month();
    return date.month() < mes || date.month() > mes + 1;
  };

  const handleMonthPicker = (value) => {
    const periodoRangePicker = [moment(value).startOf('month'), moment(value).endOf('month')];

    setPeriodo(periodoRangePicker);
    setFieldValue('periodoMeta', periodoRangePicker);
  }

  const styleMargin = { marginLeft: 20 }

  return (<>
    <FormItem
      name="dataMeta"
      label="Mês"
      hasFeedback={false}
      validate={validateData}
      style={styleMargin}
    >
      <MonthPicker
        name="dataMeta"
        format="MM/YYYY"
        disabledDate={disabledDate}
        allowClear={false}
        disabled={selecionada.values !== undefined}
        onChange={handleMonthPicker}
      />
    </FormItem>

    <FormItem
      name='periodoMeta'
      label='Início e Fim da Meta'
      style={styleMargin}
      validate={validateRangePicker}
    >
      <RangePicker
        className='rangePickerDuplo'
        name='periodoMeta'
        format='DD/MM/YYYY'
        placeholder={['Data Inicial', 'Data Final']}
        value={periodoRangePicker}
        disabled={selecionada.values !== undefined || values.dataMeta === undefined}
        allowClear={false}
        disabledDate={disabledDateRangePicker}
        onChange={handleRangePicker}
      />
    </FormItem>

    <FormItem name="loja" label={<span>Loja</span>} style={styleMargin}>
      <Select name="loja"
        mode="single"
        style={{ width: "40%" }}
        placeholder="Selecione uma empresa"
        onChange={handleSelectChange}
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }        
      >
        {empresas.map(item => (
          <Option key={item.key}>
            {
                item.nomeFantasia
            }
          </Option>
        ))}
      </Select>
    </FormItem>

    <FormItem name="principal"
      label={<span>Meta Principal</span>}
      // required={true}
      validate={validateRequired}
      style={styleMargin}
    >
      <Row gutter={6}>
        <Col span={12}>
          <InputNumber className="input-number-total"
            name="principal"
            min={0}
            step={1000}
            formatter={currencyFormatter()}
            parser={currencyParser}
            autoFocus
          />
        </Col>

        {!metaAdicional &&
          <Col span={12}>
            <Button type="dashed" onClick={() => {
              setFieldValue("metaAdicional", true);
              setFieldValue("secundaria", values.secundaria < values.principal ? values.principal : values.secundaria);
              setMetaSecundaria(true);

            }}
              style={{ width: '60%' }} disabled={values.principal === 0 || errors.principal}>
              <Icon type="plus" />Adicionar Meta
            </Button>
          </Col>
        }
      </Row>
    </FormItem>

    {metaAdicional && (
      <FormItem name="secundaria"
        label={<span>Meta Adicional</span>}
        extra={
          values.metaSecundariaProporcional
          && values.principal >= 0
          && values.secundariaProporcional >= 0
          && metaMessageExtra(values.principal, values.secundariaProporcional)

          ||

          !values.metaSecundariaProporcional
          && values.principal >= 0
          && "Atenção: para cadastrar uma meta adicional não proporcional, " +
          "você deve informar o valor da seguinte forma: meta principal + " +
          "valor da meta individual dos vendedores por período."
        }
        style={styleMargin}
      >
        {
          values.metaSecundariaProporcional ?
            <InputNumber className="meta-porcentagem"
              name="secundariaProporcional"
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
            />
            :
            <InputNumber
              name="secundaria"
              min={values.principal}
              step={1000}
              formatter={currencyFormatter()}
              parser={currencyParser}
              style={{
                width: 170,
              }}
            />
        }
        <>
          <Switch style={{ marginLeft: "10px" }} size="small" name="metaSecundariaProporcional" checked={values.metaSecundariaProporcional} onChange={(checked) => setFieldValue("metaSecundariaProporcional", checked)} />
          <span style={{ marginLeft: "5px" }}>Valor proporcional</span>
          {metaSecundaria &&
            <Button type="dashed" onClick={() => {
              setFieldValue("metaAdicional", false);
              setMetaSecundaria(false);
            }}
              style={{ marginLeft: "75px", width: '150px' }}>
              <Icon type="minus" />Remover Meta
            </Button>

          }
        </>
      </FormItem>
    )}

    <FormItem name="ticketMedio"
      label={<span>Ticket Médio</span>}
      extra={
        !errors.principal && values.ticketMedio > 0 &&
        (<span>Cerca de {Number((values.principal / values.ticketMedio).toFixed(0)).toLocaleString('pt-BR')} conversões</span>)
      }
      style={styleMargin}
    >
      <InputNumber name="ticketMedio"
        min={0}
        max={values.principal}
        formatter={currencyFormatter()}
        parser={currencyParser}
        style={{
          width: 140,
        }}
      />
    </FormItem>


    <FormItem
      name="numeroAtendimentos"
      label={<span>Nº de Atendimentos</span>}
      style={styleMargin}
    >
      <InputNumber name="numeroAtendimentos"
        min={0}
        style={{
          width: 140
        }}
      />

    </FormItem>

    <FormItem
      name="percentualDeFuga"
      label={<span>Percentual de Fuga</span>}
      style={styleMargin}
    >
      <InputNumber
        name="percentualDeFuga"
        min={0}
        max={100}
        formatter={(value) => `${value}%`}
        parser={(value) => value.replace('%', '')}
        style={{
          width: 140
        }}
      />

    </FormItem>

    <FormItem name="pesos" label="Peso/Semana" style={styleMargin}>
      <PesosRow periodos={periodos} periodoRangePicker={periodoRangePicker} />
    </FormItem>
  </>);
};

export default LojaStep;
