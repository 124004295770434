import DataManager from "./dtm";
import { fbDatabase, fbFirestore } from "../firebase/firebase";
import { getGestorId } from "../components/auth/auth-provider";

async function queryBy10(ids, pagou, converteu) {
  const gestorId = getGestorId();
  let query = fbDatabase
    .collection("indicacoes")
    .where("gestorId", "==", gestorId)
    .where("indicador.id", "in", ids)
    .where("pagou", "==", pagou)
    .where("converteu", "==", converteu);

  const snapshot = await query
    .orderBy("indicador.nome")
    .orderBy("dataCompra")
    .get();

  /** X-TUDO */
  let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmIndicacoesJSX-queryBy10');
  xRef.set({
    collection: 'indicacoes',
    lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
    count: fbFirestore.FieldValue.increment(snapshot.size),
    lastIncrement: snapshot.size
  }, { merge: true }).catch(error => console.error(error));

  if (snapshot.empty) {
    return [];
  }

  const data = snapshot.docs.map((item) => ({
    id: item.id,
    ...item.data(),
  }));

  return data;
}

class IndicacoesDB extends DataManager {
  state = {
    collection: "indicacoes",
  };

  async getByEmpresaIdAndPeriodoAndConverteu(empresaId, dataIni, dataFim, converteu) {
    const snapshot = await fbDatabase
      .collection("indicacoes")
      .where("empresaId", "==", empresaId)
      .where("converteu", "==", converteu)
      .where("dataCompra", ">=", dataIni)
      .where("dataCompra", "<=", dataFim)
      .get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmIndicacoesJSX-IndicacoesDB-getByEmpresaIdAndPeriodoAndConverteu');
    xRef.set({
      collection: 'indicacoes',
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(snapshot.size),
      lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    if (snapshot.empty) {
      return [];
    }

    const data = snapshot.docs.map((item) => ({
      id: item.id,
      ...item.data(),
    }));

    return data;
  }

  async getByGestorIdAndPeriodoAndConverteu(dataIni, dataFim, converteu) {
    const gestorId = getGestorId();
    const snapshot = await fbDatabase
      .collection("indicacoes")
      .where("gestorId", "==", gestorId)
      .where("converteu", "==", converteu)
      .where("dataCompra", ">=", dataIni)
      .where("dataCompra", "<=", dataFim)
      .orderBy("dataCompra")
      .get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmIndicacoesJSX-IndicacoesDB-getByGestorIdAndPeriodoAndConverteu');
    xRef.set({
      collection: 'indicacoes',
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(snapshot.size),
      lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    if (snapshot.empty) {
      return [];
    }

    const data = snapshot.docs.map((item) => ({
      id: item.id,
      ...item.data(),
    }));

    return data;
  }

  async getByIndicadorId(id) {
    const gestorId = getGestorId();
    const snapshot = await fbDatabase
      .collection("indicacoes")
      .where("gestorId", "==", gestorId)
      .where("indicador.id", "==", id)
      .orderBy("indicador.nome")
      .orderBy("dataCompra")
      .get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmIndicacoesJSX-IndicacoesDB-getByIndicadorId');
    xRef.set({
      collection: 'indicacoes',
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(snapshot.size),
      lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    if (snapshot.empty) {
      return [];
    }

    const data = snapshot.docs.map((item) => ({
      id: item.id,
      ...item.data(),
    }));

    return data;
  }

  async getByIndicadorId2(id, pagou, converteu) {
    const gestorId = getGestorId();
    const snapshot = await fbDatabase
      .collection("indicacoes")
      .where("gestorId", "==", gestorId)
      .where("indicador.id", "==", id)
      .where("pagou", "==", pagou)
      .where("converteu", "==", converteu)
      .orderBy("indicador.nome")
      .orderBy("dataCompra")
      .get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmIndicacoesJSX-IndicacoesDB-getByIndicadorId2');
    xRef.set({
      collection: 'indicacoes',
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(snapshot.size),
      lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    if (snapshot.empty) {
      return [];
    }

    const data = snapshot.docs.map((item) => ({
      id: item.id,
      ...item.data(),
    }));

    return data;
  }

  async loadByIndicadores(indicadores, pagou, converteu) {
    let ids = [];
    let data = [];
    for (let index = 0; index < indicadores.length; index++) {
      ids.push(indicadores[index].key);
      if (ids.length >= 10) {
        const ljs = await queryBy10(ids, pagou, converteu);
        data.push.apply(data, ljs);
        ids = [];
      }
    }
    if (ids.length > 0) {
      const ljs = await queryBy10(ids, pagou, converteu);
      data.push.apply(data, ljs);
    }
    return data;
  }

  async baixarIndicacoes(ids) {
    const batch = fbDatabase.batch();
    for (let index = 0; index < ids.length; ++index) {
      const dcRef = fbDatabase.collection("indicacoes").doc(ids[index]);
      batch.update(dcRef, {
        pagou: true,
      });
    }

    return new Promise((resolve, reject) => {
      batch
        .commit()
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          reject(false);
        });
    });
  }
}

const indicacoesDB = new IndicacoesDB();
export default indicacoesDB;
