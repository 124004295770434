import React, { Component } from 'react';

class LoginHomeBluve extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filaUrl: 'URL_DA_SUA_FILA_AQUI', // Substitua pelo URL correto da sua fila
    };
  }

  render() {
    return (
      <div className="dropdown">
        <button className="dropbtn">
          Login <i className="fa fa-caret-down"></i>
        </button>
        <div className="dropdown-content">
          <a href="/login">
            <i className="fa fa-sign-in"></i> Gestor
          </a>
          <a href={this.state.filaUrl} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-user"></i> Fila
          </a>
        </div>
      </div>
    );
  }
}

export default LoginHomeBluve;
