import React from 'react';
import './editableTable.css';
import { Avatar, Table, Input, Form, Button, Icon, message } from 'antd';
import Highlighter from 'react-highlight-words';


const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    state = {
        editing: false,
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    onKeyDownEvt = (e) => {
        const isNumber = isFinite(e.nativeEvent.key);
        if (isNumber) {
            this.toggleEdit();
        }
    }

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            // Não precisa desse código de jeito nenhum
            // pois é necessário chamar sempre o toggleEdit e o handleSave
            // if (error && error[e.currentTarget.id]) {
            //     return;
            // }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex } = this.props;
        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `informe`,
                        },
                    ],
                })(<Input
                    ref={node => (this.input = node)} 
                    onPressEnter={this.save} 
                    onBlur={this.save} />)}
            </Form.Item>
        ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                    onKeyDown={this.onKeyDownEvt}
                >
                    {children}
                </div>
            );
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                        children
                    )}
            </td>
        );
    }
}

class EditableTable extends React.Component {
    state = {
        loading: false,
        dataSource: [],
        filteredInfo: null,
        sortedInfo: null,
    };

    constructor(props) {
        super(props);
        this.tabbleHandleChange = this.tabbleHandleChange.bind(this);
        this.getColumnSearchProps = this.getColumnSearchProps.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ dataSource: nextProps.dataSource, loading: nextProps.loading });
    }

    UNSAFE_componentWillMount() {
        this.columns = [
            {
                title: "Foto",
                dataIndex: "avatar",
                key: "avatar",
                width: 90,
                render: avatar => (
                    <span>
                        <Avatar size={52} src={avatar} />
                    </span>
                )
            },
            {
                title: "Apelido",
                dataIndex: "apelido",
                key: "apelido",
                width: 350,
            },
            {
                tabindex: "0",
                title: "Dias Trabalhados Semana 1",
                dataIndex: "diasTrabalhadosSemana1",
                key: "diasTrabalhadosSemana1",
                editable: true,
                render: (text, record) => (
                    <Input
                        className="wrapInputValue"
                        value={text}
                    />
                ),
            },
            {
                tabindex: "1",
                title: "Dias Trabalhados Semana 2",
                dataIndex: "diasTrabalhadosSemana2",
                key: "diasTrabalhadosSemana2",
                editable: true,
                render: (text, record) => (
                    <Input
                        className="wrapInputValue"
                        value={text}
                    />
                ),
            },
            {
                tabindex: "2",
                title: "Dias Trabalhados Semana 3",
                dataIndex: "diasTrabalhadosSemana3",
                key: "diasTrabalhadosSemana3",
                editable: true,
                render: (text, record) => (
                    <Input
                        className="wrapInputValue"
                        value={text}
                    />
                ),
            },
            {
                tabindex: "3",
                title: "Dias Trabalhados Semana 4",
                dataIndex: "diasTrabalhadosSemana4",
                key: "diasTrabalhadosSemana4",
                editable: true,
                render: (text, record) => (
                    <Input
                        className="wrapInputValue"
                        value={text}
                    />
                ),
            },
        ]
    }

    getColumnSearchProps = (dataIndex, dataName) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Procurar ${dataName}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Procurar
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Limpar
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    tabbleHandleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    componentDidMount() {
        this.setState({ dataSource: this.props.dataSource });
    }

    handleDelete = key => {
        const dataSource = [...this.state.dataSource];
        const newData = dataSource.filter(item => item.key !== key);
        this.setState({ dataSource: newData });
        if (this.props.callback) {
            this.props.callback(newData);
        }
    };

    handleSave = row => {
        if (row.valor === "0") {
            message.warning("Valor zerado não é permitido");
            return;
        }

        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);

        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData });
        if (this.props.callback) {
            this.props.callback(newData);
        }
    };

    render() {
        const { dataSource } = this.state;
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });
        return (
            <div>
                <Table
                    loading={this.state.loading}
                    className="editableTableObj"
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    onChange={this.tabbleHandleChange}
                    pagination={false}
                />
            </div>
        );
    }
}

const EditableFormTable = Form.create()(EditableTable);

export default EditableFormTable;