import React from 'react';
import { Col, Card, Skeleton, Statistic } from "antd";
import './Styles.css';


const ColComponent = (props) => {

    const { title, value, suffix, span, className } = props;

    return (
        <Col span={span}>
            <Card className={className}>
                <Skeleton
                    active
                    title={false}
                    loading={false}
                    paragraph={{ width: ["90%", "50%"] }}
                >
                    <Statistic
                        className='realtime-skeleton'
                        title={
                            <span>{title}</span>
                        }
                        value={value}
                        suffix={suffix}
                    />
                </Skeleton>
            </Card>
        </Col>
    );
}

export default ColComponent;