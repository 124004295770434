import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Button, Table } from 'antd';
import { InputNumber } from 'formik-antd';
import moment from 'moment';
import { toLocale } from '../helperFunctions';
import ImportarPercentuais from './ImportarPercentuaisModal';

const meses = [
  'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
  'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
]

const diaSemana = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

let interval;


const PesoVendedorPorDia = () => {
  const { values, setFieldValue, errors } = useFormikContext();

  const { periodos } = values;
  const [dias, setDias] = useState(periodos.reduce((total, periodo) => total + periodo.dias, 0));


  const [dataSource, setDataSource] = useState(null);
  const [periodo, setPeriodo] = useState(null);
  const [periodoSelecionado, setPeriodoSelecionado] = useState(0);
  const [pesoTotalPeriodo, setPesoTotalPeriodo] = useState(100);
  const [importarPercentuais, setImportarPercentuais] = useState(false);

  const columns = [
    {
      title: 'Dia',
      dataIndex: 'dia',
      key: 'dia',
    },
    {
      title: 'Peso Diário',
      dataIndex: 'pesoDiario',
      key: 'pesoDiario',
      render: (text, { index, pesoDiario }) => (
        <div>
          <InputNumber
            min={0}
            max={100}
            decimalSeparator=","
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            value={Number(pesoDiario)}
            onChange={(value) => handlePesoChange(value, index)}
          />
        </div>
      )
    },
    {
      title: 'Meta do Dia',
      dataIndex: 'metaDia',
      key: 'metaDia',
      render: (text) => <span>{toLocale(text)}</span>
    },
    {
      title: 'Número de Vendedores',
      dataIndex: 'numeroVendedores',
      key: 'numeroVendedores',
      render: (text, { index, numeroVendedores }) => (
        <div>
          <InputNumber
            min={0}
            value={numeroVendedores}
            onChange={(value) => handleVendedoresChange(value, index)}
          />
        </div>
      )
    },
    {
      title: 'Meta do Dia por Vendedor',
      dataIndex: 'metaPorVendedor',
      key: 'metaPorVendedor',
      render: (text, { metaDia, numeroVendedores }) => (
        <span>
          {numeroVendedores === 0 ? toLocale(0) : toLocale(metaDia / numeroVendedores)}
        </span>
      )
    },
  ];

  useEffect(() => {
    if (!dataSource) {
      handleSelect(0);
    }
  });

  const handleSelect = (value) => {
    const { periodos } = values;
    const { inicio } = periodos[value];

    const meta = periodos.reduce((total, periodo) => total + periodo.meta, 0);
    //const dias = periodos.reduce((total, periodo) => total + periodo.dias, 0);
    const metaPorDia = periodos.flatMap((periodo) => periodo.metaPorDia);
    const vendedorPorDia = periodos.flatMap((periodo) => periodo.vendedorPorDia);

    const data = [];

    for (let i = 0; i < dias; i++) {
      const date = moment(inicio).add(i, 'days');
      const dia = `${date.format('DD')} de ${meses[date.month()]} de ${date.year()}, ${diaSemana[date.weekday()]}`;

      data.push({
        dia,
        index: i,
        metaDia: metaPorDia[i],
        pesoDiario: Number(Number((metaPorDia[i] * 100) / meta).toFixed(2)),
        numeroVendedores: vendedorPorDia[i]
      });
    }

    setDataSource(data);
    setPeriodo(periodos[value]);
    setPeriodoSelecionado(value);
  }

  const recalcularMetaSemanal = (metaSemanal, percentuaisDiarios, diasPorSemana) => {
    const percentuaisSemanais = [];

    let diaSemana = 1;
    let semana = 0;
    const valorMensalDaMeta = metaSemanal.reduce((acumulador, valor) => acumulador + valor, 0);
    for (let percentual of percentuaisDiarios) {
      const primeiroPercentual = (valorMensalDaMeta / 100) * percentual;

      let segundoPercentual = 0;

      segundoPercentual = (primeiroPercentual / metaSemanal[semana]) * 100;
      percentuaisSemanais.push(segundoPercentual);

      diaSemana++;
      if (diaSemana > diasPorSemana[semana]) {
        diaSemana = 1;
        semana++;
      }
    }
    return percentuaisSemanais;
  };

  const onPercentuaisImportados = (percentuaisDiarios) => {
    debugger;

    const { periodos } = values;
    const meta = periodos.reduce((total, periodo) => total + periodo.meta, 0);

    const newData = dataSource.map((item, i) => {
      return {
        ...item,
        pesoDiario: Number(percentuaisDiarios[i]),
        metaDia: Number(((meta / 100) * Number(percentuaisDiarios[i])).toFixed(2)),
      };
    });

    setDataSource(newData);
  };

  const onImportarPeriodoClick = () => {
    setImportarPercentuais(true);
  }


  const handlePesoChange = (percentual, index) => {
    // const { meta } = periodo;
    const { periodos } = values;
    const meta = periodos.reduce((total, periodo) => total + periodo.meta, 0);

    let pesoTotal = 0;

    const newData = dataSource.map((item, i) => {
      if (i === index) {
        const metaDia = Number(Number(meta * (percentual / 100)).toFixed(2));
        pesoTotal = Number(Number(percentual + pesoTotal).toFixed(2));

        setFieldValue(`periodos[${periodoSelecionado}].metaPorDia[${i}]`, metaDia);

        return {
          ...item,
          pesoDiario: Number(percentual),
          metaDia: metaDia >= 0 ? metaDia : 0,
        }
      } else {
        pesoTotal = Number(Number(item.pesoDiario + pesoTotal).toFixed(2));
        return { ...item };
      }
    });

    setPesoTotalPeriodo(pesoTotal);
    setDataSource(newData);
  }

  const validatePesoTotal = () => {
    let error;

    if (pesoTotalPeriodo > 100) {
      error = 'Deve ser igual a 100%';
    } else if (pesoTotalPeriodo < 100) {
      const finalValue = Number(100 - pesoTotalPeriodo).toFixed(2);
      error = `Falta ${finalValue}%`;
    }

    return error;
  }

  const handleVendedoresChange = (numeroVendedores, index) => {
    const newData = dataSource.map((item, i) => {
      if (i === index) {
        setFieldValue(`periodos[${periodoSelecionado}].vendedorPorDia[${index}]`, numeroVendedores);

        return {
          ...item,
          numeroVendedores
        }
      } else {
        return { ...item };
      }
    });

    setDataSource(newData);
  }

  return (
    <div>

      {importarPercentuais ? (
        <ImportarPercentuais
        dias={dias}
        onPercentuaisImportados={onPercentuaisImportados}
        ></ImportarPercentuais>
      ) : (null)}



      <div style={{ marginLeft: 20, marginBottom: '2rem' }}>
        <b>Alerta: Revise com cuidado os períodos e os valores de meta do dia</b>
      </div>

      <div style={{ display: 'flex' }}>
        {/* <div
          style={{
            display: 'flex',
            marginBottom: '1rem',
            marginLeft: 20,
            alignItems: 'center',
            width: '30%'
          }}
        >
          <Select
            defaultValue={0}
            value={periodoSelecionado}
            onChange={handleSelect}
            style={{ width: '40%' }}
          >
            <Option value={0}>Primeira</Option>
            <Option value={1}>Segunda</Option>
            <Option value={2}>Terceira</Option>
            <Option value={3}>Quarta</Option>
          </Select>

          <span>
            Semana
          </span>

        </div> */}

        <div
          style={{
            display: 'flex',
            marginBottom: '1rem',
            marginLeft: 20,
            alignItems: 'center',
            width: '60%'
          }}
        >
          <span>
            Total Peso:
          </span>

          <InputNumber
            name='pesoTotal'
            disabled={true}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            value={pesoTotalPeriodo}
            validate={validatePesoTotal}
            style={{
              marginLeft: '0.5rem',
              width: '20%'
            }}
          />

          &nbsp;

          <Button type='primary' onClick={onImportarPeriodoClick}>Importar percentuais % </Button>

          {errors.pesoTotal && <span style={{ marginLeft: '0.5rem' }} >{errors.pesoTotal}</span>}
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  )
}

export default PesoVendedorPorDia;
