
import React from 'react';
import './EscalaCRUD.css';
import {
  Avatar,
  Form,
  Steps,
  Input,
  Typography,
  Table,
  Select,
  Tooltip,
  Icon,
  Button,
  message,
  Divider,
} from 'antd';
import vendedorDB from '../../../../dataManager/dtmVendedor';
import EditableFormTable from '../../../editableTable/editableTable';
import CurrencyInput from 'react-currency-input';

const { Step } = Steps;
const { Text } = Typography;

const steps = [
  {
    title: 'Loja',
  },
  {
    title: 'Escala',
  },
  {
    title: 'Finalização',
  },
];

function formatarMoeda(valor) {
  valor = parseFloat(valor).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
  return valor;
}

function toFloat(num) {
  const cleanStr = String(num).replace(/[^0-9.,]/g, '');
  let dotPos = cleanStr.indexOf('.');
  let commaPos = cleanStr.indexOf(',');

  if (dotPos < 0) dotPos = 0;

  if (commaPos < 0) commaPos = 0;

  const dotSplit = cleanStr.split('.');
  const commaSplit = cleanStr.split(',');

  const isDecimalDot = dotPos
    && (
      (commaPos && dotPos > commaPos)
      || (!commaPos && dotSplit[dotSplit.length - 1].length === 2)
    );

  const isDecimalComma = commaPos
    && (
      (dotPos && dotPos < commaPos)
      || (!dotPos && commaSplit[commaSplit.length - 1].length === 2)
    );

  let integerPart = cleanStr;
  let decimalPart = '0';
  if (isDecimalComma) {
    integerPart = commaSplit[0];
    decimalPart = commaSplit[1];
  }
  if (isDecimalDot) {
    integerPart = dotSplit[0];
    decimalPart = dotSplit[1];
  }

  return parseFloat(
    `${integerPart.replace(/[^0-9]/g, '')}.${decimalPart.replace(/[^0-9]/g, '')}`,
  );
}

function verificarMetaSemana(valorMeta, nrSemana) {

  if (isNaN(valorMeta)) {
    message.error(`Valor de metas da semana ${nrSemana} não está sendo atingida`);
    return false;
  }
  return true;

}

// const openNotificationWithIcon = (type, title, description, duration) => {
//   if (!duration) {
//     duration = 4; // this is the default time
//   }
//   notification[type]({
//     message: [title],
//     description: [description],
//     duration,
//   });
// };

class EscalaCRUD extends React.Component {
  state = {
    current: 0,
    diasTrabalhados: 5,
    empresas: [],

    metas: {
      faturamento: 0,
      premioFaturamento: 0,
      bonusSobreMeta: 0,
      premioACadaBonusMeta: 0,
      metaTicketMedio: 0,
      premioMetaTicketMedio: 0,
      numeroAtendimentos: 0,
      premioNumeroAtendimentos: 0,
      comissaoVendedor: 0,
      pesoSemana1: 0,
      pesoSemana2: 0,
      pesoSemana3: 0,
      pesoSemana4: 0,

      valorPesoSemana1: 0,
      valorPesoSemana2: 0,
      valorPesoSemana3: 0,
      valorPesoSemana4: 0,

      showWarning: 'block',
      warningColor: 'warning',
      warningText: '100% restantes'
    },

    data: {
      key: this.props.record.key,
      empresa: {
        razaoSocial: ''
      },
    },
    vendedores: [],
    empresaId: [],
    loading: false,

    columns: [
      {
        title: "Foto",
        dataIndex: "avatar",
        key: "avatar",
        width: 90,
        render: avatar => (
          <span>
            <Avatar size={52} src={avatar} />
          </span>
        )
      },
      {
        title: "Vendedor",
        dataIndex: "apelido",
        key: "apelido"
      },

      {
        title: 'Metas',
        children: [
          {
            title: "Semana 1",
            dataIndex: "metaSemana1Str",
            key: "metaSemana1Str"
          },
          {
            title: "Semana 2",
            dataIndex: "metaSemana2Str",
            key: "metaSemana2Str"
          },
          {
            title: "Semana 3",
            dataIndex: "metaSemana3Str",
            key: "metaSemana3Str"
          },
          {
            title: "Semana 4",
            dataIndex: "metaSemana4Str",
            key: "metaSemana4Str"
          },
        ]
      },

    ],
  };

  constructor(props) {
    super(props);
    this.selectHandleChange = this.selectHandleChange.bind(this);
    this.diasTrabalhadosChange = this.diasTrabalhadosChange.bind(this);
    this.onApplyClick = this.onApplyClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
    this.postCallBack = this.postCallBack.bind(this);
    this.validarEscala = this.validarEscala.bind(this);
    this.validarMetas = this.validarMetas.bind(this);
    this.calcularMetasIndividuais = this.calcularMetasIndividuais.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.salvarMeta = this.salvarMeta.bind(this);

    // changes de metas
    this.valorFaturamentoChange = this.valorFaturamentoChange.bind(this);
    this.valorPremioFaturamentoChange = this.valorPremioFaturamentoChange.bind(this);
    this.bonusSobreMetaChange = this.bonusSobreMetaChange.bind(this);
    this.premioACadaBonusMetaChange = this.premioACadaBonusMetaChange.bind(this);
    this.metaTicketMedioChange = this.metaTicketMedioChange.bind(this);
    this.premioMetaTicketMedioChange = this.premioMetaTicketMedioChange.bind(this);
    this.numeroAtendimentosChange = this.numeroAtendimentosChange.bind(this);
    this.premioNumeroAtendimentosChange = this.premioNumeroAtendimentosChange.bind(this);
    this.comissaoVendedorChange = this.comissaoVendedorChange.bind(this);
    this.pesoSemana1Change = this.pesoSemana1Change.bind(this);
    this.pesoSemana2Change = this.pesoSemana2Change.bind(this);
    this.pesoSemana3Change = this.pesoSemana3Change.bind(this);
    this.pesoSemana4Change = this.pesoSemana4Change.bind(this);
    this.calcularRestantePercentualPeso = this.calcularRestantePercentualPeso.bind(this);
  }

  componentDidMount() {
    const { Option } = Select;
    const empresas = [];
    this.props.record.empresas.forEach((item) => {
      empresas.push(<Option key={item.key}>{item.login.login}</Option>);
    });

    this.setState({ empresas: empresas });

    let selecteds = [];
    if (this.props.editMode && this.props.record) {
      selecteds = this.props.record.empresas[0].key;
      this.setState({ selecteds, vendedores: this.props.record.vendedores });
    } else {
      // Só seleciono automaticamente se o length for igual a 1
      // para evitar erros de cadastramento
      if (this.props.record.empresas.length === 1) {
        selecteds.push(this.props.record.empresas[0].key);
      }
      else {
        selecteds = this.props.record.empresa.key;
      }

      this.selectHandleChange(selecteds);
    }
  }

  prev() {
    const vendedores = this.state.vendedores.filter((item) => {
      return item.key !== 'totais';
    });

    const current = this.state.current - 1;
    this.setState({ current, vendedores });
  }

  next() {

    if (this.state.current === 0) {
      if (!this.validarMetas()) {
        message.error('Existem itens obrigatórios ainda não informados na sua projeção, verifique');
        return;
      }

      if ((this.state.metas.pesoSemana1 +
        this.state.metas.pesoSemana2 +
        this.state.metas.pesoSemana3 +
        this.state.metas.pesoSemana4) !== 100) {
        message.error('Ajuste o peso das metas semanais até atingir 100%');
        return;
      }

      if (this.state.vendedores.length === 0) {
        message.error('Empresa não tem vendedores cadastrados ativos');
        return;
      }
    }

    let isOk = true;

    if (this.state.current === 1) {
      if (!this.validarEscala()) {
        message.error('Um ou mais vendedores tem escala de trabalho inválida. Os valores devem estar entre 0 e 7 dias');
        return;
      }
      isOk = this.calcularMetasIndividuais();
    }

    if (isOk) {
      const current = this.state.current + 1;
      this.setState({ current });
    }
  }

  postCallBack(data) {
    this.setState({ vendedores: data });
  }

  async selectHandleChange(value) {
    this.setState({ loading: true });

    const empresa = this.props.record.empresas.filter(item => {
      return item.key === value;
    })[0];


    const empresas = [];
    empresas.push({ key: value });
    let vendedores = [];
    if (!this.props.editMode) {
      vendedores = await vendedorDB.loadByEmpresas(empresas);
    }

    const data = this.state.data;
    data.empresa = empresa;

    this.setState({ data, selecteds: value, vendedores, loading: false });

    if (!this.props.editMode) {
      this.onApplyClick();
    }
  }

  diasTrabalhadosChange(value) {
    this.setState({ diasTrabalhados: Number(value.target.value) });
  }

  onApplyClick() {
    const diasTrabalhados = this.state.diasTrabalhados;
    if (diasTrabalhados < 0 || diasTrabalhados > 15) {
      message.error('Dias trabalhados precisa ser um valor entre 0 e 7');
      return;
    }

    this.setState({ loading: true });
    const vendedores = [];
    this.state.vendedores.forEach(vendedor => {
      const vend = vendedor;
      vend.diasTrabalhadosSemana1 = diasTrabalhados;
      vend.diasTrabalhadosSemana2 = diasTrabalhados;
      vend.diasTrabalhadosSemana3 = diasTrabalhados;
      vend.diasTrabalhadosSemana4 = diasTrabalhados;

      vendedores.push(vend);
    });

    this.setState({ vendedores, loading: false });

  }

  validarEscala() {
    let isValid = true;
    this.state.vendedores.forEach(vendedor => {
      if (
        (!vendedor.diasTrabalhadosSemana1 || vendedor.diasTrabalhadosSemana1 < 0 || vendedor.diasTrabalhadosSemana1 > 7) ||
        (!vendedor.diasTrabalhadosSemana2 || vendedor.diasTrabalhadosSemana2 < 0 || vendedor.diasTrabalhadosSemana2 > 7) ||
        (!vendedor.diasTrabalhadosSemana3 || vendedor.diasTrabalhadosSemana3 < 0 || vendedor.diasTrabalhadosSemana3 > 7) ||
        (!vendedor.diasTrabalhadosSemana4 || vendedor.diasTrabalhadosSemana4 < 0 || vendedor.diasTrabalhadosSemana4 > 7)) {
        isValid = false;
      }
    });
    return isValid;
  }

  validarMetas() {
    return toFloat(this.state.metas.faturamento) > 0 &&
      toFloat(this.state.metas.premioFaturamento) > 0 &&
      this.state.metas.bonusSobreMeta > 0 &&
      toFloat(this.state.metas.premioACadaBonusMeta) > 0 &&
      this.state.metas.comissaoVendedor > 0 &&
      this.state.metas.pesoSemana1 > 0 &&
      this.state.metas.pesoSemana2 > 0 &&
      this.state.metas.pesoSemana3 > 0 &&
      this.state.metas.pesoSemana4 > 0;
  }

  calcularMetasIndividuais() {

    let diasTrabalhadosSemana1 = 0;
    let diasTrabalhadosSemana2 = 0;
    let diasTrabalhadosSemana3 = 0;
    let diasTrabalhadosSemana4 = 0;

    // Primeira coisa: Calcular os dias trabalhados 
    // de todos os vendedores em cada semana
    this.state.vendedores.forEach(vendedor => {
      diasTrabalhadosSemana1 += Number(vendedor.diasTrabalhadosSemana1);
      diasTrabalhadosSemana2 += Number(vendedor.diasTrabalhadosSemana2);
      diasTrabalhadosSemana3 += Number(vendedor.diasTrabalhadosSemana3);
      diasTrabalhadosSemana4 += Number(vendedor.diasTrabalhadosSemana4);
    });

    // Segunda coisa: Dividir a meta da semana 
    // pelo total de dias da respectiva semana
    const metaDiariaSemana1 = (this.state.metas.valorPesoSemana1 / diasTrabalhadosSemana1);
    const metaDiariaSemana2 = (this.state.metas.valorPesoSemana2 / diasTrabalhadosSemana2);
    const metaDiariaSemana3 = (this.state.metas.valorPesoSemana3 / diasTrabalhadosSemana3);
    const metaDiariaSemana4 = (this.state.metas.valorPesoSemana4 / diasTrabalhadosSemana4);

    // Terceira coisa: Multiplicar a meta diária 
    // de cada semana pelos respectivos dias de 
    // trabalho de cada vendedor
    const vendedores = [];
    let totalMetaSemana1 = 0;
    let totalMetaSemana2 = 0;
    let totalMetaSemana3 = 0;
    let totalMetaSemana4 = 0;
    this.state.vendedores.forEach(vendedor => {
      vendedor.metaSemana1 = (Number(vendedor.diasTrabalhadosSemana1) * metaDiariaSemana1);
      vendedor.metaSemana2 = (Number(vendedor.diasTrabalhadosSemana2) * metaDiariaSemana2);
      vendedor.metaSemana3 = (Number(vendedor.diasTrabalhadosSemana3) * metaDiariaSemana3);
      vendedor.metaSemana4 = (Number(vendedor.diasTrabalhadosSemana4) * metaDiariaSemana4);

      totalMetaSemana1 += vendedor.metaSemana1;
      totalMetaSemana2 += vendedor.metaSemana2;
      totalMetaSemana3 += vendedor.metaSemana3;
      totalMetaSemana4 += vendedor.metaSemana4;
      // valores string
      vendedor.metaSemana1Str = formatarMoeda(vendedor.metaSemana1);
      vendedor.metaSemana2Str = formatarMoeda(vendedor.metaSemana2);
      vendedor.metaSemana3Str = formatarMoeda(vendedor.metaSemana3);
      vendedor.metaSemana4Str = formatarMoeda(vendedor.metaSemana4);

      vendedores.push(vendedor);
    });


    let metaIsOk = verificarMetaSemana(totalMetaSemana1, 1);
    if (metaIsOk) {
      metaIsOk = verificarMetaSemana(totalMetaSemana2, 2);
    }
    if (metaIsOk) {
      metaIsOk = verificarMetaSemana(totalMetaSemana3, 3);
    }
    if (metaIsOk) {
      metaIsOk = verificarMetaSemana(totalMetaSemana4, 4);
    }


    const totais = {
      key: 'totais',
      apelido: 'Totais',
      metaSemana1Str: formatarMoeda(totalMetaSemana1),
      metaSemana2Str: formatarMoeda(totalMetaSemana2),
      metaSemana3Str: formatarMoeda(totalMetaSemana3),
      metaSemana4Str: formatarMoeda(totalMetaSemana4),
    }

    vendedores.push(totais);

    if (metaIsOk) {
      this.setState({ vendedores });
    }
    return metaIsOk;
  }

  valorFaturamentoChange(value) {
    const metas = this.state.metas;
    metas.faturamento = value;
    this.setState({ metas });

    if (this.state.metas.pesoSemana1 > 0) {
      const obj = {
        target: {
          value: this.state.metas.pesoSemana1.toString(),
        }
      }
      this.pesoSemana1Change(obj, metas.faturamento);
    }

    if (this.state.metas.pesoSemana2 > 0) {
      const obj = {
        target: {
          value: this.state.metas.pesoSemana2.toString(),
        }
      }
      this.pesoSemana2Change(obj, metas.faturamento);
    }

    if (this.state.metas.pesoSemana3 > 0) {
      const obj = {
        target: {
          value: this.state.metas.pesoSemana3.toString(),
        }
      }
      this.pesoSemana3Change(obj, metas.faturamento);
    }

    if (this.state.metas.pesoSemana4 > 0) {
      const obj = {
        target: {
          value: this.state.metas.pesoSemana4.toString(),
        }
      }
      this.pesoSemana4Change(obj, metas.faturamento);
    }
  }

  valorPremioFaturamentoChange(value) {
    const metas = this.state.metas;
    metas.premioFaturamento = value;
    this.setState({ metas });
  }

  bonusSobreMetaChange(obj) {
    const metas = this.state.metas;
    metas.bonusSobreMeta = Number(obj.target.value);
    this.setState({ metas });
  }

  premioACadaBonusMetaChange(value) {
    const metas = this.state.metas;
    metas.premioACadaBonusMeta = value;
    this.setState({ metas });
  }

  metaTicketMedioChange(value) {
    const metas = this.state.metas;
    metas.metaTicketMedio = value;
    this.setState({ metas });
  }

  premioMetaTicketMedioChange(value) {
    const metas = this.state.metas;
    metas.premioMetaTicketMedio = value;
    this.setState({ metas });
  }

  numeroAtendimentosChange(obj) {
    const metas = this.state.metas;
    metas.numeroAtendimentos = Number(obj.target.value);
    this.setState({ metas });
  }

  premioNumeroAtendimentosChange(value) {
    const metas = this.state.metas;
    metas.premioNumeroAtendimentos = value;
    this.setState({ metas });
  }

  comissaoVendedorChange(obj) {
    debugger;
    const metas = this.state.metas;
    metas.comissaoVendedor = Number(obj.target.value);
    this.setState({ metas });
  }

  pesoSemana1Change(obj, fat) {
    debugger;
    const metas = this.state.metas;
    metas.pesoSemana1 = Number(obj.target.value);

    const faturamento = !fat ? toFloat(metas.faturamento) : toFloat(fat);
    metas.valorPesoSemana1 = (faturamento / 100) * metas.pesoSemana1;
    this.setState({ metas });
    this.calcularRestantePercentualPeso(
      metas.pesoSemana1 +
      metas.pesoSemana2 +
      metas.pesoSemana3 +
      metas.pesoSemana4);
  }

  pesoSemana2Change(obj, fat) {
    const metas = this.state.metas;
    metas.pesoSemana2 = Number(obj.target.value);

    const faturamento = !fat ? toFloat(metas.faturamento) : toFloat(fat);
    metas.valorPesoSemana2 = (faturamento / 100) * metas.pesoSemana2;
    this.setState({ metas });
    this.calcularRestantePercentualPeso(
      metas.pesoSemana1 +
      metas.pesoSemana2 +
      metas.pesoSemana3 +
      metas.pesoSemana4);
  }

  pesoSemana3Change(obj, fat) {
    const metas = this.state.metas;
    metas.pesoSemana3 = Number(obj.target.value);

    const faturamento = !fat ? toFloat(metas.faturamento) : toFloat(fat);
    metas.valorPesoSemana3 = (faturamento / 100) * metas.pesoSemana3;
    this.setState({ metas });
    this.calcularRestantePercentualPeso(
      metas.pesoSemana1 +
      metas.pesoSemana2 +
      metas.pesoSemana3 +
      metas.pesoSemana4);
  }

  pesoSemana4Change(obj, fat) {
    const metas = this.state.metas;
    metas.pesoSemana4 = Number(obj.target.value);

    const faturamento = !fat ? toFloat(metas.faturamento) : toFloat(fat);
    metas.valorPesoSemana4 = (faturamento / 100) * metas.pesoSemana4;
    this.setState({ metas });
    this.calcularRestantePercentualPeso(
      metas.pesoSemana1 +
      metas.pesoSemana2 +
      metas.pesoSemana3 +
      metas.pesoSemana4);
  }

  calcularRestantePercentualPeso(pesoTotal) {
    const metas = this.state.metas;
    const restante = 100 - pesoTotal;
    if (restante === 0) {
      metas.showWarning = 'none';
    } else if (restante < 0) {
      metas.showWarning = 'block';
      metas.warningColor = 'danger';
      metas.warningText = 'Máximo de 100% ultrapassado';
    }
    else {
      metas.showWarning = 'block';
      metas.warningColor = 'warning';
      metas.warningText = restante === 1 ? `${restante}% restante` : `${restante}% restantes`;
    }
    this.setState({ metas });
  }

  async salvarMeta() {

    alert('erro');
    return;

    // if (!this.validarEscala()) {
    //   message.error('Um ou mais vendedores tem escala de trabalho inválida. Os valores devem estar entre 0 e 7 dias');
    //   return;
    // }


    // const date = this.props.record.periodo;
    // const dataIni = new Date(date.getFullYear(), date.getMonth(), 1);
    // const dataFim = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    // dataFim.setHours(23, 59, 59);

    // const gestorId = getGestorId();
    // const empresaId = this.state.selecteds;
    // // const escala = await metaEscalaDB.getByPeriod(dataIni, dataFim, gestorId, empresaId);

    // if (!this.props.editMode && escala) {
    //   message.warning(`Já existe escala para o período e empresa escolhidos.`);
    //   return;
    // }

    // let vendedores = [];
    // if (!this.props.editMode) {
    //   vendedores = this.state.vendedores.map((item) => ({
    //     avatar: item.avatar,
    //     key: item.key,
    //     apelido: item.apelido,
    //     nome: item.nome,
    //     diasTrabalhadosSemana1: item.diasTrabalhadosSemana1,
    //     diasTrabalhadosSemana2: item.diasTrabalhadosSemana2,
    //     diasTrabalhadosSemana3: item.diasTrabalhadosSemana3,
    //     diasTrabalhadosSemana4: item.diasTrabalhadosSemana4,
    //   }));

    //   const empresas = this.props.record.empresas.filter((emp) => {
    //     return emp.key === this.state.selecteds;
    //   });

    //   const empresa = empresas.map((item) => ({
    //     id: item.key,
    //     razaoSocial: item.razaoSocial,
    //     login: item.login.login,
    //   }))[0];

    //   const item = {
    //     gestorId,
    //     dataIni,
    //     dataFim,
    //     empresa
    //   }

    //   let novoId = '';
    //   const res = await metaEscalaDB.add(item);
    //   if (res.recorded === true) {
    //     novoId = res.id;
    //     metaEscalaDB.addVendedores(novoId, vendedores);
    //   }
    // }
    // else {
    //   vendedores = this.state.vendedores.map((item) => ({
    //     id: item.id,
    //     avatar: item.avatar,
    //     key: item.key,
    //     apelido: item.apelido,
    //     nome: item.nome,
    //     diasTrabalhadosSemana1: item.diasTrabalhadosSemana1,
    //     diasTrabalhadosSemana2: item.diasTrabalhadosSemana2,
    //     diasTrabalhadosSemana3: item.diasTrabalhadosSemana3,
    //     diasTrabalhadosSemana4: item.diasTrabalhadosSemana4,
    //   }));
    //   metaEscalaDB.updateVendedores(this.props.record.escalaId, vendedores);
    // }

    // const tipoAtualizacao = this.props.editMode ? 'atualizado' : 'cadastrado';
    // openNotificationWithIcon('success', 'Perfeito!', `Meta de ${this.props.dateStr} foi ${tipoAtualizacao} com sucesso`, 3);
    // this.props.handleOk(vendedores);

  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  cancelClick() {
    this.props.handleCancel();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    // const tailFormItemLayout = {
    //   wrapperCol: {
    //     xs: {
    //       span: 24,
    //       offset: 0,
    //     },
    //     sm: {
    //       span: 16,
    //       offset: 8,
    //     },
    //   },
    // };

    return (
      <div>
        <Steps current={this.state.current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>

        <div className="steps-content">{steps[this.state.current].content}</div>
        <div className="steps-action">
          {this.state.current === 0 && (

            <div className="divMeta">

              <div className="btnCopiarMesAnterior" >
                <Button
                  type='primary'>
                  Copiar mês anterior
              </Button>
              </div>

              <Form {...formItemLayout}>

                <Form.Item
                  ref="selectEmpresa"
                  label={
                    <span>
                      Empresa/Loja&nbsp;
                    <Tooltip title="Escolha a empresa na qual o vendedor trabalha">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator("empresas", {
                    initialValue: this.state.selecteds,
                    rules: [
                      { required: true, message: "selecione uma empresa" },
                    ],
                  })(
                    <Select
                      mode="single"
                      style={{ width: "100%" }}
                      placeholder="Selecione uma empresa"
                      onChange={this.selectHandleChange}
                    >
                      {this.state.empresas}
                    </Select>
                  )}
                </Form.Item>


                <Form.Item
                  label={
                    <span>
                      Faturamento/prêmio&nbsp;
                    <Tooltip title="Qual a meta de faturamento e prêmio por faturamento do mês?">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('faturamento', {
                    initialValue: this.state.metas.faturamento,
                    rules: [{ required: true, message: 'Informe os valores' }],
                  })(
                    <div className="divSideBySideMeta">

                      <CurrencyInput
                        className="wrapValorMeta"
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        value={this.state.metas.faturamento}
                        onChange={this.valorFaturamentoChange} />

                      <CurrencyInput
                        className="wrapValorMeta"
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        value={this.state.metas.premioFaturamento}
                        onChange={this.valorPremioFaturamentoChange}
                      />
                    </div>

                  )}

                </Form.Item>


                <Form.Item

                  label={
                    <span>
                      bônus meta/prêmio&nbsp;
                    <Tooltip title="Qual o percentual da meta sobre bônus e o valor do prêmio a ser pago?">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                  ref="txtBonusMeta">
                  {getFieldDecorator('bonusSobreMeta', {
                    initialValue: this.state.metas.bonusSobreMeta,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor, informe o percentual de bônus sobre meta',
                      },
                    ],
                  })(
                    <div className="divSideBySideMeta">
                      <Input
                        className="wrapPercentualMeta"
                        type="number"
                        value={this.state.metas.bonusSobreMeta}
                        onChange={this.bonusSobreMetaChange}
                      />
                      <label>%</label>

                      <CurrencyInput
                        className="wrapValorMeta"
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        value={this.state.metas.premioACadaBonusMeta}
                        onChange={this.premioACadaBonusMetaChange}
                      />
                    </div>

                  )}
                </Form.Item>


                <Form.Item
                  label={
                    <span>
                      Ticket Médio/prêmio&nbsp;
                  <Tooltip title="Qual a meta de ticket médio e prêmio?">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('metaTicketMedio', {
                    initialValue: this.state.metas.metaTicketMedio,
                    rules: [{ required: false }],
                  })(
                    <div className="divSideBySideMeta">

                      <CurrencyInput
                        className="wrapValorMeta"
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        value={this.state.metas.metaTicketMedio}
                        onChange={this.metaTicketMedioChange}
                      />

                      <CurrencyInput
                        className="wrapValorMeta"
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        value={this.state.metas.premioMetaTicketMedio}
                        onChange={this.premioMetaTicketMedioChange}
                      />
                    </div>
                  )}

                </Form.Item>

                <Form.Item
                  label={
                    <span>
                      Nr atend/prêmio&nbsp;
                  <Tooltip title="Qual a meta de número de atendimentos e prêmio?">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('metaNumeroAtendimentos', {
                    initialValue: this.state.metas.numeroAtendimentos,
                    rules: [{ required: false }],
                  })(
                    <div className="divSideBySideMeta">
                      <Input
                        className="wrapPercentualMeta"
                        type="number"
                        value={this.state.metas.numeroAtendimentos}
                        onChange={this.numeroAtendimentosChange}
                      />

                      <CurrencyInput
                        className="wrapValorMeta"
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        value={this.state.metas.premioNumeroAtendimentos}
                        onChange={this.premioNumeroAtendimentosChange}
                      />
                    </div>
                  )}

                </Form.Item>

                <Form.Item
                  ref='txtComissaoVendedor'
                  label={
                    <span>
                      Comissão Vendedor&nbsp;
                  </span>
                  }
                >
                  {getFieldDecorator('comissaoVendedor', {
                    initialValue: this.state.metas.comissaoVendedor,
                    rules: [{ required: true, message: 'Informe a comissão', whitespace: true }],
                  })(
                    <div className="wrapPercentualComissaoVendedorMeta">
                      <Input
                        value={this.state.metas.comissaoVendedor}
                        onChange={this.comissaoVendedorChange}
                      />
                      <label>%</label>
                    </div>
                  )}
                </Form.Item>
                <Divider />
                <div className="textWarningPesoMeta">
                  <Text
                    style={{ display: this.state.metas.showWarning }}
                    type={this.state.metas.warningColor}
                  >{this.state.metas.warningText}</Text>
                </div>

                <Form.Item
                  label={
                    <span>
                      Peso - Semana 1&nbsp;
                  <Tooltip title="Informe o percentual do peso da semana">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('pesoSemana1', {
                    initialValue: this.state.metas.pesoSemana1,
                    rules: [{ required: true, message: 'Informe os valores' }],
                  })(
                    <div className="divSideBySideMeta">
                      <Input
                        className="wrapPercentualMeta"
                        type="number"
                        min={0} max={100}
                        value={this.state.metas.pesoSemana1}
                        onChange={this.pesoSemana1Change}
                      />
                      <label>%</label>
                      <CurrencyInput
                        disabled={true}
                        className="wrapValorMeta disabledElement"
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        value={this.state.metas.valorPesoSemana1}
                      />
                    </div>
                  )}

                </Form.Item>

                <Form.Item
                  label={
                    <span>
                      Peso - Semana 2&nbsp;
                  <Tooltip title="Informe o percentual do peso da semana">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('pesoSemana2', {
                    initialValue: this.state.metas.pesoSemana2,
                    rules: [{ required: true, message: 'Informe os valores' }],
                  })(
                    <div className="divSideBySideMeta">
                      <Input
                        className="wrapPercentualMeta"
                        type="number"
                        min={0} max={100}
                        value={this.state.metas.pesoSemana2}
                        onChange={this.pesoSemana2Change}
                      />
                      <label>%</label>
                      <CurrencyInput
                        disabled={true}
                        className="wrapValorMeta disabledElement"
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        value={this.state.metas.valorPesoSemana2}
                      />
                    </div>
                  )}

                </Form.Item>

                <Form.Item
                  label={
                    <span>
                      Peso - Semana 3&nbsp;
                  <Tooltip title="Informe o percentual do peso da semana">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('pesoSemana3', {
                    initialValue: this.state.metas.pesoSemana3,
                    rules: [{ required: true, message: 'Informe os valores' }],
                  })(
                    <div className="divSideBySideMeta">
                      <Input
                        className="wrapPercentualMeta"
                        type="number"
                        min={0} max={100}
                        value={this.state.metas.pesoSemana3}
                        onChange={this.pesoSemana3Change}
                      />
                      <label>%</label>
                      <CurrencyInput
                        disabled={true}
                        className="wrapValorMeta disabledElement"
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        value={this.state.metas.valorPesoSemana3}
                      />
                    </div>
                  )}

                </Form.Item>

                <Form.Item
                  label={
                    <span>
                      Peso - Semana 4&nbsp;
                  <Tooltip title="Informe o percentual do peso da semana">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('pesoSemana4', {
                    initialValue: this.state.metas.pesoSemana4,
                    rules: [{ required: true, message: 'Informe os valores' }],
                  })(
                    <div className="divSideBySideMeta">
                      <Input
                        className="wrapPercentualMeta"
                        type="number"
                        min={0} max={100}
                        value={this.state.metas.pesoSemana4}
                        onChange={this.pesoSemana4Change}
                      />
                      <label>%</label>
                      <CurrencyInput
                        disabled={true}
                        className="wrapValorMeta disabledElement"
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        value={this.state.metas.valorPesoSemana4}
                      />
                    </div>
                  )}
                </Form.Item>
              </Form>
            </div>
          )}

          {this.state.current === 1 && (
            <div className="divStepCurrent1">
              <Form {...formItemLayout}>

                <Form.Item
                  ref='txtEmpresa'
                  label={
                    <span>
                      Empresa&nbsp;
                  </span>
                  }
                >
                  {getFieldDecorator('empresa', {
                    initialValue: this.state.data.empresa.login.login,
                    rules: [{ whitespace: true }],
                  })(<Input disabled={true} className="disabledElement" />)}
                </Form.Item>

                <Form.Item
                  ref="diasTrabalhados"
                  label={
                    <span>
                      Dias Trabalhados&nbsp;
                        <Tooltip title="Aplica o valor indicado para todos os vendedores">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator("diasTrabalhados", {
                    initialValue: this.state.diasTrabalhados,
                    rules: [
                      { required: false },
                    ],
                  })(
                    <div className="divSideBySideMeta">
                      <Input value={this.state.diasTrabalhados} type="number" className="wrapDiasTrabahados" onChange={this.diasTrabalhadosChange}></Input>

                      <Button
                        type='primary'
                        onClick={this.onApplyClick}
                      >
                        Aplicar
                        </Button>


                    </div>
                  )}
                </Form.Item>

                <EditableFormTable
                  dataSource={this.state.vendedores}
                  loading={this.state.loading}
                  callback={this.postCallBack}
                />

                {/* <Form.Item {...tailFormItemLayout}>
                    <div className="divEscalaMetaButtons">
                      <Button type="primary" htmlType="submit">Salvar</Button>
                      <Button onClick={this.cancelClick}>Cancelar</Button>
                    </div>
                  </Form.Item> */}
              </Form>
            </div>
          )}

          {this.state.current === 2 && (
            <Table
              className="tableResultadoMetas"
              bordered
              dataSource={this.state.vendedores}
              loading={this.state.loading}
              columns={this.state.columns}
            />

          )}

          {this.state.current === 3 && (
            <label>Eduardo C2B</label>
          )}


          {this.state.current > 0 && (
            <Button
              className="btnAnteriorStepMeta"
              disabled={this.state.loading}
              style={{ marginLeft: 8 }}
              onClick={() => this.prev()}>
              Anterior
            </Button>
          )}

          {(this.state.current === 0 || this.state.current === 1) && (

            <Button type="primary" onClick={() => this.next()} disabled={this.state.loading} loading={this.state.loading}>
              Próximo
            </Button>
          )}
          {this.state.current === steps.length - 1 && (
            <Button loading={this.state.loading} type="primary" onClick={() => this.salvarMeta()}>
              Concluir
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default Form.create()(EscalaCRUD);