import apiFirebase from "components/apiFirebase/apiFirebase";

export function getNextColor(color) {
  if (color === "#fff") {
    color = "#eee";
  } else {
    color = "#fff";
  }
  return color;
}

export async function buildReport() {
  const token = await apiFirebase.getToken();
  const historico = await apiFirebase.retornarUltimosLogin(token);

  const data = [];
  let color = getNextColor();

  historico.gerentes.forEach(hist => {
    data.push({
      tipo: 'Gerente',
      nome: hist.nome,
      email: hist.email,
      ultimoLogin: hist.ultimoLogin ? hist.ultimoLogin : 'Sem histórico disponível',
      color
    });

    color = getNextColor(color);
  });

  historico.empresas.forEach(hist => {
    data.push({
      tipo: 'Loja',
      nome: `${hist.razaoSocial} (${hist.nomeFantasia})`,
      email: hist.email,
      ultimoLogin: hist.ultimoLogin ? hist.ultimoLogin : 'Sem histórico disponível',
      color
    });
    color = getNextColor(color);
  });

  return data;
}
