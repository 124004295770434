import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getGerenteId, getGestorId, getUserType } from 'components/auth/auth-provider';
import { GESTOR } from 'components/funcs/constants';

import {
  createOptions,
  getEmpresas,
  getEmpresasGerente,
  getRotinaVendedor,
  getMonths,
  getVendedores,
  getYears,
  exportarRotinaDoVendedor
} from './RotinaVendedorF';

import {
  Button,
  Calendar,
  Select
} from 'antd';

import BluveLayout from 'components/layout/BluveLayout';
import dayjs from 'dayjs';
import moment from 'moment';
import mensagem from 'components/message/Message';

import './RotinaVendedor.css';

const { Option } = Select;


class RotinaVendedor extends Component {
  state = {
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
    rotinaVendedor: undefined,
    linhaDoTempo: undefined,
    entrada: '--:--',
    saida: '--:--',
    cargaHoraria: '--:--',
    showLinhaDoTempo: false,
    diaLinhaDoTempo: 0
  }

  constructor(props) {
    super(props);

    this.disabledDate = this.disabledDate.bind(this);
    this.changeDisabledDate = this.changeDisabledDate.bind(this);
    this.dateCellRender = this.dateCellRender.bind(this);
    this.onSelectDate = this.onSelectDate.bind(this);
    this.registrarInfoHistorico = this.registrarInfoHistorico.bind(this);
  }

  disabledDate(currentDate) {
    const { startDate, endDate } = this.state;
    return currentDate < moment(startDate).startOf('day') || currentDate > moment(endDate).endOf('day') ? true : false;
  }

  changeDisabledDate(year, month) {
    const teste = `${year}-${month + 1}`;

    const startDate = dayjs(teste).startOf('month').format('YYYY-MM-DD');
    const endDate = dayjs(teste).endOf('month').format('YYYY-MM-DD');

    this.setState({
      startDate,
      endDate,
      entrada: '--:--',
      saida: '--:--',
      cargaHoraria: '--:--',
      showLinhaDoTempo: false
    });

    this.onSelectDate(moment(this.state.startDate).month() === moment().month() ? moment() : moment(this.state.startDate));
  }

  dateCellRender(value) {
    if (this.disabledDate(value)) {
      return null;
    }

    const { rotinaVendedor } = this.state;
    const dia = value.date();

    if (rotinaVendedor && rotinaVendedor[dia]) {
      const { entradaF, saidaF } = rotinaVendedor[dia];

      const listData = [
        {
          type: 'entrada', content: `Entrada ${entradaF}`
        },
        {
          type: 'saida', content: `Saída ${saidaF}`
        }
      ]

      return (
        <ul className='dateCell'>
          {listData.map((item) => (
            <li className={item.type} key={`${dia}-${item.content}`} >
              {item.content}
            </li>
          ))}
        </ul>
      )
    }
  }

  onSelectDate(value) {
    const { rotinaVendedor } = this.state;
    const dia = value.date();

    if (!rotinaVendedor || !rotinaVendedor[dia]) return;

    const {
      entradaF: entrada,
      saidaF: saida,
      cargaHoraria
    } = rotinaVendedor[dia];

    this.setState({
      entrada,
      saida,
      cargaHoraria,
      diaLinhaDoTempo: dia,
      showLinhaDoTempo: true
    });
  }

  async registrarInfoHistorico(rotinaVendedor, linhaDoTempo) {
    this.setState({ rotinaVendedor, linhaDoTempo });
  }

  render() {
    return (
      <BluveLayout selectItem={'relatorio/rotina-vendedor'}>
        <HeaderRotina
          changeDisabledDate={this.changeDisabledDate}
          registrarInfoHistorico={this.registrarInfoHistorico}
        />

        <div className='bodyRotina'>
          <div className='calendario'>
            <Calendar
              disabledDate={this.disabledDate}
              value={moment(this.state.startDate).month() === moment().month() ? moment() : moment(this.state.startDate)}
              dateCellRender={this.dateCellRender}
              onSelect={this.onSelectDate}
            />
          </div>

          <div className='detalhes'>
            <CardsRotinaVendedor
              horaDeEntrada={this.state.entrada}
              horaDeSaida={this.state.saida}
              cargaHoraria={this.state.cargaHoraria}
            />

            <LinhaDoTempo
              dataSource={this.state.linhaDoTempo}
              diaSelecionado={this.state.diaLinhaDoTempo}
              showLinhaDoTempo={this.state.showLinhaDoTempo}
            />
          </div>
        </div>
      </BluveLayout>
    )
  }
}

class HeaderRotina extends Component {
  state = {
    disableOptions: true,
    botaoExportacao: false,
    loading: false,
    loadingExportacao: false,
    gestorId: undefined,
    empresaId: undefined,
    empresas: [],
    vendedorId: undefined,
    rotinaVendedor: undefined,
    linhaDoTempo: undefined,
    nomeVendedor: '',
    dataAno: new Date().getFullYear(),
    dataMes: new Date().getMonth(),
    optionsEmpresas: [],
    optionsVendedores: [],
    optionsAnos: [],
    optionsMeses: [],
  }

  constructor(props) {
    super(props);

    this.loadOptions = this.loadOptions.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onSelectEmpresaChange = this.onSelectEmpresaChange.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.exportar = this.exportar.bind(this);
  }

  componentDidMount() {
    document.addEventListener('tokenAvailable', () => {
      this.loadOptions();
    });
  }

  async loadOptions() {
    const optionsEmpresas = [];
    const gestorId = await getGestorId();
    const empresas = await getEmpresas();

    empresas.forEach((empresa) => {
      const {
        key,
        nomeFantasia,
        razaoSocial
      } = empresa;

      optionsEmpresas.push(
        <Option key={key} value={key}>
          {nomeFantasia ?? razaoSocial}
        </Option>
      );
    });

    const anos = getYears();
    const optionsAnos = createOptions(anos);

    const meses = getMonths();
    const optionsMeses = createOptions(meses);

    this.setState({
      gestorId,
      optionsEmpresas,
      empresas,
      optionsAnos,
      optionsMeses,
      disableOptions: false
    });
  }

  onSelectChange(value, obj, nome) {
    this.setState({ [obj]: value });

    if (nome) this.setState({ nomeVendedor: nome });
  }

  async onSelectEmpresaChange(empresaId) {
    this.setState({ disableOptions: true });

    const { gestorId } = this.state;
    const optionsVendedores = [];

    const vendedores = await getVendedores(gestorId, empresaId);

    vendedores.forEach((vendedor) => {
      const {
        key,
        nome
      } = vendedor;

      optionsVendedores.push(
        <Option key={key} value={key}>
          {nome}
        </Option>
      );
    });

    this.setState({
      empresaId,
      optionsVendedores,
      disableOptions: false
    });
  }

  async onFilter() {
    this.setState({ loading: true, botaoExportacao: false });
    const { vendedorId, dataAno, dataMes } = this.state;

    if (dataAno === dayjs().get('year') && dataMes > dayjs().get('month')) {
      this.setState({ loading: false });
      mensagem.avisar('Não é possível buscar dados de rotina do vendedor de um mês futuro.');
      return;
    }

    const {
      rotinaVendedor,
      linhaDoTempo
    } = await getRotinaVendedor(dataAno, dataMes, vendedorId);

    await this.props.registrarInfoHistorico(rotinaVendedor, linhaDoTempo);
    this.props.changeDisabledDate(dataAno, dataMes);

    this.setState({
      rotinaVendedor,
      linhaDoTempo,
      loading: false,
      botaoExportacao: true
    });
  }

  async exportar() {
    this.setState({ loadingExportacao: true });

    const {
      nomeVendedor,
      rotinaVendedor,
      linhaDoTempo,
      dataAno,
      dataMes,
      empresaId
    } = this.state;


    const empresa = this.state.empresas.find((empresa) => empresa.key === empresaId);

    await exportarRotinaDoVendedor(
      empresa,
      nomeVendedor,
      rotinaVendedor,
      linhaDoTempo,
      dataAno,
      dataMes
    );

    this.setState({ loadingExportacao: false });
  }

  render() {
    return (
      <div className='headerRotina'>
        <div className='options'>
          <label>
            Empresa:
          </label>

          <Select
            showSearch
            placeholder='Selecionar empresa'
            optionFilterProp='children'

            value={this.state.empresaId}
            disabled={this.state.disableOptions || this.state.loading}
            onChange={this.onSelectEmpresaChange}
          >
            {this.state.optionsEmpresas}
          </Select>
        </div>

        <div className='options'>
          <label>
            Vendedor:
          </label>

          <Select
            showSearch
            placeholder='Selecionar vendedor'
            optionFilterProp='children'

            value={this.state.vendedorId}
            disabled={!this.state.empresaId || this.state.disableOptions || this.state.loading}
            onChange={(value, { props }) => this.onSelectChange(value, 'vendedorId', props.children)}
          >
            {this.state.optionsVendedores}
          </Select>
        </div>

        <div className='options'>
          <label>
            Ano:
          </label>

          <Select
            showSearch
            placeholder='Selecionar ano'
            optionFilterProp='children'

            value={this.state.dataAno}
            disabled={this.state.disableOptions || this.state.loading}
            onChange={(value) => this.onSelectChange(value, 'dataAno')}
          >
            {this.state.optionsAnos}
          </Select>
        </div>

        <div className='options'>
          <label>
            Mês:
          </label>

          <Select
            showSearch
            placeholder='Selecionar mês'
            optionFilterProp='children'

            value={this.state.dataMes}
            disabled={this.state.disableOptions || this.state.loading}
            onChange={(value) => this.onSelectChange(value, 'dataMes')}
          >
            {this.state.optionsMeses}
          </Select>
        </div>

        <Button
          className='filterButton'
          type='primary'

          loading={this.state.loading}
          disabled={!this.state.empresaId || !this.state.vendedorId}
          onClick={this.onFilter}
        >
          Filtrar
        </Button>

        <Button
          className='excel-button'

          loading={this.state.loadingExportacao}
          disabled={!this.state.botaoExportacao}
          onClick={this.exportar}
        >
          Exportar para Excel
        </Button>
      </div>
    )
  }
}

class CardsRotinaVendedor extends Component {
  render() {
    return (
      <div className='containerCardsRotinaVendedor'>
        <div className='cardsRotinaVendedor'>
          <b>Entrada</b>

          <div className='contentCardRotina'>
            {this.props.horaDeEntrada}
          </div>
        </div>

        <div className='cardsRotinaVendedor'>
          <b>Saída</b>

          <div className='contentCardRotina'>
            {this.props.horaDeSaida}
          </div>
        </div>

        <div className='cardsRotinaVendedor'>
          <b>Carga Horária</b>

          <div className='contentCardRotina'>
            {this.props.cargaHoraria}
          </div>
        </div>
      </div>
    )
  }
}

class LinhaDoTempo extends Component {
  constructor(props) {
    super(props);

    this.construirLinhaDoTempo = this.construirLinhaDoTempo.bind(this);
  }

  construirLinhaDoTempo() {
    const {
      dataSource,
      diaSelecionado
    } = this.props;

    const data = dataSource[diaSelecionado];
    const linhaDoTempo = [];

    if (!data) return;

    data.forEach((item) => {
      const {
        motivo,
        horario,
        cardColor
      } = item;

      linhaDoTempo.push(
        <div
          className='cardLinhaDoTempo'
          style={{
            backgroundColor: cardColor
          }}
        >
          <div>
            {motivo}
          </div>

          <div>
            {horario}
          </div>
        </div>
      )
    });

    return linhaDoTempo;
  }

  render() {
    return (
      <div className='linhaDoTempo'>
        {this.props.showLinhaDoTempo && (this.construirLinhaDoTempo())}
      </div>
    )
  }
}

export default withRouter(RotinaVendedor);
