import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button, Input } from 'antd';
import PasswordStrengthBar from 'react-password-strength-bar';


class CriarSenha extends Component {
  state = {
    password: '',
    confirmPassword: '',
    score: undefined,
    bloquearBotao: true
  }

  constructor(props) {
    super(props);

    this.kba = this.props.kba;
    this.historicoId = this.props.historicoId;

    this.handleConfirm = this.handleConfirm.bind(this);
    this.buttonState = this.buttonState.bind(this);

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeScore = this.onChangeScore.bind(this);
  }

  async handleConfirm() {
    return new Promise(async (resolve, reject) => {

      const {
        password,
        confirmPassword,
      } = this.state;

      if (password !== confirmPassword) {
        reject({
          type: 'warning',
          title: 'Senhas Não Conferem',
          description: 'Os campos devem ser preenchidos com a mesma senha.'
        });

        return;
      }

      try {
        const token = await this.kba.createPassword(
          password,
          this.historicoId
        );

        resolve({ token });
      } catch (error) {
        reject({
          type: 'error',
          title: 'Erro',
          description: error.message
        })
      }
    })
  }

  buttonState() {
    const {
      password,
      confirmPassword,
      score
    } = this.state;

    let bloquearBotao = true;

    if (password && confirmPassword && score >= 2) {
      bloquearBotao = false;
    }

    this.setState({ bloquearBotao });
  }

  async onChangeInput(e, campo) {
    const { value } = e.target;
    await this.setState({ [campo]: value });
    this.buttonState();
  }

  onChangeScore(score) {
    this.setState({ score });
  }

  render() {
    return (
      <div className='stepContainer'>
        <div className='textContainer'>
          <div className='title'>
            Crie uma senha
          </div>

          <div className='description'>
            Chegamos à última etapa! Agora basta criar uma senha para sua conta.
          </div>

          <div className='description bold'>
            A senha precisa ser forte e conter no mínimo 6 dígitos.
          </div>
        </div>

        <div style={{ marginBottom: '2rem' }}>
          <Input
            className='stepInput'
            placeholder='Senha'
            type='password'

            value={this.state.password}
            onChange={(e) => this.onChangeInput(e, 'password')}
          />

          <PasswordStrengthBar
            className='passStrength'
            password={this.state.password}
            scoreWords={['Senha muito fraca', 'Senha fraca', 'Senha razoável', 'Senha boa', 'Senha forte']}
            shortScoreWord={this.state.password ? 'Senha muito curta' : ''}
            minLength={6}

            onChangeScore={this.onChangeScore}
          />
        </div>

        <Input
          className='stepInput'
          placeholder='Confirmar Senha'
          type='password'

          value={this.state.confirmPassword}
          onChange={(e) => this.onChangeInput(e, 'confirmPassword')}
        />

        <Button
          className='buttonConfirm'
          type='primary'

          disabled={this.state.bloquearBotao}
          loading={this.props.loading}
          onClick={() => this.props.onClickButton(this.handleConfirm)}
        >
          Salvar e Entrar
        </Button>
      </div>
    )
  }
}


export default withRouter(CriarSenha);
