import React, { useState } from "react";
import BluveLayout from "../../../components/layout/BluveLayout";
import { withRouter } from "react-router";
import { Select, Table, Button, message, Modal, Tabs, Icon } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import {
  getEmpresas,
  getEmpresasAdm,
  buildReport,
  getNextColor,
  getClientes,
  exportToExcelVisaoLoja,
  exportToExcelVisaoGraficos,
} from "./TaxaConversaoF.jsx";
import { getUserType, getGestorId, isAdminUser } from "../../../components/auth/auth-provider";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "chart.piecelabel.js";
import "antd/dist/antd.css";
import { GERENTE } from "../../../components/funcs/constants";
import bluveStorage from "../../../components/localstorage/localstorage";
import { DiarioAtendimento } from "../../../components";
import "./TaxaConversao.css";


const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
const { warning } = Modal;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;


class TaxaConversao extends React.Component {
  state = {
    empresas: [],
    empresaId: '',
    empresaComparacaoId: '',
    dataSelecionada: moment(),
    reportData: [],
    lineChart: {},
    barChart: {},
    loading: false,
    loadingTable: false,
    button: false,
    periodo: {},
    alterouConfiguracaoRelatorio: false,
    alterouEmpresaComparacao: false,
    rangePickerOpened: false,
    dataTable: [],
    usuarioAdm: false,
    clientes: [],
    cliente: {
      value: undefined,
      key: undefined,
      change: false,
    },
  };

  options = [];
  optionsComparacao = [];
  optionsClientes = [];

  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onSelectChangeComparar = this.onSelectChangeComparar.bind(this);
    this.onChangeCliente = this.onChangeCliente.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.onFilterClickLojas = this.onFilterClickLojas.bind(this);
    this.onExportVisaoLojaClick = this.onExportVisaoLojaClick.bind(this);
    this.onExportVisaoGraficosClick = this.onExportVisaoGraficosClick.bind(this);
  }

  async componentDidMount() {
    const gestorId = await getGestorId();
    const usuarioAdm = await isAdminUser();

    if (usuarioAdm) {
      let clientes = [];
      const storageClientes = bluveStorage.getClientes('@clientes');

      if (storageClientes && storageClientes.gestorId === gestorId) {
        clientes = storageClientes.clientes;
      } else {
        clientes = await getClientes();
        clientes.sort((a, b) => a.nomeFantasia.localeCompare(b.nomeFantasia));
        bluveStorage.setClientes({ clientes, gestorId });
      }

      this.optionsClientes.push(
        <Option key={"todos"} value={"todos"}>
          {"Todos os Clientes"}
        </Option>
      );

      for (let i = 0; i < clientes.length; i++) {
        const cliente = clientes[i];
        this.optionsClientes.push(
          <Option key={i} value={cliente.id}>
            {cliente.nomeFantasia}
          </Option>
        );
      };

      this.setState({ clientes, usuarioAdm });
    };

    let empresas = [];
    const storage = bluveStorage.getEmpresasGestor('@gestorEmpresas');
    if (storage && storage.gestorId === gestorId) {
      empresas = storage.empresas;
    } else {
      if (!usuarioAdm) {
        empresas = await getEmpresas();
      }

      const data = {
        gestorId: gestorId,
        empresas: empresas,
      };

      bluveStorage.setEmpresasGestor(data);
    }

    if (getUserType() !== GERENTE) {
      this.optionsComparacao.push(
        <Option key={"rede"} value={"rede"}>
          {"Rede de Lojas"}
        </Option>
      );
    } else {
      const texto = empresas.length > 1 ? "Minhas lojas" : "Você mesmo";
      this.optionsComparacao.push(
        <Option key={"rede"} value={"rede"}>
          {texto}
        </Option>
      );
    }

    for (const item of empresas) {
      this.options.push(
        <Option key={item.key} value={item.key}>
          {item.nomeFantasia}
        </Option>
      );

      if (empresas.length > 1) {
        this.optionsComparacao.push(
          <Option key={item.key} value={item.key}>
            {item.nomeFantasia}
          </Option>
        );
      }
    }

    if (!empresas.length) {
      empresas.push({
        id: "Nenhuma empresa cadastrada",
        razaoSocial: "Nenhuma empresa cadastrada",
      });
    }

    let empresaId = bluveStorage.getReportEmpresaId();
    if (!empresaId) {
      empresaId = empresas[0].key;
    }

    let periodo = bluveStorage.getReportPeriodo();
    if (!periodo) {
      periodo = {
        inicial: moment().subtract(7, "days"),
        final: moment(),
      };
    } else {
      periodo.inicial = moment(periodo.inicial);
      periodo.final = moment(periodo.final);
    }


    let empresaComparacaoId = bluveStorage.getReportEmpresaComparacaoId();
    if (!empresaComparacaoId) {
      empresaComparacaoId = "rede";
    }

    const report = bluveStorage.getReportTaxaConversao();
    if (report.lineData || report.barData) {
      this.mountChart(report.lineData, report.barData);
    }

    let dataTable = [];
    if (empresas[0].id === 'Nenhuma empresa cadastrada' && usuarioAdm) {
      this.setState({ button: true });
    } else {
      dataTable = await this.getTableData(empresas, periodo);
    }

    this.setState({
      empresaId,
      empresaComparacaoId,
      empresas,
      periodo,
      dataTable
    });
  }

  onSelectChange(value) {
    this.setState({ empresaId: value, alterouConfiguracaoRelatorio: true });
  }

  onSelectChangeComparar(value) {
    this.setState({
      empresaComparacaoId: value,
      alterouEmpresaComparacao: true,
    });
  }

  async onChangeCliente(value, key) {
    this.setState({ loadingTable: true });
    const clientes = this.state.clientes;
    let empresas = []

    if (value === 'todos') {
      for (let i = 0; i < clientes.length; i++) {
        if (clientes[i].empresas === null) {
          const lista = await getEmpresasAdm(clientes[i].id);
          clientes[i].empresas = lista;
          empresas = empresas.concat(lista);
        } else {
          empresas = empresas.concat(clientes[i].empresas)
        }
      }
    } else {
      const cliente = clientes[key];

      if (cliente.empresas === null) {
        empresas = await getEmpresasAdm(value);
        clientes[key].empresas = empresas;
      } else {
        empresas = cliente.empresas;
      }
    }
    const dataTable = await this.getTableData(empresas, this.state.periodo);
    this.setState({ empresas, clientes, dataTable });

    const gestorId = await getGestorId();
    bluveStorage.setEmpresasGestor({ gestorId, empresas });
    bluveStorage.setClientes({ gestorId, clientes });
  }

  onDateChange(value) {
    const periodo = {
      inicial: value[0],
      final: value[1],
    };
    this.setState({ periodo, alterouConfiguracaoRelatorio: true });
  }

  async onFilterClick() {
    if (!this.state.periodo.inicial || !this.state.periodo.final) {
      message.warning("Selecione o período para gerar o gráfico", 2);
      return;
    }

    if (this.state.empresaId === this.state.empresaComparacaoId) {
      warning({
        title: "Atenção",
        content:
          "A empresa selecionada e a de comparação não podem ser as mesmas.",
      });

      return;
    }

    if (this.state.empresas && this.state.empresas.length > 0 && this.state.empresas[0].id === 'Nenhuma empresa cadastrada') {
      warning({
        title: "Atenção",
        content:
          "O usuário não possui nenhuma empresa cadastrada.",
      });

      return;
    }

    if (this.state.empresaId === undefined && this.state.empresas) {
      const empresaId = this.state.empresas[0].key;
      this.setState({ empresaId });
    }

    this.setState({ loading: true });
    bluveStorage.setReportEmpresaId(this.state.empresaId);
    bluveStorage.setReportEmpresaCompracaoId(this.state.empresaComparacaoId);

    if (this.state.alterouConfiguracaoRelatorio) {
      bluveStorage.clearAllReportsData();
    } else if (this.state.alterouEmpresaComparacao) {
      bluveStorage.clearReportsDataEmpresaComparacao();
    }

    bluveStorage.setReportPeriodo(this.state.periodo);
    setTimeout(async () => {
      const lineData = await buildReport(
        this.state.empresaId,
        this.state.periodo.inicial,
        this.state.periodo.final
      );

      const barData = await buildReport(
        this.state.empresaComparacaoId,
        this.state.periodo.inicial,
        this.state.periodo.final,
        this.state.empresaComparacaoId === "rede",
        getUserType() === GERENTE,
        this.state.empresas
      );

      bluveStorage.setReportTaxaConversao(lineData, barData);
      this.mountChart(lineData, barData);
    }, 500);
  }

  alinharLista(lista1, lista2) {
    const listaMestre = lista1.length > lista2.length ? lista1 : lista2;
    const listaSlave = lista1.length > lista2.length ? lista2 : lista1;
    let index = 0;
    listaMestre.forEach((item) => {
      let achouLabel = false;
      listaSlave.forEach((slave) => {
        if (slave.labels === item.labels) {
          achouLabel = true;
        }
      });
      if (!achouLabel) {
        const dt = {
          id: index,
          labels: item.labels,
          atendimentos: 0,
          convertidos: 0,
          encerramentosManuais: 0,
          percentual: "0.00%",
          color: item.color,
        };
        listaSlave.splice(index, 0, dt);
      }
      index++;
    });

    if (listaMestre.length !== listaSlave.length) {
      this.alinharLista(listaMestre, listaSlave);
    }
  }

  ordenarLista(lista) {
    lista = lista.sort((a, b) => (a.labels > b.labels ? 1 : -1));
    let color = "#fff";
    lista.forEach((item) => {
      item.color = color;
      color = getNextColor(color);
    });
    return lista;
  }

  removerValoresZerados(lista) {
    lista = lista.filter((item) => {
      return item.atendimentos > 0 || item.convertidos > 0;
    });
    return lista;
  }

  mountChart(loja, lojaComparacao) {
    if (!loja && !lojaComparacao) {
      message.info("Sem movimentos nesse período");

      const emptyChart = {
        labels: [],
        datasets: [],
      };

      this.setState({
        loading: false,
        reportData: [],
        lineChart: emptyChart,
        barChart: emptyChart,
      });
      return;
    }

    if (loja === undefined) {
      loja = [];
    }

    if (lojaComparacao === undefined) {
      lojaComparacao = [];
    }

    // Antes de alimentar os itens que formarão o gráfico, harmonizo as duas
    // listas
    this.alinharLista(loja, lojaComparacao);

    loja = this.ordenarLista(loja);
    lojaComparacao = this.ordenarLista(lojaComparacao);

    const labels = [];
    const chartAtendimentos = [];
    const chartConversoes = [];
    const backgroundColors = [];
    const chartPercConversoesLoja = [];
    const chartPercConversoesComparacao = [];

    loja.forEach((item) => {
      if (item.labels !== "Total") {
        labels.push(item.labels);
        chartAtendimentos.push(item.atendimentos);
        chartConversoes.push(item.convertidos);
        chartPercConversoesLoja.push(
          (item.convertidos / item.atendimentos) * 100
        );
        backgroundColors.push("rgba(75,192,192,0.7)");
      }
    });

    lojaComparacao.forEach((item) => {
      if (item.labels !== "Total") {
        //labels.push(item.labels);
        chartPercConversoesComparacao.push(
          (item.convertidos / item.atendimentos) * 100
        );
      }
    });

    //==========  Line Chart
    const dtsLineChart = [];
    dtsLineChart.push({
      label: "Atendimentos",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgb(157, 158, 157)",
      borderColor: "rgb(157, 158, 157)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "#ffa39e",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "#ffa39e",
      pointHoverBorderColor: "#ffa39e   ",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: chartAtendimentos,
    });

    dtsLineChart.push({
      label: "Convertidos",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgb(153, 102, 255)",
      borderColor: "rgb(153, 102, 255)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgb(54, 162, 235)",
      // pointBorderColor: 'red',
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgb(54, 162, 235)",
      pointHoverBorderColor: "rgb(54, 162, 235)",
      // pointHoverBackgroundColor: 'red',
      // pointHoverBorderColor: 'red',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: chartConversoes,
    });

    const lineChartData = {
      labels: labels,
      datasets: dtsLineChart,
    };

    //==========  Line Chart

    const dtsBarChart = [];
    dtsBarChart.push({
      label: "Conversões Loja",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgb(66, 133, 245)",
      borderColor: "rgb(66, 133, 245)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(66, 133, 245)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgb(66, 133, 245)",
      pointHoverBorderColor: "rgb(66, 133, 245)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: chartPercConversoesLoja,
    });

    if (
      getUserType() !== GERENTE ||
      (getUserType() === GERENTE && this.state.empresas.length > 1)
    ) {
      dtsBarChart.push({
        label: "Comparação",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgb(219, 68, 55)",
        borderColor: "rgb(219, 68, 55)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "red",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "red",
        pointHoverBorderColor: "red",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: chartPercConversoesComparacao,
      });
    }

    const barChartData = {
      labels: labels,
      datasets: dtsBarChart,
    };

    loja = this.removerValoresZerados(loja);
    this.setState({
      loading: false,
      reportData: loja,
      lineChart: lineChartData,
      barChart: barChartData,
    });
  }

  async getTableData(empresas, periodo) {
    const periodoInicial = periodo.inicial;
    const periodoFinal = periodo.final;
    const dataSource = [];

    this.setState({ loadingTable: true });
    if (empresas.length > 0 && empresas[0].id !== 'Nenhuma empresa cadastrada') for (let i = 0; i < empresas.length; i++) {
      const empresa = empresas[i];
      const report = await buildReport(
        empresa.key,
        periodoInicial,
        periodoFinal
      );

      if (report[report.length - 1].percentual == 'NaN%') {
        report[report.length - 1].percentual = '0.00%';
      }

      let cliente = [];
      if (this.state.usuarioAdm) {
        cliente = this.state.clientes.find((item) => item.id === empresa.gestorId);
      }

      dataSource.push({
        key: empresa.key,
        loja: empresa.nomeFantasia,
        gestor: cliente ? cliente.nomeFantasia : '',
        email: cliente ? cliente.email : '',
        ativo: cliente ? cliente.ativo : '',
        taxaDeConversao: report[report.length - 1].percentual,
        atendimentos: report[report.length - 1].atendimentos,
        convertidos: report[report.length - 1].convertidos,
      });
    }

    this.setState({ loadingTable: false });
    return dataSource;
  }

  async onFilterClickLojas() {
    const { value, key, change } = this.state.cliente;

    if (change && this.state.usuarioAdm) {
      this.onChangeCliente(value, key);
      this.setState({ cliente: { change: false } });
    } else {
      const dataTable = await this.getTableData(this.state.empresas, this.state.periodo);
      this.setState({ dataTable });
    }
  }

  onExportVisaoLojaClick() {
    exportToExcelVisaoLoja(
      this.state.dataTable, 
      this.state.periodo
      );
  }

  onExportVisaoGraficosClick() {
    const empresaFiltrada = this.state.empresas.filter(item => item.key === this.state.empresaId)[0];
    const comparacao = this.state.empresas.length > 1 ? "Minhas lojas" : "Você mesmo";

    exportToExcelVisaoGraficos(
      this.state.reportData, 
      this.state.periodo,
      `${empresaFiltrada.razaoSocial} (${empresaFiltrada.nomeFantasia})`,
      comparacao,
      );    
  }

  _onChange = (field, value) => {
    let { periodo } = this.state;
    periodo[field] = value;

    this.setState({
      periodo: periodo,
      alterouConfiguracaoRelatorio: true,
    });
  };

  handleRangePickerOpen = (open) => {
    this.setState({ rangePickerOpened: open })
  }

  render() {
    const { empresaId, periodo, endOpen, loading } = this.state;

    const Dates = () => {
      const [dates, setDates] = useState([]);
      const disabledDate = current => {
        if (!dates || dates.length === 0) {
          return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 31;
        const tooEarly = dates[0] && dates[0].diff(current, 'days') > 0;
        return tooEarly || tooLate;
      };

      return (
        <RangePicker
          disabledDate={disabledDate}
          onCalendarChange={value => {
            setDates(value);
          }}
          format={dateFormat}
          placeholder={["Data Inicial", "Data Final"]}
          onChange={this.onDateChange}
          value={[
            this.state.periodo ? this.state.periodo.inicial : "",
            this.state.periodo ? this.state.periodo.final : "",
          ]}
        />
      );
    }

    const columns = [
      {
        title: "Período",
        dataIndex: "labels",
        key: "labels",
      },
      {
        title: "Atendimentos",
        dataIndex: "atendimentos",
        key: "atendimentos",
      },
      {
        title: "Convertidos",
        dataIndex: "convertidos",
        key: "convertidos",
      },
      {
        title: "Encerramentos Manuais",
        dataIndex: "encerramentosManuais",
        key: "encerramentosManuais",
      },
      {
        title: "Taxa de conversão",
        dataIndex: "percentual",
        key: "percentual",
      },
    ];

    const columnsLojas = [
      {
        title: "Lojas",
        dataIndex: "loja",
        key: "loja",
        filters: this.state.empresas.map((item) => {
          if (item.id === 'Nenhuma empresa cadastrada') {
            return { text: '', value: '' };
          } else {
            return { text: item.nomeFantasia, value: item.key };
          }
        }),
        onFilter: (value, record) => record.key.indexOf(value) === 0,
        sorter: (a, b) => a.loja.localeCompare(b.loja),
        filterIcon: filtered =>
          <Icon
            type="unordered-list"
            style={{ color: filtered ? '#1890ff' : undefined, fontSize: '1.4rem' }}
          />,
      },
      {
        title: "Taxa de Conversão Total",
        dataIndex: "taxaDeConversao",
        key: "taxaDeConversao",
        defaultSortOrder: 'descend',
        sorter: (a, b) => parseFloat(a.taxaDeConversao) - parseFloat(b.taxaDeConversao)
      },
      {
        title: "Atendimentos",
        dataIndex: "atendimentos",
        key: "atendimentos",
        sorter: (a, b) => a.atendimentos - b.atendimentos
      },
      {
        title: "Conversões",
        dataIndex: "convertidos",
        key: "convertidos",
        sorter: (a, b) => a.convertidos - b.convertidos
      }
    ];

    if (this.state.usuarioAdm) {
      columnsLojas.splice(1, 0, {
        title: "Gestor",
        dataIndex: "gestor",
        key: "gestor",
        sorter: (a, b) => a.gestor.localeCompare(b.gestor),
        filterIcon: filtered =>
          <Icon
            type="unordered-list"
            style={{ color: filtered ? '#1890ff' : undefined, fontSize: '1.4rem' }}
          />,
      });

      columnsLojas.splice(2, 0, {
        title: "Email",
        dataIndex: "email",
        key: "email",
      });
    }

    return (
      <div>
        <BluveLayout selectItem={"relatorio/taxa-conversao"}>
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={this.state.usuarioAdm ? "Visão Geral das Lojas dos Gestores" : "Visão Geral das Lojas"}
              key="1"
            >

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ margin: 0 }}>Período:</label>
                <div className="rangePickerDuplo">
                  <Dates />
                </div>

                <Button
                  type="primary"
                  loading={this.state.loading}
                  disabled={this.state.button}
                  onClick={() => {
                    this.onFilterClickLojas();
                    this.onFilterClick();
                  }}
                >
                  Filtrar
                </Button>

                <Button
                  className='excel-button'
                  disabled={this.state.loading || this.state.dataTable.length === 0}
                  onClick={this.onExportVisaoLojaClick}
                  style={{ marginLeft: '1rem' }}
                >
                  Exportar para Excel
                </Button>


                {this.state.usuarioAdm && (
                  <div style={{ width: '100%' }}>
                    <label style={{ marginLeft: '1rem', marginBottom: 0 }}>Clientes:</label>
                    <Select
                      showSearch
                      placeholder='Selecionar o cliente'
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: '30%' }}
                      onChange={(value, key) => {
                        this.setState({
                          button: false,
                          cliente: { value, key: key.key, change: true }
                        })
                      }}
                    >
                      {this.optionsClientes}
                    </Select>
                  </div>
                )}
              </div>

              <Table
                columns={columnsLojas}
                pagination={{
                  defaultPageSize: 100,
                  position: "both",
                  pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
                  showSizeChanger: true,
                  locale: { items_per_page: "" },
                }}
                dataSource={this.state.dataTable}
                loading={this.state.loadingTable}
                bordered
                style={{ marginTop: '2rem' }}
              />
            </TabPane>

            <TabPane tab="Gráficos" key="2">
              <div className="containerFluxoOportunidades">
                <label>Empresa:</label>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "20%", marginBottom: '15px' }}
                  placeholder="Selecione uma empresa"
                  onChange={this.onSelectChange}
                  value={this.state.empresaId}
                >
                  {this.options}
                </Select>
                <label>Período:</label>

                <div className="rangePickerDuplo">
                  <Dates />
                </div>

                <label>Comparar com:</label>

                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "20%" }}
                  placeholder="Selecione uma empresa"
                  onChange={this.onSelectChangeComparar}
                  value={this.state.empresaComparacaoId}
                >
                  {this.optionsComparacao}
                </Select>
                <Button
                  type="primary"
                  loading={this.state.loading}
                  disabled={this.state.button}
                  onClick={() => {
                    this.onFilterClickLojas();
                    this.onFilterClick();
                  }}
                >
                  Filtrar
                </Button>

                <Button
                  className='excel-button'
                  disabled={this.state.loading || this.state.reportData.length === 0}
                  onClick={this.onExportVisaoGraficosClick}
                  style={{ marginLeft: '1rem' }}
                >
                  Exportar para Excel
                </Button>
              </div>

              <DiarioAtendimento
                empresaId={empresaId}
                inicio={periodo.inicial}
                fim={periodo.final}
                loading={loading}
              ></DiarioAtendimento>

              <div className="left">
                <Bar
                  data={this.state.barChart}
                  width={100}
                  height={280}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,

                    plugins: {
                      datalabels: {
                        display: true,
                        anchor: "end",
                        align: "top",
                        formatter: function (value, context) {
                          return value.toFixed(0);
                        }
                      },
                    },

                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            min: 0,
                            stepSize: 20,
                            callback: function (value) {
                              return value + "%";
                            },
                          },
                        },
                      ],
                    },
                    legend: {
                      display: true,
                    },
                    title: {
                      display: false,
                    },

                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem, data) {
                          var dataset = data.datasets[tooltipItem.datasetIndex];
                          var currentValue = dataset.data[tooltipItem.index];
                          return currentValue.toString().replace(".", ",") + " %";
                        },
                        title: function (tooltipItem, data) {
                          return data.labels[tooltipItem[0].index];
                        },
                      },
                    },
                  }}
                />
              </div>

              <div className="right">
                <Bar
                  data={this.state.lineChart}
                  width={100}
                  height={280}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,

                    plugins: {
                      datalabels: {
                        anchor: "end",
                        align: "top",
                      },
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            min: 0,
                          },
                        },
                      ],
                    },
                    legend: {
                      display: true,
                    },
                    title: {
                      display: false,
                    },
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem, data) {
                          var dataset = data.datasets[tooltipItem.datasetIndex];
                          var currentValue = dataset.data[tooltipItem.index];
                          return currentValue.toString().replace(".", ",") + " %";
                        },
                        title: function (tooltipItem, data) {
                          return data.labels[tooltipItem[0].index];
                        },
                      },
                    },
                  }}
                />
              </div>

              <div className="divTableGrafico">
                <Table
                  columns={
                    this.state.reportData.find(item => item.atendimentos != item.encerramentosManuais)
                      ? columns
                      : columns.filter(item => item.key !== "encerramentosManuais")
                  }
                  rowClassName={(record) => record.color.replace("#", "")}
                  pagination={{ pageSize: 250, hideOnSinglePage: true }}
                  dataSource={this.state.reportData}
                  bordered
                  rowKey={(record) => record.id}
                />
              </div>
            </TabPane>
          </Tabs>
        </BluveLayout>
      </div>
    );
  }
}

export default withRouter(TaxaConversao);
