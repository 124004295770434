import {
  getGestorId,
  getUserType,
  getGerenteId,
} from "../../../components/auth/auth-provider";
import empresaDB from "../../../dataManager/dtmEmpresa";
import { GERENTE } from "../../../components/funcs/constants";
import gerenteDB from "../../../dataManager/dtmGerente";
import rankingDB from "../../../dataManager/dtmRanking";
import { salvarXlsx } from "../metas/Funcs-Metas";

export function getNextColor(color) {
  if (color === "#fff") {
    color = "#eee";
  } else {
    color = "#fff";
  }
  return color;
}

export async function getEmpresas() {
  const gestorId = getGestorId();
  const empresas = await empresaDB.getByGestorIdCustom(gestorId, 'login.login');
  const newEmpresas = [];
  const userType = getUserType();

  if (userType === GERENTE) {
    const gerenteId = getGerenteId();
    const empresasGerente = await gerenteDB.getEmpresasGerente(gerenteId, 'razaoSocial');

    for (let i = 0; i < empresasGerente.length; i++) {
      const empresa = empresas.filter((item) => {
        return item.key === empresasGerente[i].id;
      });

      if (empresa && empresa.length > 0) {
        newEmpresas.push(empresa[0]);
      }
    }
  }
  const sortedNewEmpresas = sortEmpresasAlphabetically(newEmpresas);
  const sortedEmpresas = sortEmpresasAlphabetically(empresas);
    
  const displayedEmpresas = sortedNewEmpresas.length > 0 ? sortedNewEmpresas : sortedEmpresas;

  return displayedEmpresas;
}

function sortEmpresasAlphabetically(empresas) {
  if (!empresas) {
    return [];
  }
  return empresas.sort((a, b) => a.nomeFantasia.localeCompare(b.nomeFantasia));
}

export async function buildReport(id, dataInicial, dataFinal, empresas) {
  const inicio = dataInicial.startOf("day").toDate();
  const fim = dataFinal.endOf("day").toDate();

  const ids = [];
  if (id === 'todas') {
    empresas.forEach(empresa => {
      ids.push(empresa.key);
    });
  } else {
    ids.push(id);
  }

  const vendedores = await rankingDB.getByPeriodo(ids, inicio, fim);

  if (!vendedores) {
    return undefined;
  }

  const lista = [];
  let totalAtendimentos = 0;
  let totalConvertidos = 0;
  let totalEncerramentosManuais = 0;
  let color = "#fff";

  vendedores.forEach((vend) => {
    const percentual =
      ((vend.convertidos / vend.atendimentos) * 100).toFixed(2) + "%";
    vend["percentual"] = percentual;


    const empresa = empresas.filter((item) => {
      return item.key === vend.empresaId;
    })[0];

    vend["nomeLoja"] = empresa.login ? empresa.login.login : empresa.razaoSocial;

    lista.push(vend);
    totalAtendimentos += vend.atendimentos;
    totalConvertidos += vend.convertidos;
    totalEncerramentosManuais += vend.encerramentosManuais;
  });

  lista.sort((a, b) => (a.percentual > b.percentual ? -1 : 1));

  let novaLista = []

  lista.forEach((item) => {
    item["color"] = color;
    color = getNextColor(color);
    novaLista.push(item);
  })

  const totalPercentual =
    ((totalConvertidos / totalAtendimentos) * 100).toFixed(2) + "%";

  novaLista.push({
    id: "total",
    nome: `Total`,
    atendimentos: totalAtendimentos,
    convertidos: totalConvertidos,
    percentual: totalPercentual,
    encerramentosManuais: totalEncerramentosManuais,
    color,
  });

  return novaLista;
}

export async function exportToExcel(data, empresaNome, periodo, todas) {
    const dataInicial = periodo.inicial.toDate().toLocaleDateString();
  const dataFinal = periodo.final.toDate().toLocaleDateString();

  const infoExcel = [];
  infoExcel.push([`Empresa: ${empresaNome}`]);
  infoExcel.push(['']);

  if (todas) {
    infoExcel.push(['Loja', 'Vendedor', 'Atendimentos', 'Convertidos', 'Taxa de Conversão']);
  } else {
    infoExcel.push(['Vendedor', 'Atendimentos', 'Convertidos', 'Taxa de Conversão']);
  }


  data.forEach((item) => {
    if (todas) {
      infoExcel.push([
        item.nomeLoja,
        item.nome,
        item.atendimentos,
        item.convertidos,
        item.percentual,
      ]);
      
    } else {
      infoExcel.push([
        item.nome,
        item.atendimentos,
        item.convertidos,
        item.percentual,
      ]);
    }
  });

  const fileName = `Conversão individual - Período de ${dataInicial} até ${dataFinal}`;
  await salvarXlsx(infoExcel, fileName);

}
