import React from "react";
import { withRouter } from "react-router";
import { FILA_URL } from "../../firebase/firebase";
import { currentUser } from "../../components/auth/auth-provider";
import { REDES_SOCIAIS } from "../../components/funcs/constants";
import CookiesAlert from "components/cookiesAlert/cookiesAlert";

// CSSs
import "./Home.css";
import "../../assets/template/assets/css/bootstrap.min.css";
import "../../assets/template/assets/css/font-awesome.min.css";
import "../../assets/template/assets/css/themify-icons.css";
import "../../assets/template/assets/css/magnific-popup.css";
import "../../assets/template/assets/css/owl.carousel.css";
import "../../assets/template/assets/css/owl.transitions.css";
import "../../assets/template/assets/css/plyr.css";
import "../../assets/template/assets/css/swiper.min.css";
import "../../assets/template/assets/css/slick.css";
import "../../assets/template/css/scheme/blue-green.css";
import "../../assets/template/css/style.css";
import "../../assets/template/css/responsive.css";
import "../../assets/template/assets/js/jquery.min.js";
import "../../assets/template/assets/js/bootstrap.min.js";
import "../../assets/template/assets/js/jquery.nav.js";
import "../../assets/template/assets/js/owl.carousel.js";
import "../../assets/template/assets/js/visible.js";
import "../../assets/template/assets/js/jquery.stellar.min.js";
import "../../assets/template/assets/js/jquery.countTo.js";
import "../../assets/template/assets/js/imagesloaded.pkgd.min.js";
import "../../assets/template/assets/js/isotope.pkgd.min.js";
import "../../assets/template/assets/js/jquery.magnific-popup.min.js";
import "../../assets/template/assets/js/jquery.ajaxchimp.min.js";
import "../../assets/template/assets/js/plyr.js";
import "../../assets/template/assets/js/swiper.min.js";
import "../../assets/template/assets/js/slick.min.js";

import logo from "../../assets/template/images/logo-bluve-azul.png";
import tabletImg from "../../assets/template/images/tablet.jpg";
import client1 from "assets/template/images/client-logo/toulon-2.png";
import client2 from "assets/template/images/client-logo/fluminense-2.png";
import client3 from "assets/template/images/client-logo/loft-2.png";
import client4 from "assets/template/images/client-logo/touch-2.png";
import client5 from "assets/template/images/client-logo/imaginarium.png";
import mensagem from "components/message/Message";
import LoginHomeBluve from "./LoginHomeBluve";

const params = new URLSearchParams(window.location.search);

class Home extends React.Component {
  state = {
    filaUrl:
      "https://stackoverflow.com/questions/4907843/open-a-url-in-a-new-tab-and-not-a-new-window",
  };

  constructor(props) {
    super(props);
    document.title = `Bluve`;
    this.mountFilaUrl = this.mountFilaUrl.bind(this);
    this.redirectJustIfUserIsAuthenticated = this.redirectJustIfUserIsAuthenticated.bind(
      this
    );
    this.redirectJustIfUserIsAuthenticated();
  }

  componentDidMount() {
    this.mountFilaUrl();

    // Exibir mensagem de sessão encerrada caso sessionRevoked=true
    if (params.get('sessionRevoked')) {
      mensagem.avisar('Sessão encerrada devido a login em outro dispositivo.');
    }
  }

  redirectJustIfUserIsAuthenticated() {
    const user = currentUser();
    if (user) {
      this.props.history.push("/dashboard");
    }
  }

  mountFilaUrl() {
    const protocol = window.location.protocol;
    const domain = window.location.hostname;
    let url = FILA_URL;
    if (domain === "localhost") {
      let port = window.location.port;
      // Bem, nem sempre vai funcionar no local,
      // mas se for o caso, estas são as duas portas que normalmente
      // estão atuando no react
      if (port === "3000") {
        port = "3001";
      } else {
        port = "3000";
      }
      url = `${protocol}//${domain}:${port}`;
    }
    this.setState({ filaUrl: url });
  }

  render() {
    return (
      <div className="blue-green">
        {/* Header */}
        <nav
          className="navbar navbar-default style-10"
          data-spy="affix"
          data-offset-top="60"
        >
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar-collapse"
                aria-expanded="false"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand" href="/home">
                {/* <img src="https://storage.cloud.google.com/bluve-bucket/logo_oficial_branca_com_azul.png"
                                    className="bluveLogoTopo"
                                    alt="Bluve Logo"></img> */}
                <img
                  src={logo}
                  className="bluveLogoTopo"
                  alt="Bluve Logo"
                ></img>
              </a>
            </div>

            {/* <LoginHomeBluve></LoginHomeBluve> */}

            <div className="collapse navbar-collapse" id="navbar-collapse">
              <ul className="nav-blue nav navbar-nav navbar-right onepage-nav">
                <li>
                  <a href="/home">Início</a>
                </li>
                <li>
                  <a href="#porque">Porque</a>
                </li>
                <li>
                  <a href="#comofunciona">Como funciona</a>
                </li>
                <li>
                  <a href="#emqualquerlugar">Recursos</a>
                </li>
                <li>
                  <a href="/contato">Contato</a>
                </li>
                {/* <li className="signup-item">
                  <a href="/login">
                    <i className="fa fa-user-plus"></i> &nbsp;Cadastre-se grátis!
                  </a>
                </li> */}
                <li className="signup-item">
                  <a href="/login">
                    <i className="fa fa-sign-in"></i> &nbsp;Área do Gestor
                  </a>
                </li>
                <li className="login-item">
                  <a
                    href={this.state.filaUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-user" aria-hidden="true"></i>&nbsp;Login Fila da Vez
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* Header End */}

        {/* Banner */}
        <div className="cps-banner style-13" id="banner">
          <div className="cps-banner-item banner-back">
            <div className="cps-banner-content" style={{ top: "70%" }}>
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 sm-text-center">
                    <h1 className="cps-banner-title">Bluve</h1>
                    <h3 className="cps-banner-subtitle">
                      Descubra por que perde vendas
                    </h3>

                    <p className="cps-banner-text">
                      * Quantas pessoas saem de mãos vazias? <br />* Quais
                      produtos os clientes pedem? <br />* Qual vendedor tem a
                      melhor taxa de conversão?
                    </p>
                    {/* <form className="cps-banner-form" action="#" method="post">
                                <input type="email" name="email" placeholder="Digite seu email" required="">
                                <button type="submit">Iniciar</button>
                                <div className="clearfix"></div>
                            </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cps-banner-image hidden-sm hidden-xs">
            <div className="double-mock">
              <img
                className="back-mock"
                height="537"
                src={tabletImg}
                style={{ marginRight: "100px" }}
                alt="Laptop"
              ></img>
              {/* <img
                className="back-mock"
                src="https://storage.googleapis.com/bluve-bucket/bluve-site/banner/mock-6-laptop.png"
                alt="Laptop"
              ></img> */}
              {/* <img
                className="front-mock"
                src="https://storage.googleapis.com/bluve-bucket/bluve-site/banner/mock-6-mobile.png"
                alt="Mobile"
              ></img> */}
            </div>
          </div>
        </div>
        {/* Banner End */}

        <div className="cps-main-wrap">
          {/* Connect everyone on rightime */}
          <div
            className="cps-section cps-section-padding cps-bottom-0"
            id="porque"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 sm-bottom-30">
                  <img
                    className="img-responsive"
                    src="https://storage.googleapis.com/bluve-bucket/email-heading.jpg"
                    alt="Por que"
                  ></img>
                </div>
                <div className="col-md-6">
                  <div className="connection-content">
                    <div className="cps-section-header text-left style-3">
                      <div className="icon">
                        <i className="fa fa-connectdevelop"></i>
                      </div>
                      <h3 className="cps-section-title cps-theme-color">
                        Pronto para gerar mais vendas com o Bluve?
                      </h3>
                      <p className="cps-section-text">
                        Com a lista da vez digital você vai identificar como
                        fazer sua equipe de vendas ser mais produtiva: Qual
                        vendedor desperdiça mais atendimentos? Em que horário
                        entram mais pessoas na loja?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Connect everyone on rightime end */}

          {/* How it works video */}
          <div
            className="cps-section cps-section-padding cps-bottom-0"
            id="comofunciona"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-md-offset-3 col-xs-12">
                  <div className="cps-section-header text-center">
                    <h3 className="cps-section-title cps-theme-color">
                      Como funciona
                    </h3>
                    <p className="cps-section-text">
                      Comece a usar a lista da vez em 5 minutos: Cadastre seu
                      gerente e os vendedores. Crie as famílias de produtos e os
                      motivos de perdas de vendas. Agora, você já tem a lista da
                      vez pronta!
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="how-it-works-video">
                    <div className="plyr-video">
                      <iframe
                        title="Vídeo Bluve"
                        width="750"
                        height="415"
                        src="https://www.youtube.com/embed/HzWB6BTWZC4"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* How it works video end */}

          {/* Invite to call */}
          <div
            className="cps-section cps-section-padding cps-bottom-0"
            id="emqualquerlugar"
          >
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-sm-push-6 col-xs-12 xs-bottom-30">
                  <div className="cps-section-header text-left style-3">
                    <h3 className="cps-section-title cps-theme-color">
                      Acompanhe os resultados em
                      <br />
                      qualquer lugar
                    </h3>
                    <p className="cps-section-text">
                      O Bluve foi feito pensando em mobilidade. Você pode usar a
                      Lista da Vez em qualquer dispositivo: computador, celular
                      ou tablet. Os dados, você pode acompanhar na sua
                      dashboard.
                    </p>
                  </div>
                </div>
                <div className="col-sm-5 col-sm-pull-6 col-xs-12">
                  <img
                    className="img-responsive features-side-img"
                    src="https://storage.googleapis.com/bluve-bucket/bluve-site/features/shape-4.svg"
                    alt="Recursos"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          {/* Invite to call end */}

          {/* Invite to call */}
          <div className="cps-section cps-section-padding cps-bottom-0">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-xs-12 xs-bottom-30">
                  <div className="cps-section-header text-left style-3">
                    <h3 className="cps-section-title cps-theme-color">
                      Velocidade sempre
                    </h3>
                    <p className="cps-section-text">
                      Criamos o Bluve pensando em como melhorar o atendimento na
                      sua loja
                    </p>
                  </div>
                </div>
                <div className="col-sm-5 col-sm-offset-1 col-xs-12">
                  <img
                    className="img-responsive features-side-img"
                    src="https://storage.googleapis.com/bluve-bucket/bluve-site/features/shape-5.svg"
                    alt="Velocidade"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          {/* Invite to call end */}

          {/* Client Logo End */}
          <div
            class="cps-section cps-section-padding cps-gray-bg"
            id="cps-integrated-with"
          >
            <div class="container">
              <div class="row">
                <div class="col-md-10 col-md-offset-1 col-xs-12">
                  <div class="cps-section-header text-center">
                    <h5 class="cps-section-subtitle">
                      <span>+200</span> vendedores dessas empresas estão usando
                      o Bluve
                    </h5>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <div class="integrated-with-wrap style-2">
                    <a href="#" class="integrated-with">
                      <img src={client1} alt="Integrated with ..." />
                    </a>
                    <a href="#" class="integrated-with">
                      <img src={client2} alt="Integrated with ..." />
                    </a>
                    <a href="#" class="integrated-with">
                      <img src={client3} alt="Integrated with ..." />
                    </a>
                    <a href="#" class="integrated-with">
                      <img src={client4} alt="Integrated with ..." />
                    </a>
                    <a href="#" class="integrated-with">
                      <img src={client5} alt="Integrated with ..." />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Subscription */}
          <div className="cps-section cps-section-padding subscription-style-4 cps-curve-bg-2">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 col-xs-12">
                  <div className="cps-section-header text-center">
                    <h3 className="cps-section-title">
                      Evolua seu negócio hoje!
                    </h3>
                    <p className="cps-section-text">
                      Nossa equipe está sempre pronta para ajudar você a
                      entender o perfil de consumo das pessoas e, assim,
                      aumentar o seu faturamento.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row big-mock-padding">
                <div className="col-xs-12 text-center">
                  <img
                    className="img-responsive"
                    src="https://storage.googleapis.com/bluve-bucket/bluve-site/bg/mock-bg-2.png"
                    alt="Asana big Screenshot"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          {/* Subscription End */}
        </div>

        <footer className="style-5">
          <div className="cps-footer-upper">
            <div className="container">
              <div className="cps-footer-widget-area">
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="cps-widget about-widget">
                      <a className="cps-footer-logo" href="home-agency.html">
                        <img
                          className="imgFooterHome"
                          src="https://storage.googleapis.com/bluve-bucket/b_oficial.png"
                          alt="Logo Bluve Rodapé"
                        ></img>
                      </a>
                      <p>{REDES_SOCIAIS}</p>
                      <div className="cps-socials">
                        <a
                          href="https://www.facebook.com/bluvebr"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/bluvebr"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="cps-widget custom-menu-widget">
                      <h4 className="cps-widget-title">Mais</h4>
                      <ul className="widget-menu">
                        <li>
                          <a href="/sobre">Sobre nós</a>
                        </li>
                        {/* <li><a href="/produtos">Nossos produtos</a></li> */}
                        <li>
                          <a href="/time">Nosso time</a>
                        </li>
                        {/* <li><a href="/clientes">Nossos clientes</a></li> */}
                        <li>
                          <a href="/contato">Contato</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cps-footer-lower">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-xs-12 xs-text-center">
                  <p className="copyright">
                    Copyright {new Date().getFullYear()} - C2b Software do Brasil Ltda.,{" "}
                    <a href="/home">Bluve</a>. Todos os direitos reservados
                  </p>
                </div>
                <div className="col-sm-6 col-xs-12 text-right xs-text-center">
                  <ul className="footer-menu">
                    <li>
                      <a href="/privacidade">Política de privacidade</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <CookiesAlert></CookiesAlert>
      </div>
    );
  }
}

export default withRouter(Home);
