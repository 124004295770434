import React from "react";
import { Form, FormikDebug, SubmitButton } from "formik-antd";
import { Button } from "antd";
import { useFormikContext } from "formik";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const StepActions = ({ stepNumber, isLastStep, previousHandle }) => {
  const { values } = useFormikContext();

  return (
    <div style={{ display: "flex" }}>
      {stepNumber > 0 && (
        <Button style={{ marginRight: "5px" }} onClick={() => previousHandle(values)}>
          Voltar
        </Button>
      )}

      <div>
        {/* <ResetButton>Reset</ResetButton> */}
        <SubmitButton>
          {isLastStep ? "Salvar Meta" : "Próximo"}
        </SubmitButton>
      </div>
    </div>
  );
};

const FormikForm = ({ step, stepNumber, previousHandle, isLastStep, handleSubmit }) => {
  const { values } = useFormikContext();

  return (
    <Form {...formItemLayout} onSubmit={(e, bag) => handleSubmit(e, values, bag)}>
      {step}

      <StepActions
        stepNumber={stepNumber}
        previousHandle={previousHandle}
        isLastStep={isLastStep} />

      {/* <FormikDebug /> */}
    </Form>
  );
};

export default FormikForm;
