import { fbDatabase, fbFirestore } from '../firebase/firebase';
// import { getUserType, getGestorId, getGerenteId } from '../components/auth/auth-provider';
// import { GESTOR } from '../components/funcs/constants';
import DataManager from './dtm';

class MetaDB extends DataManager {

  state = {
    collection: 'metas',
    orderBy: [], // usado na classe pai
  }

  async getUltimaMeta(empresaId) {
    const { collection } = this.state;

    const snapshot = await fbDatabase.collection(collection)
      .where("empresaId", "==", empresaId)
      .orderBy("ano", "desc")
      .orderBy("mes", "desc")
      .limit(1).withConverter()
      .get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmMetaJSX-MetaDB-getUltimaMeta');
    xRef.set({
      collection: 'metas',
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(snapshot.size),
      lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    if (snapshot.empty) {
      return null;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data()
    }))[0];

    return data;
  }

  async getByData(mes, ano, empresaId) {
    const { collection } = this.state;

    const snapshot = await fbDatabase.collection(collection)
      .where("empresaId", "==", empresaId)
      .where("ano", "==", ano)
      .where("mes", "==", mes)
      .limit(1)
      .get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmMetaJSX-MetaDB-getByData');
    xRef.set({
      collection: 'metas',
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(snapshot.size),
      lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    if (snapshot.empty) {
      return null;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data()
    }))[0];

    return data;
  }

  async getByFim(date, empresaId) {
    const { collection } = this.state;

    const snapshot = await fbDatabase
      .collection(collection)
      .where('empresaId', '==', empresaId)
      .where('fim', '>=', date.toDate())
      .limit(1)
      .get();

    if (snapshot.empty) {
      return null;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data()
    }))[0];

    return data;
  }

  async getByAno(ano, empresaId) {
    const { collection } = this.state;

    const snapshot = await fbDatabase.collection(collection)
      // .where("gestorId", "==", gestorId)
      // .where("dataIni", ">=", start)
      // .where("dataIni", "<=", end)
      .where("empresaId", "==", empresaId)
      // .where("ciclo.ano", "==", ano)
      .where("ano", "==", ano)
      // .where("ciclo.mes", "==", mes)
      // .orderBy("mes", "asc")
      .get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmMetaJSX-MetaDB-getByAno');
    xRef.set({
      collection: 'metas',
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(snapshot.size),
      lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    if (snapshot.empty) {
      return null;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
    }));

    return data;
  }

  async excluirMeta(key) {
    const { collection } = this.state;    
    await fbDatabase.collection(collection).doc(key).delete();
  }
}

const metaDB = new MetaDB();
export default metaDB;
