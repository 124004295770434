import React from 'react';
import { Form, Button, Input, notification } from 'antd';
import PasswordStrengthBar from 'react-password-strength-bar';
import { validateUser } from 'pages/perfil/Funcs-Perfil';
import { redefinePassword } from 'pages/login/LoginF';
import { currentUser, logout } from 'components/auth/auth-provider';
import history from '../../../history';
import './AlterarSenhaCRUD.css';

const openNotificationWithIcon = (type, title, description, duration) => {
    if (!duration) {
        duration = 4; // this is the default time
    }
    notification[type]({
        message: [title],
        description: [description],
        duration: duration,
    });
};


class AlterarSenhaCRUD extends React.Component {
    state = {
        bloqueioBotaoSalvar: true,
        help: '',
        validateStatus: '',
        loading: false,
        data: {
            password: '',
            newPasswordStrength: '',
            confirmPassword: '',
            score: undefined,
        },
    };

    constructor(props) {
        super(props);
        this.passwordChanged = this.passwordChanged.bind(this);
        this.newPasswordChanged = this.newPasswordChanged.bind(this);
        this.confirmChanged = this.confirmChanged.bind(this);
        this.scoreCheck = this.scoreCheck.bind(this);
        this.estadoBotao = this.estadoBotao.bind(this);
        this.esqueciSenhaOnClick = this.esqueciSenhaOnClick.bind(this);
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if(err) {
                return;
            }

            let isOk = await validateUser(values.password);
            if (!isOk) {
                openNotificationWithIcon('error', 'Senha incorreta', 'Para alterar o seu cadastro, você precisa informar corretamente qual é a sua senha');
                return;
            }

            if (isOk) {
                openNotificationWithIcon('success', 'Senha atualizada com sucesso');
            }

            this.props.handleOk(this.state.data.newPasswordStrength);
        })
    }

    passwordChanged(obj) {
        const data = this.state.data;
        data.password = obj.target.value;
        this.setState({ data });
        this.estadoBotao();
    }

    newPasswordChanged(obj) {
        const data = this.state.data;
        data.newPasswordStrength = obj.target.value;
        this.setState({ data });
        this.estadoBotao();
    }

    confirmChanged(obj) {
        const data = this.state.data;
        data.confirmPassword = obj.target.value;
        this.setState({ data });
        this.estadoBotao();
    }

    scoreCheck(score) {
        const data = this.state.data;
        data.score = score;
        this.setState({ data })
    }

    estadoBotao(){
        const password = this.state.data.password;
        const newPassword = this.state.data.newPasswordStrength;
        const confirmPassword = this.state.data.confirmPassword;
        const score = this.state.data.score;
        let botao = true;

        this.setState({ help: this.props.form.message, validateStatus: this.props.form.rules });
        if(password && password !== newPassword && score >= 2 && newPassword === confirmPassword) botao = false;
        else if(newPassword !== confirmPassword || (newPassword === password && password)) {
            this.setState({ help: 'Senhas não conferem', validateStatus: 'error' });
            if(newPassword === password && password) {
                this.setState({ help: 'Nova senha não pode ser igual a atual', validateStatus: 'error' });
            }
        };
        this.setState({ bloqueioBotaoSalvar: botao });
    }

    esqueciSenhaOnClick() {
        const email = currentUser().email;

        this.setState({ loading: true });
        setTimeout(async () => {
            const resp = await redefinePassword(email);
            if (resp.success) {
                openNotificationWithIcon('success', 'Email de redefinição de senha enviado. Verifique sua caixa de emails');
                logout();
                history.push('/home');
                history.go();
            }
            else {
                openNotificationWithIcon('error', `Ocorreu o seguinte erro ao tentar enviar seu email: ${resp.message}`);
            }
            this.setState({ loading: false });
        }, 1000);
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 10,
                },
            },
        };

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <label style={{paddingBottom: '2rem'}}>
                    A senha de login precisa ser redefinida para maior segurança de sua conta e de suas informações.
                </label>

                <Form.Item className="formSenhas" label="Senha Atual">
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Informe a sua senha atual', whitespace: true }],
                    })(<Input.Password onChange={this.passwordChanged}  style={{ width: '75%' }} />)}
                </Form.Item>

                <Form.Item 
                    className="formSenhas"
                    label="Nova Senha" 
                    validateStatus={this.state.validateStatus}>

                    {getFieldDecorator('newPassword', {
                        rules: [{ required: true, message: 'Informe uma nova senha' }],
                    })(<Input.Password onChange={this.newPasswordChanged} style={{ width: '75%' }} />)}

                    <PasswordStrengthBar
                        className='strengthBar'
                        password={this.state.data.newPasswordStrength}
                        scoreWords={['Senha muito fraca', 'Senha fraca', 'Senha razoável', 'Senha boa', 'Senha forte']}
                        shortScoreWord={this.state.data.newPasswordStrength ? 'Senha muito curta' : ''}
                        minLength={6}
                        onChangeScore={(score) => this.scoreCheck(score)}
                        style={{ width: '75%' }} />
                </Form.Item>

                            
                <Form.Item label="Confirme"
                    className="formSenhas" 
                    help={this.state.help}
                    validateStatus={this.state.validateStatus}>

                    {getFieldDecorator('confirmNewPassword', {
                        rules: [{ required: true, message: 'Confirme sua nova Senha' }],
                    })(<Input.Password onChange={this.confirmChanged} style={{ width: '75%' }} />)}
                </Form.Item>

                <Form.Item>
                    <Button 
                        loading={this.state.loading} 
                        style={{ border: 0, background: 'transparent', boxShadow: 'none', left: '50%' }}>

                        <a className="EsqueciSenha" onClick={this.esqueciSenhaOnClick}>
                            Esqueci minha senha
                        </a>
                    </Button>
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>

                    <Button type="primary" htmlType="submit" disabled={this.state.bloqueioBotaoSalvar}>
                        Salvar
                    </Button>
                </Form.Item>
            </Form>
        );
    };
}

export default Form.create()(AlterarSenhaCRUD);