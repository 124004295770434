import React from "react";
import { withRouter } from 'react-router';
import { TELEFONE_CONTATO } from '../../components/funcs/constants';
import { REDES_SOCIAIS } from '../../components/funcs/constants';

// CSSs
import "../../assets/template/assets/css/bootstrap.min.css";
import "../../assets/template/assets/css/font-awesome.min.css";
import "../../assets/template/assets/css/themify-icons.css";
import "../../assets/template/assets/css/magnific-popup.css";
import "../../assets/template/assets/css/owl.carousel.css";
import "../../assets/template/assets/css/owl.transitions.css";
import "../../assets/template/assets/css/plyr.css";
import "../../assets/template/assets/css/swiper.min.css";
import "../../assets/template/assets/css/slick.css";
import "../../assets/template/css/scheme/blue-green.css";
import "../../assets/template/css/style.css";
import "../../assets/template/css/responsive.css";
import '../../assets/template/assets/js/jquery.min.js';
import '../../assets/template/assets/js/bootstrap.min.js';
import '../../assets/template/assets/js/jquery.nav.js';
import '../../assets/template/assets/js/owl.carousel.js';
import '../../assets/template/assets/js/visible.js';
import '../../assets/template/assets/js/jquery.stellar.min.js';
import '../../assets/template/assets/js/jquery.countTo.js';
import '../../assets/template/assets/js/imagesloaded.pkgd.min.js';
import '../../assets/template/assets/js/isotope.pkgd.min.js';
import '../../assets/template/assets/js/jquery.magnific-popup.min.js';
import '../../assets/template/assets/js/jquery.ajaxchimp.min.js';
import '../../assets/template/assets/js/plyr.js';
import '../../assets/template/assets/js/swiper.min.js';
import '../../assets/template/assets/js/slick.min.js';


class Page404 extends React.Component {

    state = {
        contato: TELEFONE_CONTATO,
    }

    constructor(props) {
        super(props);
        document.title = `Bluve - 404`;
    }

    render() {
        return (
            <div>
                {/* Header */}
                <nav class="navbar navbar-default style-8" data-spy="affix" data-offset-top="60">
                    <div class="container">
                        <div class="navbar-header">
                            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false">
                                <span class="sr-only">Toggle navigation</span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                            </button>
                            <a class="navbar-brand" href="/home">
                                <img src="https://storage.googleapis.com/bluve-bucket/logo_oficial.png"
                                    className="bluveLogoTopo"
                                    alt="Bluve Logo"></img>

                            </a>
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </nav>
                {/* Header End */}

                {/* Page Header */}
                <div class="page-header style-8">
                    <div class="cps-bg-round-1" data-stellar-ratio="0.5"></div>
                    <div class="cps-bg-round-2" data-stellar-ratio="0.3"></div>
                    <div class="container">
                    </div>
                </div>
                {/* Page Header End */}

                <div class="cps-main-wrap">
                    <div class="cps-section cps-section-padding">
                        <div class="container text-center">
                            <div class="cps-404-content">
                                <h3 class="cps-404-title">Erro 404</h3>
                                <p class="cps-404-text">Opa! Se aqui fosse uma rua,seria sem saída. Se fosse o universo, seria uma dimensão nova e desconhecida. Mas é apenas nosso site reportando que a página que você buscou não existe. Seja como for, é melhor sair para uma área real. ;-)</p>
                                <a class="btn btn-to-home" href="/home">Voltar</a>
                            </div>
                        </div>
                    </div>

                    {/* CTA */}
                    <div class="cps-cta cps-gradient-bg">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-8 col-xs-12 sm-text-center">
                                    <h3 class="cps-cta-title">Precisa de ajuda?</h3>
                                    <p class="cps-cta-text">Estamos prontos para ajudar você</p>
                                    <div className="cps-section-header text-center">
                                        <h4 className="cps-subsection-title">Fale com a gente: {this.state.contato}</h4>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                    {/* CTA End */}

                </div>

                <footer>
                    <div className="cps-footer-upper">
                        <div className="container">
                            <div className="cps-footer-widget-area">
                                <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                        <div className="cps-widget about-widget">
                                            <a className="cps-footer-logo" href="/home">
                                                <img
                                                    className="bluveLogoTopo"
                                                    alt="Logo Bluve Rodapé"
                                                    src="https://storage.googleapis.com/bluve-bucket/b_oficial.png"
                                                ></img>
                                            </a>

                                            <p>{REDES_SOCIAIS}</p>
                                            <div className="cps-socials">
                                                <a href="https://www.facebook.com/bluvebr" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>
                                                <a href="https://www.instagram.com/bluvebr" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                        <div className="cps-widget custom-menu-widget">
                                            <h4 className="cps-widget-title">Mais</h4>
                                            <ul className="widget-menu">
                                                <li><a href="/sobre">Sobre nós</a></li>
                                                {/* <li><a href="/produtos">Nossos produtos</a></li> */}
                                                <li><a href="/time">Nosso time</a></li>
                                                {/* <li><a href="/clientes">Nossos clientes</a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cps-footer-lower">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-6 col-xs-12 xs-text-center">
                                    <p className="copyright">Copyright 2024 - C2b Software do Brasil Ltda., <a href="/home">Bluve</a>. Todos os direitos reservados
                        </p>
                                </div>
                                <div className="col-sm-6 col-xs-12 text-right xs-text-center">
                                    <ul className="footer-menu">
                                    <li><a href="/privacidade">Política de privacidade</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }

}

export default withRouter(Page404);    