import {
  getGestorId,
  getUserType,
  getGerenteId,
} from "../../../components/auth/auth-provider";
import empresaDB from "../../../dataManager/dtmEmpresa";
import gerenteDB from "../../../dataManager/dtmGerente";
import atendimentoDB from "../../../dataManager/dtmAtendimento";
import { GERENTE } from "../../../components/funcs/constants";
import { fbDatabase } from "../../../firebase/firebase";
import { salvarXlsx } from "../metas/Funcs-Metas";

export async function getEmpresas() {
  const gestorId = getGestorId();
  const empresas = await empresaDB.getByGestorIdCustom(gestorId, "razaoSocial");
  const newEmpresas = [];
  const userType = getUserType();

  if (userType === GERENTE) {
    const gerenteId = getGerenteId();
    const empresasGerente = await gerenteDB.getEmpresasGerente(
      gerenteId,
      "razaoSocial"
    );

    for (let i = 0; i < empresasGerente.length; i++) {
      const empresa = empresas.filter((item) => {
        return item.key === empresasGerente[i].id;
      });

      if (empresa && empresa.length > 0) {
        newEmpresas.push(empresa[0]);
      }
    }
  }


  const sortedNewEmpresas = sortEmpresasAlphabetically(newEmpresas);
  const sortedEmpresas = sortEmpresasAlphabetically(empresas);
    
  const displayedEmpresas = sortedNewEmpresas.length > 0 ? sortedNewEmpresas : sortedEmpresas;

  return displayedEmpresas;
}

function sortEmpresasAlphabetically(empresas) {
  if (!empresas) {
    return [];
  }
  return empresas.sort((a, b) => a.nomeFantasia.localeCompare(b.nomeFantasia));
}


export function getNextColor(color) {
  if (color === "#fff") {
    color = "#eee";
  } else {
    color = "#fff";
  }
  return color;
}

export async function buildReport(
  id,
  dataInicial,
  dataFinal,
  rede,
  gerente,
  empresas
) {
  const inicio = dataInicial.startOf("day").toDate();
  const fim = dataFinal.endOf("day").toDate();

  let horas = [];
  if (!rede) {
    horas = await atendimentoDB.getHorasByPeriodo(inicio, fim, "empresaId", id);
  } else {
    if (gerente && empresas.length > 0) {
      horas = await atendimentoDB.getHorasByPeriodoAndEmpresas(
        inicio,
        fim,
        empresas
      );
    } else {
      const gestorId = getGestorId();
      horas = await atendimentoDB.getHorasByPeriodo(
        inicio,
        fim,
        "gestorId",
        gestorId
      );
    }
  }

  if (horas === undefined) {
    return;
  }

  const dados = {};
  for (let i = 0; i < horas.length; i++) {
    for (const iterator of Object.keys(horas[i])) {
      dados[iterator] = dados[iterator] || { total: 0, convertidos: 0, encerramentosAutomaticos: 0 };
      dados[iterator].total += horas[i][iterator].total;
      dados[iterator].convertidos += horas[i][iterator].convertidos;

      // if (horas[i][iterator].encerramentosAutomaticos) {
      dados[iterator].encerramentosAutomaticos += horas[i][iterator].encerramentosAutomaticos || 0;
      // }
    }
  }

  const labels = Object.keys(dados).map(
    (item) => item.padStart(2, "0") + ":00"
  );
  const atendimentos = Object.values(dados).map((item) => item.total);
  const convertidos = Object.values(dados).map((item) => item.convertidos);
  const encerramentosManuais = Object.values(dados).map(item => item.total - item.encerramentosAutomaticos);

  const reportData = [];
  let index = 0;
  let color = "#fff";

  let totalAtendimentos = 0;
  let totalConvertidos = 0;
  let totalEncerramentosManuais = 0;

  labels.forEach((label) => {
    const horaFinal = label.split(":")[0] + ":59";
    const percentual =
      ((convertidos[index] / atendimentos[index]) * 100).toFixed(2) + "%";
    reportData.push({
      id: index,
      labels: `${label} - ${horaFinal}`,
      atendimentos: atendimentos[index],
      convertidos: convertidos[index],
      encerramentosManuais: encerramentosManuais[index],
      percentual,
      color,
    });

    totalAtendimentos += atendimentos[index];
    totalConvertidos += convertidos[index];
    totalEncerramentosManuais += encerramentosManuais[index];
    index++;
    color = getNextColor(color);
  });

  const totalPercentual =
    ((totalConvertidos / totalAtendimentos) * 100).toFixed(2) + "%";

  reportData.push({
    id: "total",
    labels: `Total`,
    atendimentos: totalAtendimentos,
    convertidos: totalConvertidos,
    encerramentosManuais: totalEncerramentosManuais,
    percentual: totalPercentual,
    color,
  });


  return reportData;
}

export async function getClientes() {
  const snapshot = await fbDatabase
    .collection('clientes')
    .orderBy('nomeFantasia')
    .get();

  const clientes = snapshot.docs.map((item) => {
    const { ativo, nomeFantasia, email } = item.data();
    return {
      id: item.id,
      nomeFantasia: nomeFantasia === 'Não definido...' ? email : nomeFantasia,
      email,
      ativo,
      empresas: null,
    };
  });

  return clientes;
}

export async function getEmpresasAdm(gestorId) {
  const snapshot = await fbDatabase
    .collection('empresas')
    .where('gestorId', '==', gestorId)
    .get();

  const empresas = snapshot.docs.map((item) => {
    const key = item.id;
    const { razaoSocial, login, gestorId } = item.data();
    return { key, razaoSocial, login, gestorId }
  });

  return empresas;
}

export async function exportToExcelVisaoLoja(data, periodo) {
  const dataInicial = periodo.inicial.toDate().toLocaleDateString();
  const dataFinal = periodo.final.toDate().toLocaleDateString();

  const infoExcel = [];


  infoExcel.push(['Lojas', 'Taxa de Conversão Total', 'Atendimentos', 'Conversões']);

  data.forEach((item) => {

    infoExcel.push([
      item.loja,
      item.taxaDeConversao,
      item.atendimentos,
      item.convertidos
    ]);
  });

  infoExcel.push(['']);

  infoExcel.push([
    `Totais (${data.length}) registros`,
    '',
    '',
    ''
  ]);

  const fileName = `Taxa de Conversão - Visão Loja - Período de ${dataInicial} até ${dataFinal}`;
  await salvarXlsx(infoExcel, fileName);    
}

export async function exportToExcelVisaoGraficos(data, periodo, empresa, compararCom) {
  const dataInicial = periodo.inicial.toDate().toLocaleDateString();
  const dataFinal = periodo.final.toDate().toLocaleDateString();

  const infoExcel = [];

  infoExcel.push(['Empresa:', empresa]);
  infoExcel.push(['Comparando com:', compararCom]);
  infoExcel.push(['']);
  infoExcel.push(['']);

  infoExcel.push(['Período', 'Atendimentos', 'Convertidos', 'Taxa de Conversão']);

  data.forEach((item) => {

    infoExcel.push([
      item.labels,      
      item.atendimentos,      
      item.convertidos,
      item.percentual,
    ]);
  });

  const fileName = `Taxa de Conversão - Por Faixa de Horários - Período de ${dataInicial} até ${dataFinal}`;
  await salvarXlsx(infoExcel, fileName);    
}


