import { useFormikContext, FieldArray } from "formik";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Row, Col } from "antd";
import { InputNumber } from "formik-antd";
import { currencyFormatter, currencyParser } from "../helperFunctions";

const meses = [
  'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
  'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
]

const getPeriodos = (date, quantidade = 4) => {
  const totalDays = moment(date).daysInMonth();
  const diasPorPeriodo = Math.floor(totalDays / quantidade);
  const referenceDay = moment(date).subtract(1, "day");

  const base = new Array(quantidade).fill({});
  const periodos = base.map((item, index) => {
    const inicio = moment(referenceDay).add((index * diasPorPeriodo) + 1, "days");
    const fim = index < (quantidade - 1) ?
      moment(inicio).add(diasPorPeriodo - 1, "days") :
      moment(date).endOf("month");
    const dias = fim.diff(inicio, "days") + 1;

    return { inicio, fim, dias };
  });

  return periodos;
};

const validatePesoTotal = (value) => {
  let error;

  if (value > 100) {
    error = 'Deve ser igual a 100%';
  } else if (value < 100) {
    const finalValue = (100 - value).toFixed(2);
    error = `Falta ${finalValue}%`;
  }

  return error;
}

const validateDiasTotal = (value, dias) => {
  let error;

  if (value > dias) {
    error = `Deve ser igual a ${dias} dias`;
  } else if (value < dias) {
    const finalValue = dias - value;

    const text1 = finalValue > 1 ? 'Faltam' : 'Falta';
    const text2 = finalValue > 1 ? 'dias' : 'dia';

    error = `${text1} ${finalValue} ${text2}`;
  }

  return error;
}

const RowInput = ({ name, max, disabled = false, validate, formatter, parser, onFocus, onBlur, onChange }) => {
  return (
    <InputNumber className="meta-periodo-porcentagem"
      name={name}
      min={0}
      max={max}
      formatter={formatter}
      parser={parser}
      decimalSeparator=","
      disabled={disabled}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      validate={validate}
    />
  );
};

const TotalRow = ({ name, valueName }) => {
  const { errors } = useFormikContext();

  return (
    <Row>
      <Col span={24}>
        <RowInput
          name={name}
          disabled={true}
          validate={validatePesoTotal}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
        />

        {errors.pesoTotal && <span className='rowError' >{errors.pesoTotal}</span>}
      </Col>
    </Row>
  );
};

const TotalDiasRow = ({ name, diasPeriodo, valueName }) => {
  const { errors } = useFormikContext();

  return (
    <Row>
      <Col span={24}>
        <RowInput
          name={name}
          disabled={true}
          validate={(value) => validateDiasTotal(value, diasPeriodo)}
          formatter={(value) => `${value} dias`}
          parser={(value) => value.replace(' dias', '')}
        />

        {errors.diasTotal && <span className='rowError' >{errors.diasTotal}</span>}
      </Col>
    </Row>
  );
};

const PeriodoRow = ({ name, valorTotal, periodo, days, diaInicio, mesInicio, diaFim, mesFim, handleFocus, handleBlur, handleChange, handleDayChange }) => {
  return (
    <Row>
      <div>
        <RowInput name={`${name}.peso`}
          max={100}
          onFocus={() => handleFocus(periodo)}
          onBlur={() => handleBlur()}
          onChange={(value) => handleChange(value, periodo)}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
        />

        <InputNumber
          name={`${name}.meta`}
          className="meta-periodo-valor"
          // value={valorTotal}
          formatter={currencyFormatter()}
          parser={currencyParser}
          disabled={true}
        />

        <InputNumber
          className="meta-periodo-dias"
          min={5}
          max={14}
          value={days}
          formatter={(value) => `${value} dias`}
          parser={(value) => value.replace(/\D/g, '')}
          onFocus={() => handleFocus(periodo)}
          onChange={(value) => handleDayChange(value, periodo)}
        />

        <span style={{ margin: "0 10px" }}>
          Semana {periodo}, de {diaInicio} de {mesInicio} a {diaFim} de {mesFim}
        </span>
      </div>
    </Row>
  );
};

const PesosRow = ({ periodos, periodoRangePicker }) => {
  const [inicioPeriodo, fimPeriodo] = periodoRangePicker;

  const { values, validateField, setFieldValue } = useFormikContext();

  const [totalValues, setTotalValues] = useState(new Array(periodos));
  const [periodoSelecionado, setPeriodoSelecionado] = useState(0);

  let calendarValue = inicioPeriodo;

  useEffect(() => {
    let totalPeso = values.periodos.reduce((soma, item) => soma + item.peso, 0);
    let totalDias = values.periodos.reduce((soma, item) => soma + item.dias, 0);

    if (totalPeso > 0) {
      totalPeso = parseFloat(totalPeso);
      totalPeso = totalPeso.toFixed(2);

      setFieldValue('pesoTotal', totalPeso);
      validateField('pesoTotal');
    }

    if (totalDias > 0) {
      setFieldValue('diasTotal', totalDias);
      validateField('diasTotal');
    }
  }, [values.periodos, setFieldValue, validateField]);

  useEffect(() => {
    const mapPeriodos = () => {
      const periodos = getPeriodos(calendarValue);
      const merge = values.periodos.map((item, index) => (
        { ...item, ...periodos[index] }
      ));

      setFieldValue("periodos", merge);
    };

    mapPeriodos();
  }, [values.dataMeta]);

  const handleFocus = (periodo) => {
    setPeriodoSelecionado(periodo);
  };

  const handleBlur = () => {
    setPeriodoSelecionado(0);
  };

  const handleChange = (percent, periodo) => {
    const index = periodo - 1;
    const dias = values.periodos[index].dias;

    const meta = values.principal > 0 ? values.principal * percent / 100 : 0;
    const metaPorDia = new Array(dias).fill(Number(Number(meta / dias).toFixed(2)));
    const vendedorPorDia = new Array(dias).fill(values.vendedores.length);

    const valueName = "periodos[" + index + "]";

    totalValues[index] = meta;

    setTotalValues(totalValues);
    setFieldValue(`${valueName}.meta`, meta);
    setFieldValue(`${valueName}.metaPorDia`, metaPorDia);
    setFieldValue(`${valueName}.vendedorPorDia`, vendedorPorDia);
  };

  const handleDayChange = (value, periodo) => {
    const { periodos, vendedores } = values;

    const index = periodo - 1;
    const sum = periodos[index].dias < value ? 1 : -1;

    for (let i = 0; i < periodos.length; i++) {
      const { inicio, fim } = periodos[i];
      const valueName = `periodos[${i}]`;

      if (i < index) continue;

      if (i !== index) {
        const novoInicio = moment(inicio).add(sum, 'days');
        const novoFim = moment(fim).add(sum, 'days');

        setFieldValue(`${valueName}.inicio`, novoInicio);
        setFieldValue(`${valueName}.fim`, novoFim);
      } else {
        const novoFim = moment(fim).add(sum, 'days');

        setFieldValue(`${valueName}.dias`, value);
        setFieldValue(`${valueName}.fim`, novoFim);

        moment()
      }

      for (let j = 0; j < vendedores.length; j++) {
        vendedores[j].dias[index] = value;
      }
    }
  };

  return (<>
    <Row>
      <FieldArray name="periodos" render={() => (
        values.periodos.map((periodo, index) => (
          <PeriodoRow key={`periodo-${index}`}
            name={`periodos[${index}]`}
            valorTotal={totalValues[index]}
            periodo={index + 1}
            days={periodo.dias}
            diaInicio={moment(periodo.inicio).format('DD')}
            diaFim={moment(periodo.fim).format('DD')}
            mesInicio={meses[moment(periodo.inicio).month()]}
            mesFim={meses[moment(periodo.fim).month()]}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleDayChange={handleDayChange}
          />
        ))
      )} />

      <div className='totalRow'>
        <div>
          <span>=</span>
          <TotalRow name='pesoTotal' />
        </div>

        <div>
          <span>=</span>
          <TotalDiasRow name='diasTotal' diasPeriodo={moment(fimPeriodo).diff(inicioPeriodo, 'days') + 1} />
        </div>
      </div>
    </Row>
  </>);
};

export default PesosRow;
