import DataManager from "./dtm";
import { fbDatabase, fbFirestore } from "../firebase/firebase";
import { getGestorId } from "../components/auth/auth-provider";

async function queryBy10(ids, ativoFiltro) {
  debugger;
  const gestorId = getGestorId();

  let ref = fbDatabase.collection("vendedores")
    .where("gestorId", "==", gestorId)
    .where("empresa.id", "in", ids);

  // Essa rotina que antes estava comentada e sem o if que checa se a variável é diferente de undefined
  // Gerou um problema nas metas: Estava sempre puxando todos os vendedores ativos e inativos na última tela de escala
  // SE você precisar mexer aqui de novo, lembre-se disso! Analise o caso
  // O exemplo foi dado pela tati na loja 077 

  if (ativoFiltro !== undefined && ativoFiltro !== null) {
    if (!ativoFiltro) {
      ref = ref.where("ativo", "==", true);
    } else {
      ref = ref.where("ativo", "==", false);
    }
  }

  const snapshot = await ref
    .orderBy("apelido")
    .get();

  if (snapshot.empty) {
    return [];
  }

  const data = snapshot.docs.map((item) => ({
    key: item.id,
    ...item.data(),
    ativoStr: item.data().ativo ? 'Sim' : 'Não',
  }));

  return data;
}

let listaObjetos = undefined;

async function getObjectById(id) {

  if (!listaObjetos) {
    listaObjetos = [];
  }

  const obj = listaObjetos.filter((item) => {
    return item.key === id;
  });

  return obj.length > 0 ? obj[0] : undefined;
}

async function postObject(obj) {
  listaObjetos.push(obj);
}


class VendedorDB extends DataManager {

  state = {
    collection: 'vendedores',
    orderBy: ['apelido'], // usado na classe pai
  }


  async getByIdOnCache(id) {
    let vendedor = await getObjectById(id);
    if (!vendedor) {
      let snapshot = await fbDatabase
        .collection(this.state.collection)
        .doc(id)
        .get();

      if (!snapshot.exists) {
        return undefined;
      }

      vendedor = {
        key: snapshot.id,
        ...snapshot.data(),
      }
    }

    if (vendedor) {
      postObject(vendedor);
    }

    return vendedor;

    // return {
    //   key: snapshot.id,
    //   ...snapshot.data(),
    // };
  }

  async getByGestorIdCustom(gestorId) {
    let snapshot = fbDatabase.collection(this.state.collection)
      .where("gestorId", "==", gestorId);

    if (this.state.orderBy.length > 0) {
      for (let index = 0; index < this.state.orderBy.length; index++) {
        const order = this.state.orderBy[index];
        snapshot = snapshot.orderBy(order);
      }
    }

    snapshot = await snapshot.get();

    if (snapshot.empty) {
      return undefined;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
      ativoStr: item.data().ativo ? 'Sim' : 'Não',
    }));

    return data;
  }

  async getByEmpresaId(empresaId) {
    const data = await this.getByParamValue('empresa.id', empresaId);
    return data;
  }

  async getByEmpresaIdAndAtivo(empresaId, ativo) {

    const snapshot = await fbDatabase.collection(this.state.collection)
      .where("empresa.id", "==", empresaId)
      .where("ativo", "==", ativo)
      .get()

    const data = snapshot.docs.map((doc) => ({
      key: doc.id,
      ...doc.data(),
      ativoStr: doc.data().ativo ? 'Sim' : 'Não'
    }))

    data.sort((a, b) => a.nome.localeCompare(b.nome));
    return data;
  }

  async getByGestorId(gestorId) {
    const data = await this.getByParamValue('gestorId', gestorId);
    return data;
  }

  async getByCPF(cpf) {
    const data = await this.getByParamValue('cpf', cpf);
    return data;
  }

  async getByEmail(email) {
    const data = await this.getByParamValue('email', email);
    return data;
  }

  async loadByEmpresas(empresas, ativoFiltro) {
    let ids = []
    let data = [];
    for (let index = 0; index < empresas.length; index++) {
      ids.push(empresas[index].key);
      if (ids.length >= 10) {
        const ljs = await queryBy10(ids, ativoFiltro);
        data.push.apply(data, ljs);
        ids = [];
      }
    }

    if (ids.length > 0) {
      debugger;      
      const ljs = await queryBy10(ids, ativoFiltro);
      data.push.apply(data, ljs);
    }
    return data;
  }

  async monitorarVendedores(gestorId, callback) {
    const monitoramentoTempoReal = fbDatabase
      .collection("vendedores")
      .where("gestorId", "==", gestorId)
      .where("ativo", "==", true)
      .onSnapshot((querySnapshot) => {
        if (callback) {
          callback(querySnapshot);
        }
      });
      return monitoramentoTempoReal;
  }
};

const vendedorDB = new VendedorDB();
export default vendedorDB;
