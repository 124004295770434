export const COLUMNS_WITH_MANAGER = [
    {
        title: 'CNPJ',
        dataIndex: 'cnpj',
        key: 'cnpj',
    },
    {
        title: 'Razão Social',
        dataIndex: 'razaoSocial',
        key: 'razaoSocial',
        editable: false,
    },
    {
        title: 'Nome Fantasia',
        dataIndex: 'nomeFantasia',
        key: 'nomeFantasia',
    },
    {
        title: 'Gerente',
        dataIndex: 'gerente.nome',
        key: 'gerente',
    },
    {
        title: 'Comissão Fixo',
        dataIndex: 'comissao.comissaoFixoStr',
        key: 'fixo',
        align:'right'
    },
    {
        title: 'Comissão %',
        dataIndex: 'comissao.percentual',
        key: 'percentual',
        align:'right'
    }
];


export const COLUMNS_WITHOUT_MANAGER = [
    {
        title: 'CNPJ',
        dataIndex: 'cnpj',
        key: 'cnpj',
    },
    {
        title: 'Razão Social',
        dataIndex: 'razaoSocial',
        key: 'razaoSocial',
        editable: false,
    },
    {
        title: 'Nome Fantasia',
        dataIndex: 'nomeFantasia',
        key: 'nomeFantasia',
    },
    {
        title: 'Comissão Fixo',
        dataIndex: 'comissao.comissaoFixoStr',
        key: 'fixo',
        align:'right'
    },
    {
        title: 'Comissão %',
        dataIndex: 'comissao.percentual',
        key: 'percentual',
        align:'right'
    },    
];