import axios from 'axios';
import { useEffect } from 'react';

const LOGGLY_API_KEY = '5e45c8c5-63db-4664-bd2d-f07b9424899d';
const LOGGLY_ENDPOINT = `https://logs-01.loggly.com/inputs/${LOGGLY_API_KEY}/tag/http/`;

const logToLoggly = async (level, message, tags) => {
  try {
    await axios.post(LOGGLY_ENDPOINT, { level, message, tags });
  } catch (error) {
    console.error('Failed to log to Loggly:', error);
  }
};


export default logToLoggly;
