import React from "react";
import { Form, Modal } from "antd";

class WhatsNew extends React.Component {
  state = {
    visible: false,
  };

  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    state.visible = props.visible;
    return true;
  }

  onCancel() {
    this.props.onCancel();
  }

  render() {
    return (
      <Modal
        title=""
        visible={this.state.visible}
        destroyOnClose={true}
        centered={true}
        onCancel={this.onCancel}
        footer={null}
      >
        <iframe
          id="ston-Rh0DXt188G"
          width="100%"
          height="600px"
          frameBorder="0"
          name="StonlyExplanation"
          src="https://stonly.com/borderlessembed/pt/Rh0DXt188G/view/"
        ></iframe>
      </Modal>
    );
  }
}

export default Form.create()(WhatsNew);
