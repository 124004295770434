import DataManager from "./dtm";
import { fbDatabase, fbFirestore } from "../firebase/firebase";
import { getGestorId } from "../components/auth/auth-provider";


async function queryBy10(ids, dataIni, dataFim, vendeu) {
    let query = fbDatabase.collection("conversoes")
        .where("dataInicial", ">=", dataIni)
        .where("dataInicial", "<=", dataFim)
        .where("empresaId", "in", ids);

    if (vendeu !== undefined) {
        query = query.where('vendeu', '==', vendeu)
    }

    const snapshot = await query.get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmConversaoJSX-queryBy10');
    xRef.set({
        collection: 'conversoes',
        lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
        count: fbFirestore.FieldValue.increment(snapshot.size),
        lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    if (snapshot.empty) {
        return [];
    }

    const data = snapshot.docs.map((item) => ({
        key: item.id,
        ...item.data(),
    }));

    return data;
}

function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

function getInitials(name) {
    let splitName = name.split(" ");
    if (splitName.length >= 3) {
        for (let i = 1; i < splitName.length - 1; i++) {
            splitName[i] = ""; // splitName[i].charAt(0) + '.';
        }
    }

    let retName = "";

    for (let i = 0; i < splitName.length; i++) {
        if (splitName[i] !== "") {
            if (retName !== "") {
                retName += " ";
            }
            retName += splitName[i];
        }
    }
    return retName;
}


class ConversaoDB extends DataManager {

    state = {
        collection: 'conversoes',
        orderBy: [], // usado na classe pai
    }

    async getByVendedorAndPeriodo(dataIni, dataFim, vendedorId) {
        const snapshot = await fbDatabase.collection(this.state.collection)
            .where("vendedor.id", "==", vendedorId)
            .where("dataInicial", ">=", dataIni)
            .where("dataInicial", "<=", dataFim)
            .get();

        if (snapshot.empty) {
            return undefined;
        }

        debugger;

        return snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
        }));
    }

    async getByPeriodo(dataIni, dataFim, customParamName, customParamValue, vendedoresDesagrupados) {
        const snapshot = await fbDatabase.collection(this.state.collection)
            .where(customParamName, "==", customParamValue)
            .where("dataInicial", ">=", dataIni)
            .where("dataInicial", "<=", dataFim)
            .get();

        if (snapshot.empty) {
            return undefined;
        }

        debugger;

        if (vendedoresDesagrupados === true) {
            return snapshot.docs.map((item) => ({
                key: item.id,
                ...item.data(),
            }));
        }

        let vendedores = [];
        snapshot.forEach((conversao) => {
            const item = conversao.data();
            if (item.dataInicial.toDate() >= dataIni) {
                let vendedor = vendedores.filter((vend) => {
                    return vend.id === item.vendedor.id;
                })[0];

                if (!vendedor) {
                    let vendNome = toTitleCase(item.vendedor.nome);
                    vendNome = getInitials(vendNome);
                    vendedor = {
                        id: item.vendedor.id,
                        nome: vendNome,
                        pecas: 0,
                        atendimentos: 0,
                        convertidos: 0,
                    };
                }

                vendedor["pecas"] += item.produtos ? item.produtos.length : 0;
                vendedor["atendimentos"] += 1;
                vendedor["convertidos"] += item.vendeu ? 1 : 0;

                vendedores = vendedores.filter((vend) => {
                    return vend.id !== item.vendedor.id;
                });
                vendedores.push(vendedor);
            }
        });

        // Ordeno pelo nome do vendedor
        vendedores.sort((a, b) => (a.nome > b.nome ? 1 : -1));

        return vendedores;
    }

    async getVendasPerdidasByPeriodo(dataIni, dataFim, empresaId) {
        const gestorId = getGestorId();

        let query = fbDatabase
            .collection(this.state.collection)
            .where("gestorId", "==", gestorId);

        if (empresaId) {
            query = query.where("empresaId", "==", empresaId);
        }

        const snapshot = await query
            .where("vendeu", "==", false)
            .where("dataInicial", ">=", dataIni)
            .where("dataInicial", "<=", dataFim)
            .get();

        if (snapshot.docs.length === 0) {
            return [];
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data()
        }));

        return data;
    }

    async getVendasPerdidasByPeriodoAndEmpresas(dataIni, dataFim, empresasIds) {
        let ids = [];
        let conversoes = [];
        for (let index = 0; index < empresasIds.length; index++) {
            ids.push(empresasIds[index].key);
            if (ids.length >= 10) {
                const data = await queryBy10(ids, dataIni, dataFim, false);
                conversoes.push.apply(conversoes, data);
                ids = [];
            }
        }
        if (ids.length > 0) {
            const data = await queryBy10(ids, dataIni, dataFim, false);
            conversoes.push.apply(conversoes, data);
        }
        return conversoes;
    }

    async countAtendimentos(dataIni, dataFim, administratorId, empresasIds) {
        let snapshot = undefined;
        let atendimentos = [];
        if (administratorId) {
            snapshot = await fbDatabase.collection(this.state.collection)
                .where("gestorId", "==", administratorId)
                .where("dataInicial", ">=", dataIni)
                .where("dataInicial", "<=", dataFim)
                .get();

            atendimentos = snapshot.docs.map((item) => ({
                key: item.id,
                ...item.data()
            }));
        }
        else if (empresasIds) {
            let ids = [];
            for (let index = 0; index < empresasIds.length; index++) {
                ids.push(empresasIds[index]);
                if (ids.length >= 10) {
                    const empresas = await queryBy10(ids, dataIni, dataFim);
                    atendimentos.push.apply(atendimentos, empresas);
                    ids = [];
                }
            }
            if (ids.length > 0) {
                const empresas = await queryBy10(ids, dataIni, dataFim);
                atendimentos.push.apply(atendimentos, empresas);
            }
        }

        const perdas = atendimentos.filter((item) => {
            return item.vendeu === false;
        });

        return {
            atendimentos: atendimentos.length,
            perdas: perdas.length
        };
    }

    async getPerdasByPeriodoMotivo(dataIni, dataFim, motivoId, empreasIds) {
        const data = await this.getVendasPerdidasByPeriodoAndEmpresas(dataIni, dataFim, empreasIds);

        if (data.length === 0) {
            return [];
        }

        const perdas = [];
        data.forEach(perda => {
            perda.motivos !== undefined && perda.motivos.forEach(motivo => {
                if (motivo.id === motivoId) {
                    perdas.push(perda);
                }
            })
        });
        return perdas;
    }

    async monitorarAtendimentos(dataInicial, dataFinal, gestorId, callBack) {
        const monitoramentoTempoReal = fbDatabase.collection("conversoes")
            .where("dataInicial", ">=", dataInicial)
            .where("dataInicial", "<=", dataFinal)
            .where("gestorId", "==", gestorId)
            .onSnapshot((querySnapshot) => {
                if (callBack) {
                    callBack(querySnapshot);
                }
            });

        return monitoramentoTempoReal;
    }
}

const conversaoDB = new ConversaoDB();
export default conversaoDB;