import { Component } from "react";

let startTime = undefined;
let endTime = undefined;

class Funcoes extends Component {

    spentTimeStart() {
        startTime = new Date();
    }

    spentTimeEnd() {
        endTime = new Date();
        var timeDiff = endTime - startTime; //in ms

        // strip the ms
        timeDiff /= 1000;

        // get seconds 
        var seconds = Math.round(timeDiff);
        return seconds;
    }

    toTitleCase(str) {
        str = str.trim();
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    strToBool(str) {
        return str === 'true';
    }
}

const funcoes = new Funcoes();
export default funcoes;