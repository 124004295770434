import {
    getGestorId,
    getUserType,
    getGerenteId,
} from "../../../components/auth/auth-provider";
import empresaDB from "../../../dataManager/dtmEmpresa";
import { GERENTE } from "../../../components/funcs/constants";
import gerenteDB from "../../../dataManager/dtmGerente";
import indicacoesDB from "../../../dataManager/dtmIndicacoes";

export function getNextColor(color) {
    if (color === "#fff") {
      color = "#eee";
    } else {
      color = "#fff";
    }
    return color;
}

export async function getEmpresas() {
    const gestorId = getGestorId();
    const empresas = await empresaDB.getByGestorIdCustom(gestorId, 'login.login');
    const newEmpresas = [];
    const userType = getUserType();
  
    if (userType === GERENTE) {
      const gerenteId = getGerenteId();
      const empresasGerente = await gerenteDB.getEmpresasGerente(gerenteId, 'razaoSocial');
  
      for (let i = 0; i < empresasGerente.length; i++) {
        const empresa = empresas.filter((item) => {
          return item.key === empresasGerente[i].id;
        });
  
        if (empresa && empresa.length > 0) {
          newEmpresas.push(empresa[0]);
        }
      }
    }
    return newEmpresas.length > 0 ? newEmpresas : empresas;
}

export async function buildReport(empresas, dataInicial, dataFinal){
    const inicio = dataInicial.startOf("day").toDate();
    const fim = dataFinal.endOf("day").toDate();
    
    const indicacoes = await Promise.all(empresas.map(async (item) => {
        const empresaId = item.key;
        const indicacao = await indicacoesDB.getByEmpresaIdAndPeriodoAndConverteu(empresaId, inicio, fim, true);
        
        if(indicacao.length > 0){
            indicacao.nomeEmpresa = item.login.login;
        }
        return indicacao;
    }));

    const filteredIndicacoes = indicacoes.filter(value => value.length > 0);

    let count = 0;
    let element = [];
    const lista = filteredIndicacoes.map((item) => {
      count = 0;
      if(item.length > 0){
          for(let i in item){
              if(!isNaN(i)){
                  count += item[i].valorEmCentavos;
              }
              element = {
                  nomeEmpresa: item.nomeEmpresa,
                  valorTotal: formatMoeda(count)
              }
          }
      }
      return element
    });

    lista.sort((a, b) => (a.nomeEmpresa > b.nomeEmpresa ? 1 : -1));

    let color = '#fff';
    let novaLista = [];
    let counter = 1;
    lista.forEach((item) => {
            item["key"] = counter;
            counter++;
            item["color"] = color;
            color = getNextColor(color);
            novaLista.push(item);
    });

    color = getNextColor(color);
    
    if(novaLista.length === 0){
      return;
    }
    return novaLista;
}

function formatMoeda(value){
    return 'R$ ' + (value / 100).toFixed(2)
  }