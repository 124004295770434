import DataManager from "./dtm";
import { getGestorId } from "../components/auth/auth-provider";
import { fbDatabase, fbFirestore } from "../firebase/firebase";
import { message } from 'antd';

class ProdutoDB extends DataManager {

    state = {
        collection: 'produtos',
        orderBy: ['nome'], // usado na classe pai
        count: 0
    }

    async addBatch(productsArray) {
        let batchArray = [];
        batchArray.push(fbDatabase.batch());
        let operationalCounter = 0;
        let batchIndex = 0;
        let newProductsArray = [];

        await productsArray.forEach(async (product) => {

            const collectionRef = await fbDatabase.collection(this.state.collection).doc();

            product['key'] = collectionRef.id;

            batchArray[batchIndex].set(collectionRef, product);

            operationalCounter++;

            if (operationalCounter === 499) {
                batchArray.push(fbDatabase.batch());
                batchIndex++;
                operationalCounter = 0;
            }
        })

        let recorded = false;
        let newListaProdutos = []
        
        await Promise.all(batchArray.map(async (batch) => await batch.commit()
            .then(() => {
                batch.pf.forEach((produto) => {
                    const produtoFields = produto.value.proto.mapValue.fields
                    newListaProdutos.push({
                        ativo: Object.entries(produtoFields.ativo)[0][1],
                        nome: Object.entries(produtoFields.nome)[0][1],
                        codigo: Object.entries(produtoFields.codigo)[0][1],
                        gestorId: Object.entries(produtoFields.gestorId)[0][1],
                        key: Object.entries(produtoFields.key)[0][1],

                    })
                });
                recorded = true;
            })
            .catch((err) => {
                recorded = false;
            })
        ))

        return ({
            recorded: recorded,
            produtos: newListaProdutos
        })
    }

    async getByGestorIdLimited(gestorId, limit, ativoFiltro) {
        const snapshot = await fbDatabase.collection(this.state.collection)
            .where("gestorId", "==", gestorId)
            .orderBy("nome")
            .where("ativo", "==", !ativoFiltro)
            .limit(limit)
            .get()
        
        const data = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data(),
            ativoStr: doc.data().ativo ? 'Sim' : 'Não'
        }))

        return data;
    }

    async getProductsByLetterLimited(gestorId, key, limit, ativoFiltro) {
        const end = key.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
        let ref = fbDatabase.collection(this.state.collection)
            .where("gestorId", "==", gestorId)
            .where('busca', ">=", key)
            .where('busca', '<', end)

        if (!ativoFiltro) {
            ref = ref.where("ativo", "==", true);
        }

        const snapshot = await ref
            .limit(limit)
            .get();
        
        return snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data(),
            ativoStr: doc.data().ativo ? 'Sim' : 'Não'
        }))
    }

    async getByPalavraChaveLimited(gestorId, words, limit = 5, alreadyFoundProducts = []) {

        let query = fbDatabase.collection(this.state.collection)
            .where("gestorId", "==", gestorId)

        for (let i of words) {
            query = query.where("keywords." + i, "==", "true");
        }

        if (alreadyFoundProducts.length > 0) {
            query = query.where(fbFirestore.FieldPath.documentId(), "not-in", alreadyFoundProducts)
        }

        query = query.limit(limit);

        const snapshot = await query.get();

        return snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

    }

    async getByGestorIdCustom(gestorId) {
        let query = fbDatabase.collection(this.state.collection)
            .where("gestorId", "==", gestorId);

        if (this.state.orderBy.length > 0) {
            for (let index = 0; index < this.state.orderBy.length; index++) {
                const order = this.state.orderBy[index];
                query = query.orderBy(order);
            }
        }

        const snapshot = await query.get();

        /** X-TUDO */
        let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmProdutoJSX-ProdutoDB-getByGestorIdCustom');
        xRef.set({
            collection: 'produtos',
            lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
            count: fbFirestore.FieldValue.increment(snapshot.size),
            lastIncrement: snapshot.size
        }, { merge: true }).catch(error => console.error(error));

        if (snapshot.empty) {
            return undefined;
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByCodigo(codigo) {
        const gestorId = getGestorId();
        const snapshot = await fbDatabase
            .collection(this.state.collection)
            .where("gestorId", "==", gestorId)
            .where('codigo', "==", codigo)
            .get();

        /** X-TUDO */
        let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmProdutoJSX-ProdutoDB-getByCodigo');
        xRef.set({
            collection: 'produtos',
            lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
            count: fbFirestore.FieldValue.increment(snapshot.size),
            lastIncrement: snapshot.size
        }, { merge: true }).catch(error => console.error(error));

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data()
        }));

        return data;
    }

    async getByNome(nome) {
        const gestorId = getGestorId();
        const snapshot = await fbDatabase
            .collection(this.state.collection)
            .where("gestorId", "==", gestorId)
            .where('nome', "==", nome)
            .get();

        /** X-TUDO */
        let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmProdutoJSX-ProdutoDB-getByNome');
        xRef.set({
            collection: 'produtos',
            lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
            count: fbFirestore.FieldValue.increment(snapshot.size),
            lastIncrement: snapshot.size
        }, { merge: true }).catch(error => console.error(error));

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data()
        }));

        return data;
    }

    async like(nome) {
        const gestorId = getGestorId();

        const snapshot = await fbDatabase
            .collection(this.state.collection)
            .where("gestorId", "==", gestorId)
            .where("nome", ">=", nome.upper())
            .where("nome", "<=", nome.lower() + "\uf8ff")
            .get();

        /** X-TUDO */
        let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmProdutoJSX-ProdutoDB-like');
        xRef.set({
            collection: 'produtos',
            lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
            count: fbFirestore.FieldValue.increment(snapshot.size),
            lastIncrement: snapshot.size
        }, { merge: true }).catch(error => console.error(error));

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data()
        }));

        return data;
    }


    async tempUpdateProdutos() {
        // const produtos = await fbDatabase.collection("produtos").get();

        // const total = produtos.size;
        // let updated = 0;
        // console.clear();
        // produtos.forEach(produto => {
        //     let item = {
        //         ...produto.data(),
        //     }

        //     delete item['nomeLower'];
        //     delete item['atualizadoEm'];

        //     fbDatabase.collection("produtos").doc(produto.id).
        //         set({
        //             ...item,
        //         })
        //         .then(res => {
        //             updated += 1;
        //         });
        // })
    }

    async tempDesativarLoft() {

        return;

        // // let deb = true;
        // const produtosQuery = fbDatabase
        //     .collection("produtos")
        //     .where("gestorId", "==", "VDH6URz3ocPfBy5Svql8hfRRp9k1")
        //     .where("pdvId", ">", "")
        //     .where("ativo", "==", true);
        // const result = await produtosQuery.get();

        // console.clear();
        // result.docs.forEach(async (element) => {

        //     await fbDatabase
        //         .collection("produtos")
        //         .doc(element.id)
        //         .update({ ativo: false });
        //     console.count("updated");
        // });


        // const snapshot = await fbDatabase
        // .collection(this.state.collection)
        // .where("gestorId", "==", 'VDH6URz3ocPfBy5Svql8hfRRp9k1')
        // .get();

        // let atualizar = true;
        // snapshot.docs.forEach(produto => {
        //     if (atualizar && produto.data().pdvId !== undefined) {
        //         produto.data().ativo = false;
        //         this.update(produto.id, produto);
        //     }
        // })

    }
};

const produtoDB = new ProdutoDB();
export default produtoDB;