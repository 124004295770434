import React from "react";
import ReactApexChart from "react-apexcharts";
import { Skeleton, Card, Button } from "antd";
import Meta from "antd/lib/card/Meta";

const ButtonGroup = Button.Group;

// As a child component it receives props as an argument. Here we are just destructuring it.
const MyComponent = ({
  isLoading,
  options,
  series,
  type,
  chartButtonCallback,
  title
}) => (
  <Card
    bodyStyle={{ padding: "0" }}
    style={{ marginTop: "2rem", marginBottom: "2rem", border: 0 }}
  >
    <Skeleton loading={isLoading} paragraph={{ rows: 8 }}>
      <Meta
        title={title}
        avatar={
          <ButtonGroup>
            <Button
              id="bar"
              icon="bar-chart"
              size="small"
              onClick={chartButtonCallback}
            ></Button>
            <Button
              id="line"
              icon="line-chart"
              size="small"
              onClick={chartButtonCallback}
            ></Button>
          </ButtonGroup>
        }
      />

      <ReactApexChart
        id="chart"
        options={options}
        series={series}
        type={type}
        height={'450'}
        // width="100%"
      />
    </Skeleton>
  </Card>
);

export default MyComponent;
