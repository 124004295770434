import {
  getGestorId,
  getUserType,
  getGerenteId,
} from "../../../components/auth/auth-provider";
import empresaDB from "../../../dataManager/dtmEmpresa";
import { GERENTE } from "../../../components/funcs/constants";
import gerenteDB from "../../../dataManager/dtmGerente";
import conversaoDB from "../../../dataManager/dtmConversao";
import vendedorDB from "../../../dataManager/dtmVendedor";
import funcoes from "../../../components/funcs/funcoes";
import { salvarXlsx } from "../metas/Funcs-Metas";


export function getNextColor(color) {
  if (color === "#fff") {
    color = "#eee";
  } else {
    color = "#fff";
  }
  return color;
}

export async function getEmpresas() {
  const gestorId = getGestorId();
  const empresas = await empresaDB.getByGestorIdCustom(gestorId, 'login.login');
  const newEmpresas = [];
  const userType = getUserType();

  if (userType === GERENTE) {
    const gerenteId = getGerenteId();
    const empresasGerente = await gerenteDB.getEmpresasGerente(gerenteId, 'razaoSocial');

    for (let i = 0; i < empresasGerente.length; i++) {
      const empresa = empresas.filter((item) => {
        return item.key === empresasGerente[i].id;
      });

      if (empresa && empresa.length > 0) {
        newEmpresas.push(empresa[0]);
      }
    }
  }

  const sortedNewEmpresas = sortEmpresasAlphabetically(newEmpresas);
  const sortedEmpresas = sortEmpresasAlphabetically(empresas);
  const displayedEmpresas = sortedNewEmpresas.length > 0 ? sortedNewEmpresas : sortedEmpresas;

  return displayedEmpresas;
}

function sortEmpresasAlphabetically(empresas) {
  if (!empresas) {
    return [];
  }
  return empresas.sort((a, b) => a.nomeFantasia.localeCompare(b.nomeFantasia));
}

export async function buildReport(id, dataInicial, dataFinal, separarEmpresa) {
  const inicio = dataInicial.startOf("day").toDate();
  const fim = dataFinal.endOf("day").toDate();
  let snapshot;
  if (id !== "todas") {
    snapshot = await conversaoDB.getVendasPerdidasByPeriodo(inicio, fim, id);
  } else {
    snapshot = await conversaoDB.getVendasPerdidasByPeriodo(inicio, fim);
  }
  if (snapshot.length === 0) {
    return;
  }

  let lista = [];
  console.clear();

  // let parar = true;


  for (let index = 0; index < snapshot.length; index++) {
    const item = snapshot[index];
    const produtos = item.produtos.filter((prod) => {
      return prod.id === "--";
    });

    for (let index = 0; index < produtos.length; index++) {
      const prod = produtos[index];
      let vendedor = undefined;
      if (id === 'todas' && separarEmpresa) {
        vendedor = await vendedorDB.getByIdOnCache(item.vendedor.id);
      }

      const nomeProduto = funcoes.toTitleCase(prod.nome);
      let produto = await lista.filter((item) => {
        if (vendedor !== undefined) {
          return item.nome === nomeProduto && item.empresa === vendedor.empresa.nome;
        }
        else {
          return item.nome === nomeProduto;
        }

      })[0];


      const nomeEmpresa = vendedor ? vendedor.empresa.nome : '';
      const key = `${nomeProduto}&&pp&&${nomeEmpresa}`;

      if (!produto) {
        const item = {
          key,
          nome: nomeProduto,
          empresa: nomeEmpresa,
          total: 1,
        };
        lista.push(item);
      } else {
        produto["total"] += 1;
        let novaLista = [];
        novaLista = lista.filter((item) => {
          return item.key !== key;
        });

        novaLista.push(produto);
        lista = novaLista;
      }
    };
  };
  // Ordeno pelo nome do vendedor
  lista.sort((a, b) => (a.total > b.total ? -1 : 1));

  let color = "#fff";
  const novaLista = [];

  let counter = 1;
  lista.forEach((item) => {
    item["key"] = counter;
    counter++;
    item["color"] = color;
    color = getNextColor(color);
    novaLista.push(item);
  });

  return novaLista;
}

export async function exportToExcel(data, empresaNome, periodo, colunas) {
  const dataInicial = periodo.inicial.toDate().toLocaleDateString();
  const dataFinal = periodo.final.toDate().toLocaleDateString();

  const infoExcel = [];
  infoExcel.push([`Empresa: ${empresaNome}`]);
  infoExcel.push(['']);
  infoExcel.push([colunas[0].title]);

  colunas.slice(1).forEach(coluna => {
    infoExcel[2] = infoExcel[2].concat(coluna.title);
  });

  let total = 0;

  data.forEach(item => {
    infoExcel.push([item.nome]);

    const index = infoExcel.length - 1;
    if (colunas.length === 3) {
      infoExcel[index] = infoExcel[index].concat(item.empresa);
    }
    infoExcel[index] = infoExcel[index].concat(item.total);
    total += item.total;
  });

  infoExcel.push(['']);

  infoExcel.push([
    `(${data.length}) registros`,
    `(${total}) produtos fora do Mix`,
  ]);

  const fileName = `Produtos fora do Mix - Período de ${dataInicial} até ${dataFinal}`;
  await salvarXlsx(infoExcel, fileName);
}