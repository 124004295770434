import React from "react";
import { withRouter } from "react-router";
import { Select, Table, Button, Icon, message, Modal } from "antd";
import "./Pagamento.css";
import BluveLayout from "../../../components/layout/BluveLayout";
import mitte from "../../../components/mitte/mite-pro";
import lojaDB from "../../../dataManager/dtmLoja";
import indicadorDB from "../../../dataManager/dtmIndicador";
import indicacoesDB from "../../../dataManager/dtmIndicacoes";

const { confirm } = Modal;
const { warning } = Modal;
const { Option } = Select;

function formatarMoeda(valor) {
  valor = parseFloat(valor).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });
  return valor;
}

async function confirmar(message) {
  return new Promise((resolve, reject) => {
    confirm({
      title: "Confirmação",
      content: message,
      okText: "Sim",
      cancelText: "Não",
      async onOk() {
        resolve(true);
      },
      onCancel() {
        reject(false);
      },
    });
  }).catch((error) => {
    console.log(error);
    return false;
  });
}

async function avisar(message) {
  return new Promise((resolve, reject) => {
    warning({
      title: "Atenção",
      content: message,
      async onOk() {
        resolve(true);
      },
    });
  }).catch((error) => {
    console.log("Oops errors!");
    return false;
  });
}





class Pagamento extends React.Component {
  state = {
    indicadorId: undefined,
    indicadores: [],
    indicacoes: [],
    disableRowSelection: false,
    selectedRowKeys: [],
    lojas: [],
    canUpdate: false,
    loading: true,
    totalPagar: 'R$ 0,00',
    nomeIndicadorTemp: '',
    nomeIndicador: 'Todos',
    disabledControlls: false,
    gridLoading: false,
  };

  options = [];

  constructor(props) {
    super(props);
    this.headerElement = React.createRef();
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
    this.getNomeLojaIndicador = this.getNomeLojaIndicador.bind(this);
    this.loadLojas = this.loadLojas.bind(this);
    this.disableControllsCallback = this.disableControllsCallback.bind(this);
    this.baixarSelecionados = this.baixarSelecionados.bind(this);
    this.sendPaidMail = this.sendPaidMail.bind(this);
  }

  async componentDidMount() {
    await this.loadLojas();
    const indicadores = await this.getIndicadores();

    if (indicadores.length > 0) {
      for (const indicador of indicadores) {
        this.options.push(<Option key={indicador.key} value={indicador.key}>{indicador.nome}</Option>);
      }

      if (this.options.length > 1) {
        this.options.push(<Option key={'todos'} value={'todos'}>{'Todos'}</Option>);
      }

      this.setState({
        indicadorId: indicadores[0].id,
        nomeIndicadorTemp: indicadores[0].nome,
        nomeIndicador: 'Todos',
        indicadores,
      });
    }

    this.setState({ loading: false });
  }

  disableControllsCallback(value) {
    this.setState({ disabledControlls: value, gridLoading: value });
  }

  async getIndicadores() {
    const indicadores = await indicadorDB.loadByLojas(this.state.lojas);
    return indicadores;
  }

  async loadLojas() {
    let lojas = await lojaDB.load();
    this.setState({ lojas });
  };

  getNomeLojaIndicador(key) {
    console.clear();
    let nomeLoja = '';
    this.state.indicadores.forEach(indicador => {
      if (indicador.id === key) {
        nomeLoja = indicador.loja.nome;
      }
    });
    return nomeLoja;
  }

  onSelectChange(value, obj) {
    this.setState({ indicadorId: value, nomeIndicadorTemp: obj.props.children });
  }

  formatDate(date) {
    date = new Date(date);
    return (("0" + (date.getDate())).slice(-2) + "-" +
      ("0" + (date.getMonth() + 1)).slice(-2)) + "-" +
      date.getFullYear() + " " +
      ("0" + (date.getHours())).slice(-2) + ":" +
      ("0" + (date.getMinutes())).slice(-2) + ":" +
      ("0" + (date.getSeconds())).slice(-2);
  }

  async onFilterClick() {
    if (this.state.indicadorId === undefined) {
      message.warning('Selecione alguém antes');
      return;
    }
    this.setState({
      selectedRowKeys: [],
      nomeIndicador: this.state.nomeIndicadorTemp,
      totalPagar: 'R$ 0,00',
      disableRowSelection: this.state.indicadorId === 'todos',
      loading: true,
    });

    let indicacoes = undefined;
    const pagou = false;
    const converteu = true;
    if (this.state.indicadorId !== 'todos') {
      indicacoes = await indicacoesDB.getByIndicadorId2(this.state.indicadorId, pagou, converteu);
    }
    else {
      indicacoes = await indicacoesDB.loadByIndicadores(this.state.indicadores, pagou, converteu)
    }

    if (indicacoes.length === 0) {
      message.info(`Não há indicações feitas por ${this.state.nomeIndicador}`);
      this.setState({
        indicacoes: [],
        loading: false,
      });

      return;
    }

    const data = [];
    for (let index = 0; index < indicacoes.length; ++index) {
      const item = indicacoes[index];
      data.push({
        key: item.id,
        codigo: item.codigo,
        indicador: item.indicador,
        valor: formatarMoeda(item.valorEmCentavos / 100),
        valorEmCentavos: item.valorEmCentavos,
        loja: this.getNomeLojaIndicador(item.indicador.id),
        dataCompra: this.formatDate(item.dataCompra.toDate()),
        dataIndicacao: this.formatDate(item.dataIndicacao.toDate()),
        comissao: item.comissaoEmCentavos,
        comissaoStr: formatarMoeda(item.comissaoEmCentavos / 100),
      })
    };

    this.setState({
      indicacoes: data,
      loading: false
    });

    if (this.state.indicadorId === 'todos') {
      message.warning('Ao filtrar todos os indicadores, a opção de seleção e pagamento ficam indisponíveis');
    }
  }

  onCheckBoxChange = (selectedRowKeys, selectedRows) => {
    let valorAPagar = 0;
    for (let index = 0; index < selectedRows.length; ++index) {
      valorAPagar += selectedRows[index].comissao;
    }

    const totalPagar = formatarMoeda(valorAPagar / 100);
    this.setState({ selectedRowKeys, totalPagar });
  };

  async baixarSelecionados() {
    const isOk = await indicacoesDB.baixarIndicacoes(this.state.selectedRowKeys);
    if (isOk) {
      this.sendPaidMail();
    }
  }

  async sendPaidMail() {
    const indicador = this.state.indicadores.filter((item) => {
      return item.key === this.state.indicadorId
    })[0];

    await mitte.sendPaidMail(
      indicador.email,
      indicador.nome,
      this.state.totalPagar,
      this.state.selectedRowKeys.length,
      indicador.loja.nome
    );
  }

  render() {
    const columns = [
      {
        title: "Indicador",
        dataIndex: "indicador.nome",
        key: "nome"
      },
      {
        key: 'loja',
        title: 'Loja',
        dataIndex: 'loja',
      },
      {
        title: "Código",
        dataIndex: "codigo",
        key: "codigo"
      },
      {
        title: "Data Indicação",
        dataIndex: "dataIndicacao",
        key: "dataIndicacao"
      },
      {
        title: "Data Compra",
        dataIndex: "dataCompra",
        key: "dataCompra"
      },
      {
        title: "Valor Compra",
        dataIndex: "valor",
        key: "valor"
      },
      {
        title: "% Comissão",
        dataIndex: "comissaoStr",
        key: "comissaoStr"
      },
    ];

    const selectedRowKeys = this.state.selectedRowKeys;
    const disable = this.state.disableRowSelection;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onCheckBoxChange,

      getCheckboxProps: record => ({
        disabled: disable,
        name: record.name,
      }),

    };

    return (

      <BluveLayout selectItem={"parceiros/pagamentos"}>

        <div>
          <label>Indicador:</label>
          <Select
            showSearch
            disabled={this.state.disabledControlls || this.state.loading}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: '20%', marginTop: '50px', marginBottom: '15px' }}
            placeholder='Selecione um indicador'
            onChange={this.onSelectChange}
            value={this.state.indicadorId}
          >
            {this.options}
          </Select>
          <Button type='primary' disabled={this.state.disabledControlls || this.state.loading} onClick={this.onFilterClick}>Filtrar</Button>

        </div>

        <Table
          pagination={{ pageSize: 10, position: "top" }}
          title={() => (
            <Header
              ref={this.headerElement}
              updateList={this.updateList}
              totalPagar={this.state.totalPagar}
              nomeIndicador={this.state.nomeIndicador}
              disableCallback={this.disableControllsCallback}
              filterClick={this.onFilterClick}
              execute={this.baixarSelecionados}
            />
          )}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={this.state.indicacoes}
          loading={this.state.loading}
          bordered
        />
      </BluveLayout>
    );
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.novoIndicadorElement = React.createRef();

    this.state = {
      visible: false,
      loading: false,
    };

    this.pagarIndicador = this.pagarIndicador.bind(this);
  }

  async pagarIndicador() {
    // Tem algum caracter maluco no R$
    // Deixe com os dois replaces
    const valor = parseFloat(this.props.totalPagar.replace('R$ ', '').replace('R$ ', '').replace(',', '.'));
    if (valor <= 0) {
      message.warning('Selecione uma ou mais indicações para pagar');
      return;
    }

    const baixar = await confirmar(
      `Confirma pagar o valor de ${this.props.totalPagar} para ${this.props.nomeIndicador}?`
    );
    if (!baixar) {
      return;
    }

    this.setState({ loading: baixar });
    this.props.disableCallback(baixar);
    this.props.execute();
    setTimeout(() => {
      this.setState({ loading: false });
      this.props.filterClick();
      this.props.disableCallback(false);
      avisar(`Comissão para ${this.props.nomeIndicador} foi baixada com sucesso`);
    }, 3000);


  }

  render() {
    return (
      <div hidden={this.props.nomeIndicador === 'Todos'}>
        <Button type="danger" loading={this.state.loading} onClick={this.pagarIndicador}>
          <Icon className="iconePgto" type="audit" /> Pagar {this.props.nomeIndicador}
        </Button>
        <label className='lblTotalAPagarDescricao'>Total a Pagar:</label>
        <label className='lblTotalPagamentoIndicador'>{this.props.totalPagar}</label>
      </div>
    );
  }
}

export default withRouter(Pagamento);
