import { Component } from "react";
import { H_01_HORA, H_48_HORAS } from "../funcs/constants";


class BluveStorage extends Component {

  get(name) {
    const storage = localStorage.getItem(name);
    if (storage === 'undefined') {
      return undefined
    }
    return storage;
  }

  set(name, value) {
    localStorage.setItem(name, value);
  }

  clear(name) {
    localStorage.clear(name)
  }

  clearReportsDataEmpresaComparacao() {
    // Taxa Conversão
    this.set('@reports/taxaConversao/lineData', undefined);
    this.set('@reports/taxaConversao/barData', undefined);

    // Vendas Perdidas
    this.set('@reports/vendasPerdidas/loja', undefined);
    this.set('@reports/vendasPerdidas/comparacao', undefined);
  }

  clearAllReportsData() {
    // Taxa Conversão
    this.set('@reports/taxaConversao/diarioAtendimentoData', undefined)
    this.set('@reports/taxaConversao/lineData', undefined);
    this.set('@reports/taxaConversao/barData', undefined);

    // Taxa Conversão Individual
    this.set('@reports/taxaConversaoIndividual/lineData', undefined);

    // Vendas Perdidas
    this.set('@reports/vendasPerdidas/loja', undefined);
    this.set('@reports/vendasPerdidas/comparacao', undefined);

    // Produtos x Motivos
    this.set('@reports/produtosMotivos/conversoes', undefined);
    this.set('@reports/produtosMotivos/produtosRelatorio', undefined);
    this.set('@reports/produtosMotivos/chartData', undefined);

    // Fora do Mix
    this.set('@reports/foraMix/data', undefined);
    this.set('@reports/foraMix/columns', undefined);

    // Histórico de Atendimento
    this.set('@reports/historicoAtendimento/data', undefined);
    this.set('@reports/historicoAtendimento/columns', undefined);

    // Indicação por Loja
    this.set('@reports/indicacaoLoja/data', undefined);
    this.set('@reports/indicacaoLoja/columns', undefined);

    //Metas
    this.set('@reports/metas/cardsData', undefined);
    this.set('@reports/metas/tableData', undefined);
    this.set('@reports/metas/columns', undefined);

    //Evolução dos Vendedores
    this.set('@reports/evolucaoVendedores/chartData', undefined)
    this.set('@reports/evolucaoVendedores/vendedor', undefined)
    this.set('@reports/evolucaoVendedores/listVendedores', undefined)


    this.set('@reports/motivo-produto/lineData', undefined);
  }



  clearAll() {
    localStorage.clear();
  }

  getReportEmpresaId() {
    return this.get('@reports/empresaId');
  }

  getReportEmpresaComparacaoId() {
    return this.get('@reports/empresaComparacaoId');
  }

  getReportPeriodo() {
    let periodo = this.get('@reports/periodo');
    if (periodo) {
      periodo = JSON.parse(periodo)
    }
    return periodo;
  }

  getReportTaxaConversao() {
    let lineData = this.get('@reports/taxaConversao/lineData');
    if (lineData) {
      lineData = JSON.parse(lineData);
    }

    let barData = this.get('@reports/taxaConversao/barData');
    if (barData) {
      barData = JSON.parse(barData);
    }

    return {
      lineData: lineData,
      barData: barData,
    }
  }

  getReportTaxaConversaoIndividual() {
    let lineData = this.get('@reports/taxaConversaoIndividual/lineData');
    if (lineData) {
      lineData = JSON.parse(lineData);
    }

    return {
      lineData: lineData,
    }
  }

  getReportVendasPerdidas() {
    let loja = this.get('@reports/vendasPerdidas/loja');
    if (loja) {
      loja = JSON.parse(loja);
    }

    let comparacao = this.get('@reports/vendasPerdidas/comparacao');
    if (comparacao) {
      comparacao = JSON.parse(comparacao);
    }

    return {
      loja: loja,
      comparacao: comparacao,
    }
  }

  getReportProdutosMotivos() {
    let conversoes = this.get('@reports/produtosMotivos/conversoes');
    if (conversoes) {
      conversoes = JSON.parse(conversoes);
    }

    let produtosRelatorio = this.get('@reports/produtosMotivos/produtosRelatorio');
    if (produtosRelatorio) {
      produtosRelatorio = JSON.parse(produtosRelatorio);
    }

    let chartData = this.get('@reports/produtosMotivos/chartData');
    if (chartData) {
      chartData = JSON.parse(chartData);
    }

    return {
      conversoes: conversoes,
      produtosRelatorio: produtosRelatorio,
      chartData: chartData
    }
  }

  getReportForaMix() {
    let data = this.get('@reports/foraMix/data');
    if (data) {
      data = JSON.parse(data);
    }

    let columns = this.get('@reports/foraMix/columns');
    if (columns) {
      columns = JSON.parse(columns);
    }

    return {
      data: data,
      columns: columns
    }
  }

  getReportHistoricoAtendimento() {
    let data = this.get('@reports/historicoAtendimento/data');
    if (data) {
      data = JSON.parse(data);
    }

    let columns = this.get('@reports/historicoAtendimento/columns');
    if (columns) {
      columns = JSON.parse(columns);
    }

    return {
      data: data,
      columns: columns
    }
  }

  getReportIndicacaoLoja() {
    let data = this.get('@reports/indicacaoLoja/data');
    if (data) {
      data = JSON.parse(data);
    }

    let columns = this.get('@reports/indicacaoLoja/columns');
    if (columns) {
      columns = JSON.parse(columns);
    }

    return {
      data: data,
      columns: columns
    }
  }

  getReportMetas() {
    let cardsData = this.get('@reports/metas/cardsData');
    if (cardsData) {
      cardsData = JSON.parse(cardsData);
    }

    let tableData = this.get('@reports/metas/tableData');
    if (tableData) {
      tableData = JSON.parse(tableData);
    }

    let columns = this.get('@reports/metas/columns');
    if (columns) {
      columns = JSON.parse(columns);
    }

    return {
      cardsData: cardsData,
      tableData: tableData,
      columns: columns
    }
  }

  getReportEvolucaoVendedores() {
    let chartData = this.get('@reports/evolucaoVendedores/chartData');
    if (chartData) {
      chartData = JSON.parse(chartData);
    }

    let listVendedores = this.get('@reports/evolucaoVendedores/listVendedores');
    if (listVendedores) {
      listVendedores = JSON.parse(listVendedores);
    }

    let reportData = this.get('@reports/evolucaoVendedores/reportData');
    if (reportData) {
      reportData = JSON.parse(reportData);
    }

    let vendedor = this.get('@reports/evolucaoVendedores/vendedor');
    if (vendedor) {
      vendedor = JSON.parse(vendedor);
      return {
        chartData: chartData,
        vendedor: vendedor,
        listVendedores: listVendedores,
        reportData: reportData
      };
    }


    return {
      chartData: chartData,
      listVendedores: listVendedores
    };

  }


  /* REMOVER CODIGO

  getGestorProducts() {
      let produtos = this.get('produtos');
      if (produtos) {
          produtos = JSON.parse(produtos);
      }
      return produtos;
  }

  */

  getEmpresasGestor() {
    return this.getWithExpiry("@empresasGestor");
  }

  getClientes() {
    return this.getWithExpiry("@clientes");
  }


  /* REMOVER CODIGO
  setGestorProducts(data) {
      this.set('produtos', data ? JSON.stringify(data) : undefined);
  }*/

  setReportTaxaConversao(lineData, barData) {
    this.set('@reports/taxaConversao/lineData', lineData ? JSON.stringify(lineData) : undefined);
    this.set('@reports/taxaConversao/barData', barData ? JSON.stringify(barData) : undefined);
  }

  setReportTaxaConversaoIndividual(lineData) {
    this.set('@reports/taxaConversaoIndividual/lineData', lineData ? JSON.stringify(lineData) : undefined);
  }

  setReportVendasPerdidas(loja, comparacao) {
    this.set('@reports/vendasPerdidas/loja', loja ? JSON.stringify(loja) : undefined);
    this.set('@reports/vendasPerdidas/comparacao', comparacao ? JSON.stringify(comparacao) : undefined);
  }

  setReportProdutosMotivos(conversoes, produtosRelatorio, chartData) {
    this.set('@reports/produtosMotivos/conversoes', conversoes ? JSON.stringify(conversoes) : undefined);
    this.set('@reports/produtosMotivos/produtosRelatorio', produtosRelatorio ? JSON.stringify(produtosRelatorio) : undefined);
    this.set('@reports/produtosMotivos/chartData', chartData ? JSON.stringify(chartData) : undefined);
  }

  setReportForaMix(data, columns) {
    this.set('@reports/foraMix/data', data ? JSON.stringify(data) : undefined);
    this.set('@reports/foraMix/columns', columns ? JSON.stringify(columns) : undefined);
  }

  setReportHistoricoAtendimento(data, columns) {
    this.set('@reports/historicoAtendimento/data', data ? JSON.stringify(data) : undefined);
    this.set('@reports/historicoAtendimento/columns', columns ? JSON.stringify(columns) : undefined);
  }

  setReportIndicacaoLoja(data, columns) {
    this.set('@reports/indicacaoLoja/data', data ? JSON.stringify(data) : undefined);
    this.set('@reports/indicacaoLoja/columns', columns ? JSON.stringify(columns) : undefined);
  }

  setReportMetas(cardsData, tableData, columns) {
    this.set('@reports/metas/cardsData', cardsData ? JSON.stringify(cardsData) : undefined);
    this.set('@reports/metas/tableData', tableData ? JSON.stringify(tableData) : undefined);
    this.set('@reports/metas/columns', columns ? JSON.stringify(columns) : undefined);
  }

  setReportEmpresaId(empresaId) {
    this.set('@reports/empresaId', empresaId);
  }

  setReportEmpresaCompracaoId(empresaComparacaoId) {
    this.set('@reports/empresaComparacaoId', empresaComparacaoId);
  }

  setReportPeriodo(periodo) {
    this.set('@reports/periodo', JSON.stringify(periodo));
  }

  setReportEvolucaoVendedores(chartData, vendedor, listVendedores, reportData) {
    if (vendedor) {
      this.set('@reports/evolucaoVendedores/vendedor', JSON.stringify(vendedor));
    }
    this.set('@reports/evolucaoVendedores/chartData', JSON.stringify(chartData));
    this.set('@reports/evolucaoVendedores/listVendedores', JSON.stringify(listVendedores));
    this.set('@reports/evolucaoVendedores/reportData', JSON.stringify(reportData));
  }

  setEmpresasGestor(data) {
    this.setWithExpiry("@empresasGestor", data, H_48_HORAS)
  }

  setVendedoresGestor(data) {
    this.setWithExpiry("@vendedoresGestor", data, H_48_HORAS)
  }

  setClientes(data) {
    this.setWithExpiry("@clientes", data, H_48_HORAS);
  }

  setWithExpiry(key, value, ttl) {
    if (!ttl) {
      ttl = H_01_HORA;
    }
    const now = new Date();
    const item = {
      value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item));
  }

  getWithExpiry(key) {
    const itemStr = this.get(key);
    if (!itemStr || itemStr === 'null') {
      return undefined;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return undefined;
    }
    return item.value;
  }
}

const bluveStorage = new BluveStorage();
export default bluveStorage;
