import React, { useState, useEffect } from "react";
import { Avatar, Table } from "antd";
import { useFormikContext } from "formik";
import { InputNumber, FormItem } from "formik-antd";

import CurrencyInput from 'react-currency-input';
import Text from "antd/lib/typography/Text";


const EscalaStep = () => {
  const { values, setFieldValue } = useFormikContext();
  const [valorInconsistente, setValorInconsistente] = useState('');

  console.clear();
  console.log('Vendedores: ');
  console.log(values.vendedores);

  const handleMetaTotalChange = (newValue, index) => {
    const numericValue = parseFloat(newValue.replace(/[^\d,]/g, '').replace(',', '.'));
    const vendedoresCopy = [...values.vendedores];
    const valorTotalOriginal = vendedoresCopy.reduce((soma, item) => soma + item.metaTotal, 0);
    const vendedoresAtualizados = vendedoresCopy.map((item, vendedorIndex) => {
      if (vendedorIndex === index) {

        // values.vendedores tem um array chamado "dias".
        // Ele pode ter 4 ou 5 posições, dependendo do número de períodos.
        // Cada valor nessa posição corresponde aos dias que o vendedor
        // trabalhará no período.
        // Portanto, se eu tiver, por exemplo esse valor: [3, 0, 0, 0], 
        // isso significa que o vendedor trabalhará pelo menos um dia no primeiro período.
        // Logo, o cálculo abaixo não pode ser feito com base no número de períodos, 
        // mas sim com base na soma dos dias de trabalho do vendedor.

        // Portanto, primeiro descubro quantos períodos o vendedor trabalhará.
        const periodoTrabalho = values.vendedores[vendedorIndex].dias.reduce((semanas, dias) => {
          if (dias > 0) {
            return semanas + 1;
          }
          return semanas;
        }, 0);               


        let metasPeriodos = [];
        let valorMeta = 0;

        debugger;

        if (periodoTrabalho === 1) {
          metasPeriodos = new Array(periodoTrabalho).fill(numericValue / periodoTrabalho);
          const diferenca = values.periodos.length - metasPeriodos.length;
          for (let i = 0; i < diferenca; i++) {
            metasPeriodos.push(0);
          }
        } else {
          metasPeriodos = new Array(values.periodos.length).fill(0);

          for (let i = 0; i < metasPeriodos.length; i++) {
            const peso = values.periodos[i].peso;
            const metaAjustada = numericValue * (peso / 100);
            metasPeriodos[i] = metaAjustada;
          }
        }



        // // Se o length de metasPeriodos for menor que o length de values.periodos,
        // // então preciso preencher o restante com zero.
        // if (metasPeriodos.length < values.periodos.length) {
        //   const diferenca = values.periodos.length - metasPeriodos.length;
        //   for (let i = 0; i < diferenca; i++) {
        //     metasPeriodos.push(0);
        //   }
        // }

        // // Após atribuir os valores pelos períodos, preciso validar os períodos pelos pesos,
        // // O que poderá acarretar em um novo recálculo.

        // debugger;
        // for (let i = 0; i < metasPeriodos.length; i++) {
        //   let valorMeta = metasPeriodos[i];
        //   if (valorMeta === numericValue) {
        //     continue;
        //   }

        //   // valorMeta = values.periodos[i].meta;
        //   valorMeta = numericValue;
        //   const peso = values.periodos[i].peso;

        //   // Divido o valor total da meta "numéricValue" pelo percentual do peso.
        //   // Isso me dará a meta ajustada para esse período.

        //   const metaAjustada = valorMeta * (peso / 100);
        //   metasPeriodos[i] = metaAjustada;
        // }


        
        
        return {
          ...item,
          metaTotal: numericValue,
          metasPeriodos,
        };
      } else {
        const metaAjustada = (valorTotalOriginal - numericValue) / (values.vendedores.length - 1);
        const metasPeriodos = item.metasPeriodos;
        const totalPeriodos = item.metasPeriodos.reduce((soma, item) => soma + item, 0);
        for (let i = 0; i < metasPeriodos.length; i++) {
          const percentualOriginal = (item.metasPeriodos[i] / totalPeriodos) * 100;
          const valorAjustado = (percentualOriginal * metaAjustada) / 100;
          //item.metasPeriodos[i] = valorAjustado;
        }

        return {
          ...item,
          //metaTotal: metaAjustada,
          metasPeriodos,
        };
      }
    });

    setFieldValue("vendedores", vendedoresAtualizados);
  };

  const handleVendedorDias = (value, vendedorIndex, periodo) => {
    const vendedores = values.vendedores;
    vendedores[vendedorIndex].dias[periodo] = value;

    const periodos = values.periodos.map((item, index) => {
      return {
        ...item,
        // Calcula o valor da meta diária dos vendendores de acordo com o período,
        // dividindo a meta do período pela soma de dias disponíveis (vendedores x dias)
        metaVendedorDia: item.meta > 0 ? item.meta / vendedores.reduce((soma, item) => soma + item.dias[index], 0) : 0
      };
    });

    setFieldValue("periodos", periodos);
  };

  useEffect(() => {
    const vendedores = values.vendedores.map((item, index) => {
      return {
        ...item,
        metasPeriodos: item.dias.map((item, index) => item * values.periodos[index].metaVendedorDia),
        metaTotal: item.dias.map((item, index) => item * values.periodos[index].metaVendedorDia).reduce((soma, valor) => soma + valor, 0)
      };
    });

    setFieldValue("vendedores", vendedores);
  }, [values.periodos]);

  const columns = [
    {
      dataIndex: 'avatar',
      key: 'avatar',
      render: (src) => <Avatar src={src} />,
    },
    {
      key: 'nome',
      render: (text, row) => (
        <>
          <div>
            <Text>{row.nome}</Text>
          </div>
          <div>
            <Text type="secondary">{row.apelido}</Text>
          </div>
        </>
      ),
    },
    ...values.periodos.map((item, index) => {
      return {
        title: `Período ${index + 1}`,
        dataIndex: `dias[${index}]`,
        render: (text, row, indexVendedor) => {
          return (
            <InputNumber
              className="periodo-dias"
              key={index}
              name={`vendedores.${indexVendedor}.dias.${index}`}
              min={0}
              max={values.periodos[index].dias}
              onChange={(value) => handleVendedorDias(value, indexVendedor, index)}>
            </InputNumber>
          );
        }
      }
    }),

    {
      title: "Meta Original",
      dataIndex: "metaTotal",
      render: (value, record, index) => (
        <CurrencyInput
          className="wrapValorMeta"
          prefix="R$ "
          decimalSeparator=","
          thousandSeparator="."
          value={value}
          onChange={(newValue) => handleMetaTotalChange(newValue, index)}
        />
      ),
    },];

  return (
    <FormItem name="escala">
      <Table
        columns={columns}
        dataSource={values.vendedores}
        rowKey="id"
        pagination={false}
      />
      <label>{valorInconsistente}</label>
    </FormItem>
  );
};

export default EscalaStep;
