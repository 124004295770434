import React from "react";
import BluveLayout from "../../../components/layout/BluveLayout";
import { withRouter } from "react-router";
import { Select, Button, message, Card, Switch } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import {
  getEmpresas,
  buildReport,
  getMotivos,
  exportToExcel,
} from "./MotivoProdutoF.jsx";
import { getUserType, getGestorId } from "../../../components/auth/auth-provider";
import "chartjs-plugin-datalabels";
import "chart.piecelabel.js";
import "antd/dist/antd.css";
import "./MotivoProduto.css";
import { GERENTE } from "../../../components/funcs/constants";
import bluveStorage from "../../../components/localstorage/localstorage";
import { Tableau20 } from "chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau";
import { Bar } from "react-chartjs-2";
import locale from 'antd/es/date-picker/locale/pt_BR';


const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
const { RangePicker } = DatePicker;

const backgroundColorsSchema = [
  "rgb(153, 102, 255)",
  "rgb(255, 159, 64)",
  "rgb(0, 163, 51)",
  "rgb(244, 180, 0)",
  "rgb(54, 162, 235)",
  "rgb(240, 53, 53)",
  "rgb(201, 203, 207)",
  "rgb(0,0,255)",
  "rgb(255, 0, 255)",
  "rgb(128,128,0)",
  "rgb(0,139,139)",
];

class MotivoProduto extends React.Component {
  state = {
    empresas: [],
    empresaId: '',
    motivos: [],
    motivoId: '',
    dataSelecionada: moment(),

    chartData: {},

    lineChart: {},
    barChart: {},
    loading: false,
    periodo: {},
    alterouConfiguracaoRelatorio: false,
    reportData: [],
    columns: [
      {
        title: 'Produto',
        dataIndex: 'nome',
        key: 'nome',
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        align: 'right'
      },
    ],
    exibirSku: false,
  };

  options = [];
  motivosOptions = [];

  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onSelectMotivoChange = this.onSelectMotivoChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.onChangeExibirSku = this.onChangeExibirSku.bind(this);
  }

  async componentDidMount() {

    const exibirSku = bluveStorage.get('exibirSku') !== 'false';
    this.setState({ exibirSku, loading: true });


    const gestorId = await getGestorId();

    const storage = bluveStorage.getEmpresasGestor('@gestorEmpresas');
    let empresas = [];

    if (storage && storage.gestorId === gestorId) {
      empresas = storage.empresas;
    } else {
      empresas = await getEmpresas();

      const data = {
        gestorId: gestorId,
        empresas: empresas
      };

      bluveStorage.setEmpresasGestor(data);
    }

    const motivos = await getMotivos();

    if (motivos) for (const motivo of motivos) {
      this.motivosOptions.push(
        <Option key={motivo.key} value={motivo.key}>
          {motivo.nome}
        </Option>
      );
    }

    for (const item of empresas) {
      this.options.push(
        <Option key={item.key} value={item.key}>
          {item.nomeFantasia}
        </Option>
      );
    }

    if (getUserType() !== GERENTE) {
      this.options.push(
        <Option key={"rede"} value={"rede"}>
          {"Rede de Lojas"}
        </Option>
      );
    }
    else {
      const texto = empresas.length > 1 ? 'Minhas lojas' : 'Você mesmo';
      this.options.push(
        <Option key={"rede"} value={"rede"}>
          {texto}
        </Option>
      );
    }

    if (!empresas.length) {
      empresas.push({
        id: "Nenhuma empresa cadastrada",
        razaoSocial: "Nenhuma empresa cadastrada",
      });
    }

    let empresaId = bluveStorage.getReportEmpresaId();
    //get('@reports/motivo-produto/empresaId');
    if (empresaId && empresaId !== 'rede') {
      empresaId = bluveStorage.getReportEmpresaId();
    } else if (!empresaId) {
      empresaId = empresas[0].key
    }

    let motivoId = bluveStorage.get('@report/motivo-produto/motivoId');
    if (!motivoId && motivos) {
      motivoId = motivos[0].key;
    }


    let periodo = bluveStorage.getReportPeriodo();
    if (!periodo) {
      periodo = {
        inicial: moment().subtract(7, "days"),
        final: moment(),
      };
    }
    else {
      periodo.inicial = moment(periodo.inicial);
      periodo.final = moment(periodo.final);
    }

    let lineData = bluveStorage.get('@reports/motivo-produto/lineData');
    if (lineData) {
      lineData = JSON.parse(lineData);
      if (lineData) {
        this.mountChart(lineData, false);
      }
    }

    this.setState({
      empresaId,
      motivoId,
      empresas: empresas,
      periodo,
      motivos,
      loading: false,
    });
  }

  onChangeExibirSku(checked) {
    this.setState({ exibirSku: checked });
    bluveStorage.set('exibirSku', checked);
  }


  onExportClick() {
    const empresaFiltrada = this.state.empresas.filter(item => item.key === this.state.empresaId)[0];
    const motivoFiltrado = this.state.motivos.filter(item => item.key === this.state.motivoId)[0];
    exportToExcel(
      this.state.reportData,
      empresaFiltrada.nomeFantasia ? empresaFiltrada.nomeFantasia : empresaFiltrada.razaoSocial,
      this.state.periodo,
      motivoFiltrado.nome
    );
  }

  onSelectChange(value) {
    this.setState({ empresaId: value, alterouConfiguracaoRelatorio: true });
  }

  onSelectMotivoChange(value) {
    this.setState({ motivoId: value });
  }

  onDateChange(value) {
    const periodo = {
      inicial: value[0],
      final: value[1]
    }
    this.setState({ periodo, alterouConfiguracaoRelatorio: true });
  }

  async onFilterClick() {
    if (!this.state.periodo.inicial || !this.state.periodo.final) {
      message.warning("Selecione o período para gerar o gráfico", 2);
      return;
    }

    if (!this.state.empresaId) {
      message.warning('Nenhuma empresa selecionada.');
      return;
    }

    this.setState({ loading: true });

    const setEmpresas = this.state.empresaId !== 'rede' ? [{
      key: this.state.empresaId
    }] : this.state.empresas



    if (this.state.empresaId !== 'rede') {
      bluveStorage.setReportEmpresaId(this.state.empresaId);
    }

    if (this.state.alterouConfiguracaoRelatorio) {
      bluveStorage.clearAllReportsData();
    }
    bluveStorage.setReportEmpresaId(this.state.empresaId);
    bluveStorage.setReportPeriodo(this.state.periodo);
    setTimeout(async () => {

      const props = {
        dataInicial: this.state.periodo.inicial,
        dataFinal: this.state.periodo.final,
        motivoId: this.state.motivoId,
        empresas: setEmpresas,
        exibirSku: this.state.exibirSku
      }

      const lineData = await buildReport(props);

      bluveStorage.set('@reports/motivo-produto/empresaId', this.state.empresaId);
      bluveStorage.set('@reports/motivo-produto/lineData', JSON.stringify(lineData));
      bluveStorage.set('@report/motivo-produto/motivoId', this.state.motivoId);
      this.mountChart(lineData);
    }, 500);
  }

  mountChart(data, alertar) {
    if ((!data || data.length === 0) && alertar) {
      message.info("Sem movimentos nesse período");

      this.setState({
        loading: false,
        reportData: [],
        chartData: {},
      });
      return;
    }

    if (data === undefined) {
      data = [];
    }

    let produtosLabel = data.map((item) => item.nome);
    let dataTotal = data.map((item) => item.total);

    const dtsLineChart = [];
    dtsLineChart.push({
      fill: false,
      lineTension: 0.1,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      backgroundColor: backgroundColorsSchema,
      data: dataTotal,
    });

    const chart = {
      labels: produtosLabel,
      datasets: dtsLineChart,
    };

    this.setState({
      loading: false,
      reportData: data,
      chartData: chart,
    });
  }

  render() {
    return (
      <div>
        <BluveLayout selectItem={"relatorio/motivo-produto"}>
          <div className="containerExtratoAtendimentos">
            <div className="child">
              <label>Empresa:</label>
              <br></br>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "200px" }}
                placeholder="Selecione uma empresa"
                onChange={this.onSelectChange}
                value={this.state.empresaId}
              >
                {this.options}
              </Select>
            </div>
            <div className="child">
              <label>Período:</label>
              <br></br>
              <div className="rangePickerDuplo">
                <RangePicker
                  locale={locale}
                  format={dateFormat}
                  placeholder={["Data Inicial", "Data Final"]}
                  onChange={this.onDateChange}
                  value={[
                    this.state.periodo ? this.state.periodo.inicial : '',
                    this.state.periodo ? this.state.periodo.final : '',
                  ]}
                />
              </div>
            </div>

            <div className="child">

              <label>Motivo:</label>
              <br></br>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "200px" }}
                placeholder="Selecione um motivo"
                onChange={this.onSelectMotivoChange}
                value={this.state.motivoId}
              >
                {this.motivosOptions}
              </Select>
            </div>

            <div className="child">


              <Button
                type="primary"
                loading={this.state.loading}
                onClick={this.onFilterClick}
              >
                Filtrar
              </Button>
              <br></br>
              <br></br>
              <Button
                className='excel-button-v2'
                disabled={this.state.loading || this.state.reportData.length === 0}
                onClick={this.onExportClick}
                style={{ marginLeft: '1rem' }}
              >
                Exportar para Excel
              </Button>

              <br></br>
              <Switch
                checkedChildren="Sku do produto"
                unCheckedChildren="Nome do produto"
                checked={this.state.exibirSku}
                onChange={this.onChangeExibirSku}
              />

            </div>
          </div>

          <div>
            <Card>
              <Bar
                data={this.state.chartData}
                width={100}
                height={30}
                options={{

                  legend: {
                    display: false,
                  },

                  maintainAspectRatio: true,
                  showLines: false,
                  scales: {
                    xAxes: [
                      {
                        // display: false,
                        stacked: true,
                        gridLines: {
                          // display: false
                        },
                        ticks: {
                          min: 0,
                          stepSize: 10,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        stacked: true,
                        gridLines: {
                          display: false,
                        },
                        ticks: {
                          // Limitar a quantidade de caracteres exibidos
                          // nos labels do gráfico (ao lado esquerdo)
                          callback: function (value, index, values) {
                            const LIMITE = 40;

                            if (value.length >= LIMITE) {
                              return value.substring(0, LIMITE) + "...";
                            }

                            return value;
                          },
                        },
                      },
                    ],
                  },
                  plugins: {
                    colorschemes: {
                      scheme: Tableau20,
                      fillAlpha: 0.2,
                    },
                  },
                  title: {
                    display: true,
                    text: "10 produtos com mais perdas",
                  },
                }}
              />
            </Card>
          </div>

          {/* <div className="divTableGrafico">
            <Table
              pagination={{
                defaultPageSize: 100,
                position: 'both',
                pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
                showSizeChanger: true,
                locale: { items_per_page: "" }
              }}
              columns={this.state.columns}
              rowClassName={(record) => record.color.replace('#', '')}
              dataSource={this.state.reportData}
              bordered
            />
          </div> */}
        </BluveLayout>
      </div>
    );
  }
}

export default withRouter(MotivoProduto);
