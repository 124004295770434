import React from "react";
import "antd/dist/antd.css";
import "./LojaCRUD.css";
import {
  Form,
  Tooltip,
  Icon,
  Button,
  Divider,
  Input as InputAnt,
  Select,
  Tabs,
} from "antd";
import { fbDatabase, fbFirestore } from "../../../../firebase/firebase";
import {
  getGestorId,
  getUserType,
  getGerenteId,
} from "../../../auth/auth-provider";
import { isCNPJ, formatCNPJ } from "../../../funcs/utils";
import Input from "../../../../components/intl/IntlCurrencyInput";
import { GESTOR } from "../../../funcs/constants";
import gerenteDB from "../../../../dataManager/dtmGerente";
import lojaDB from "../../../../dataManager/dtmLoja";
import mensagem from "components/message/Message";

const { TabPane } = Tabs;

class LojaCRUD extends React.Component {
  state = {
    activeKey: "geral",
    confirmDirty: false,
    autoCompleteResult: [],
    gerentes: [],
    showSelectGerentes: "block",
    data: {
      id: this.props.record.id,
      gerente: {
        id: "",
        nome: "",
      },
      razaoSocial: "",
      nomeFantasia: "",
      cnpj: "",
      email: "",
      endereco: {
        logradouro: "",
        bairro: "",
        cidade: "",
        uf: "",
        telefone: "",
      },
      comissao: {
        alterouValorFixo: false,
        fixo: 0,
        percentual: 0,
      },
    },
  };

  constructor(props) {
    super(props);
    this.cancelClick = this.cancelClick.bind(this);
    this.getGerentes = this.getGerentes.bind(this);
    this.selectGerenteHandleChange = this.selectGerenteHandleChange.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.changeLoadingStatus = this.changeLoadingStatus.bind(this);
  }

  tabChange(activeKey) {
    this.setState({ activeKey });
  }

  changeLoadingStatus(loading) {
    this.setState({ loading });
    if (this.props.onCloseHandle) {
      this.props.onCloseHandle(!loading);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {

        const _this = this;
        mensagem.printFormErrors(err, 3, function (requireds) {
          let activeKey = "";
          if (
            requireds.indexOf("gerente") >= 0 ||
            requireds.indexOf("razaoSocial") >= 0 ||
            requireds.indexOf("nomeFantasia") >= 0) {
            activeKey = "geral";
          } else {
            activeKey = "financeiro";
          }
          _this.setState({ activeKey });
        });
        return;
      }

      this.changeLoadingStatus(true);

      if (values.cnpj && values.cnpj !== "") {
        if (!isCNPJ(values.cnpj)) {
          mensagem.openNotificationWithIcon(
            "error",
            "CNPJ inválido",
            "O CNPJ informado não está correto."
          );
          this.changeLoadingStatus(false);
          return;
        }
        values.cnpj = formatCNPJ(values.cnpj);
      }

      // procuro pelo CNPJ para checar se está repetido
      let snapshot = await lojaDB.getByCNPJ(values.cnpj);
      // Verifico se está havendo uma duplicação de CNPJ
      if (snapshot !== undefined) {
        if (
          (this.props.editMode && snapshot[0].key !== this.props.record.key) ||
          (this.props.editMode && snapshot.length > 1) ||
          !this.props.editMode
        ) {
          if (values.cnpj && values.cnpj !== "") {
            mensagem.openNotificationWithIcon(
              "error",
              "Duplicação",
              "O CNPJ informado já foi cadastrado."
            );
            this.changeLoadingStatus(false);
            return;
          }
        }
      }

      // procuro pelo Email para checar se está repetido
      if (values.email) {
        snapshot = await lojaDB.getByEmail(values.email);

        if (snapshot !== undefined) {
          if (
            (this.props.editMode &&
              snapshot[0].key !== this.props.record.key) ||
            (this.props.editMode && snapshot.length > 1) ||
            !this.props.editMode
          ) {
            mensagem.openNotificationWithIcon(
              "error",
              "Duplicação",
              "O email informado já foi cadastrado."
            );
            this.changeLoadingStatus(false);
            return;
          }
        }
      }

      let valorFixo = this.state.data.comissao.fixo;
      if (!this.state.data.comissao.alterouValorFixo) {
        valorFixo = this.state.data.comissao.fixo / 100;
      }

      const item = {
        razaoSocial: values.razaoSocial,
        nomeFantasia: values.nomeFantasia,
        cnpj: values.cnpj,
        email: values.email,
        endereco: {
          logradouro: values.endereco,
          bairro: values.bairro,
          cidade: values.cidade,
          uf: values.uf,
          telefone: values.telefone,
        },
        gestorId: getGestorId(),
        gerente: this.state.data.gerente,
        comissao: {
          fixo: valorFixo,
          percentual: this.state.data.comissao.percentual ? this.state.data.comissao.percentual : 0,
        },
      };

      let novoId = "";
      if (!this.props.editMode) {
        const res = await lojaDB.add(item);
        if (res.recorded === true) {
          novoId = res.id;
        }
      } else {
        lojaDB.update(this.props.record.key, item);
      }

      const tipoAtualizacao = this.props.editMode ? "atualizada" : "cadastrada";
      mensagem.openNotificationWithIcon(
        "success",
        "Perfeito!",
        `A loja ${values.razaoSocial} foi ${tipoAtualizacao} com sucesso`,
        3
      );
      item["key"] = novoId ? novoId : this.props.record.key;
      this.props.handleOk(item);
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  async componentDidMount() {
    if (getUserType() === GESTOR) {
      const gerentes = await this.getGerentes();
      this.setState({ gerentes });
    } else {
      const gerenteId = getGerenteId();
      const gerente = await gerenteDB.getById(gerenteId);
      const data = this.state.data;
      data.gerente = {
        id: gerente.key,
        nome: gerente.nome,
      };
      this.setState({ data, showSelectGerentes: "none" });
    }

    if (this.props.editMode && this.props.record) {
      this.setState({
        data: {
          id: this.props.record.id,
          gerente: this.props.record.gerente,
          razaoSocial: this.props.record.razaoSocial,
          nomeFantasia: this.props.record.nomeFantasia,
          cnpj: this.props.record.cnpj,
          email: this.props.record.email,
          endereco: this.props.record.endereco,
          comissao: {
            fixo: this.props.record.comissao.fixo * 100,
            percentual: this.props.record.comissao.percentual,
          },
        },
      });
    }
  }

  async getGerentes() {
    const gestorId = getGestorId();
    const snapshot = await fbDatabase
      .collection("gerentes")
      .orderBy("nome")
      .orderBy("sobrenome")
      .where("gestorId", "==", gestorId)
      .get();

    /** X-TUDO */
    let xRef = fbDatabase
      .collection("x-tudo")
      .doc("Bluve-LojaCRUDJSX-LojaCRUD-getGerentes");
    xRef
      .set(
        {
          collection: "gerentes",
          lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
          count: fbFirestore.FieldValue.increment(snapshot.size),
          lastIncrement: snapshot.size,
        },
        { merge: true }
      )
      .catch((error) => console.error(error));

    if (snapshot.empty) {
      return undefined;
    }

    const data = this.state.data;
    data.gerente = {
      id: snapshot.docs[0].id,
      nome: snapshot.docs[0].data().nome,
    };
    this.setState({ data });
    const gerentes = snapshot.docs.map((item) => ({
      id: item.id,
      ...item.data(),
    }));

    const lista = [];
    const { Option } = Select;
    gerentes.forEach((item) => {
      lista.push(<Option key={item.id}>{item.nome}</Option>);
    });
    return lista;
  }

  cancelClick() {
    this.props.handleCancel();
  }

  selectGerenteHandleChange(value, obj) {
    const data = this.state.data;
    data.gerente = {
      id: value,
      nome: obj.props.children,
    };
    this.setState({ data });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const currencyConfig = {
      locale: "pt-BR",
      formats: {
        number: {
          "pt-BR": {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
      },
    };

    const comissaoFixaChange = (event, value) => {
      event.preventDefault();
      const data = this.state.data;
      data.comissao.fixo = parseFloat(value);
      data.comissao.alterouValorFixo = true;
      this.setState({ data });
    };

    const comissaoPercChange = (event) => {
      event.preventDefault();
      const data = this.state.data;
      data.comissao.percentual = parseFloat(event.target.value);
      this.setState({ data });
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Tabs activeKey={this.state.activeKey} onChange={this.tabChange}>
          <TabPane tab="Geral" key="geral">
            <Form.Item
              ref="selectGerente"
              style={{ display: this.state.showSelectGerentes }}
              label={
                <span>
                  Gerente&nbsp;
                  <Tooltip title="Escolha o gerente">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("gerente", {
                initialValue: this.state.data.gerente
                  ? this.state.data.gerente.id
                  : "",
                rules: [{ required: true, message: "selecione o Gerente" }],
              })(
                <Select
                  mode="single"
                  style={{ width: "100%" }}
                  placeholder="Selecione um gerente"
                  onChange={this.selectGerenteHandleChange}
                >
                  {this.state.gerentes}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  CNPJ&nbsp;
                  <Tooltip title="Qual é o CNPJ?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("cnpj", {
                initialValue: this.state.data.cnpj,
                rules: [
                  {
                    required: false,
                    message: "Informe o CNJP",
                    whitespace: false,
                  },
                ],
              })(<InputAnt />)}
            </Form.Item>

            <Form.Item
              ref="txtRazaoSocial"
              label={
                <span>
                  Razão Social&nbsp;
                  <Tooltip title="Qual é a Razão Social?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("razaoSocial", {
                initialValue: this.state.data.razaoSocial,
                rules: [
                  {
                    required: true,
                    message: "Informe a Razão Social",
                    whitespace: true,
                  },
                ],
              })(<InputAnt />)}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Nome Fantasia&nbsp;
                  <Tooltip title="Qual é o Nome Fantasia?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("nomeFantasia", {
                initialValue: this.state.data.nomeFantasia,
                rules: [
                  {
                    required: true,
                    message: "Informe o Nome Fantasia",
                    whitespace: true,
                  },
                ],
              })(<InputAnt />)}
            </Form.Item>
          </TabPane>

          <TabPane tab="Endereço" key="endereco">
            <Form.Item label="E-mail" ref="txtEmail">
              {getFieldDecorator("email", {
                initialValue: this.state.data.email,
                rules: [
                  {
                    type: "email",
                    message: "Email não é válido",
                  },
                  {
                    required: false,
                    message: "Por favor, informe o email",
                  },
                ],
              })(<InputAnt />)}
            </Form.Item>

            <Form.Item label="Telefone">
              {getFieldDecorator("telefone", {
                initialValue: this.state.data.endereco.telefone,
                rules: [{ required: false, message: "Informe o telefone" }],
              })(<InputAnt style={{ width: "100%" }} />)}
            </Form.Item>

            <Form.Item label="Endereço">
              {getFieldDecorator("endereco", {
                initialValue: this.state.data.endereco.logradouro,
                rules: [{ required: false, message: "Informe o endereço" }],
              })(<InputAnt style={{ width: "100%" }} />)}
            </Form.Item>

            <Form.Item label="Bairro">
              {getFieldDecorator("bairro", {
                initialValue: this.state.data.endereco.bairro,
                rules: [{ required: false, message: "Informe o bairro" }],
              })(<InputAnt style={{ width: "50%" }} />)}
            </Form.Item>

            <Form.Item label="Cidade">
              {getFieldDecorator("cidade", {
                initialValue: this.state.data.endereco.cidade,
                rules: [{ required: false, message: "Informe a cidade" }],
              })(<InputAnt style={{ width: "70%" }} />)}
            </Form.Item>

            <Form.Item label="UF">
              {getFieldDecorator("uf", {
                initialValue: this.state.data.endereco.uf,
                rules: [{ required: false, message: "Informe a UF" }],
              })(<InputAnt style={{ width: "20%" }} />)}
            </Form.Item>
          </TabPane>


          <TabPane tab="Financeiro" key="financeiro">
            <Form.Item label="Comissão Fixa" ref="txtComissaoFixa">
              {getFieldDecorator("comissaoFixa", {
                initialValue: this.state.data.comissao.fixo,
                rules: [
                  {
                    required: false,
                    message: "Por favor, informe o valor fixo de comissão",
                  },
                ],
              })(
                <Input
                  className="wrapInputComissao"
                  currency={currencyConfig.locale}
                  config={currencyConfig}
                  onChange={comissaoFixaChange}
                />
              )}
            </Form.Item>

            <Form.Item label="Comissão em %" ref="txtComissaoPercentual">
              {getFieldDecorator("comissaoPerc", {
                initialValue: this.state.data.comissao.percentual,
                rules: [
                  {
                    required: false,
                    message: "Por favor, informe o percentual de comissão",
                  },
                ],
              })(
                <InputAnt
                  type="number"
                  style={{ width: "20%" }}
                  onChange={comissaoPercChange}
                />
              )}
            </Form.Item>

            <Form.Item label="Nota" ref="txtNota">
              {
                <p className="paragrafoNotaComissao">
                  Para fins de comissionamento do indicador, valerá sempre o
                  maior valor (em percentual ou em valor fixo)
                </p>
              }
            </Form.Item>
          </TabPane>

        </Tabs>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={this.state.loading}>
            Salvar
          </Button>
          <Divider type="vertical" />
          <Button onClick={this.cancelClick} disabled={this.state.loading}>
            Cancelar
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(LojaCRUD);
