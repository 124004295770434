import { fbDatabase, fbFirestore } from '../firebase/firebase';
import DataManager from './dtm';

async function queryBy10(ids, inicio, fim) {
    let snapshot = await fbDatabase.collection('ranking')
    .where("data", ">=", inicio)
    .where("data", "<=", fim)
    .where("empresaId", "in", ids)
    .get();

    if (snapshot.empty) {
        return [];
    }

    const data = snapshot.docs.map((item) => ({
        key: item.id,
        ...item.data(),
    }));

    return data;
}


class RankingDB extends DataManager {
    state = {
        collection: 'ranking',
        orderBy: [], // usado na classe pai
    }


    async getByEmpresaIdAndPeriodo(empresaId, dataIni, dataFim) {
        const snapshot = await fbDatabase.collection(this.state.collection)
            .where('empresaId', '==', empresaId)
            .where('data', '>=', dataIni)
            .where('data', '<=', dataFim)
            .get();

        /** X-TUDO */
        let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmRanking-RankingDB-getByEmpresaIdAndPeriodo');
        xRef.set({
            collection: 'ranking',
            lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
            count: fbFirestore.FieldValue.increment(snapshot.size),
            lastIncrement: snapshot.size
        }, { merge: true }).catch(error => console.error(error));

        return snapshot !== undefined ?
            snapshot.docs.map((doc) => doc.data())
            : undefined;
    }

    async getByVendedorIdAndPeriodo(vendedorId, dataIni, dataFim) {
        const snapshot = await fbDatabase.collection(this.state.collection)
            .where('vendedorId', '==', vendedorId)
            .where('data', '>=', dataIni)
            .where('data', '<=', dataFim)
            .get();

        /** X-TUDO */
        let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmRankingJSX-RankingDB-getByVendedorIdAndPeriodo');
        xRef.set({
            collection: 'ranking',
            lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
            count: fbFirestore.FieldValue.increment(snapshot.size),
            lastIncrement: snapshot.size
        }, { merge: true }).catch(error => console.error(error));

        return snapshot !== undefined ?
            snapshot.docs.map((doc) => doc.data())
            : undefined;
    }

    async getByPeriodo(empresasIds, inicio, fim) {

        let movimentos = [];
        let ids = [];

        for (let index = 0; index < empresasIds.length; index++) {
            ids.push(empresasIds[index]);
            if (ids.length >= 10) {
                const data = await queryBy10(ids, inicio, fim);
                movimentos.push.apply(movimentos, data);
                ids = [];
            }
        }

        if (ids.length > 0) {
            const data = await queryBy10(ids, inicio, fim);
            movimentos.push.apply(movimentos, data);
        }


        if (movimentos.length === 0) {
            return undefined;
        }


        const rankingInfo = movimentos;

        let vendedores = rankingInfo.reduce((agregador, itemAtual) => {
            const vendedor = agregador.find(findItem => findItem.id == itemAtual.vendedorId);
            const { quantidade: atendimentos, conversoes: convertidos, pecas } = itemAtual.atendimentos;
            const encerramentosAutomaticos = itemAtual.atendimentos.encerramentosAutomaticos || 0;
            const encerramentosManuais = atendimentos - encerramentosAutomaticos;

            if (vendedor) {
                vendedor.atendimentos += atendimentos ? atendimentos : 0;
                vendedor.convertidos += convertidos ? convertidos : 0;
                vendedor.pecas += pecas ? pecas : 0;
                vendedor.encerramentosManuais += encerramentosManuais ? encerramentosManuais : 0;
            } else {
                agregador.push({
                    id: itemAtual.vendedorId,
                    nome: itemAtual.nome,
                    empresaId: itemAtual.empresaId,
                    atendimentos,
                    convertidos,
                    pecas,
                    encerramentosManuais
                });
            }

            return agregador;
        }, []);


        // Ordeno pelo nome do vendedor
        vendedores.sort((a, b) => (a.nome > b.nome ? 1 : -1));

        return vendedores;
    }
}

const rankingDB = new RankingDB();
export default rankingDB;
