import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button } from 'antd';


class SelecionarTipoConta extends Component {
  state = {
    tipos: [],
    tiposSelect: [],
    tipoSelecionado: undefined,
  }

  constructor(props) {
    super(props);

    this.onClickButton = this.props.onClickButton;

    this.handleConfirm = this.handleConfirm.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  async componentDidMount() {
    const tipos = [
      {
        tipo: 'Gestor',
        selected: false
      },
      {
        tipo: 'Gerente',
        selected: false
      }
    ];

    this.setState({ tipos });
  }

  async handleConfirm() {
    return new Promise(async (resolve, reject) => {
      setTimeout(() => {
        const { tipoSelecionado } = this.state;

        if (!tipoSelecionado) {
          reject({
            type: 'error',
            title: 'Nenhum Tipo Selecionado',
            description: 'Selecione uma das opções apresentadas.'
          });

          return;
        }

        resolve({
          tipoUsuario: tipoSelecionado
        });
      }, 350);
    })
  }

  onSelect(index) {
    const { tipos } = this.state;
    let tipoSelecionado;

    tipos.forEach((item, i) => {
      if (index === i) {
        tipos[i].selected = true;
        tipoSelecionado = tipos[i].tipo;
      } else {
        tipos[i].selected = false;
      }
    });

    this.setState({ tipos, tipoSelecionado });
  }

  render() {
    const { tipos } = this.state;
    const tiposSelect = [];

    tipos.forEach((item, index) => {
      tiposSelect.push(
        // <div
        //   key={index}
        //   className={tipos[index].selected ? 'selectTipo selected' : 'selectTipo'}
        //   onClick={() => this.onSelect(index)}
        // >
        //   {item.tipo}
        // </div>
        <Button
          key={index}
          className='botaoSelecionar'
          onClick={() => this.onSelect(index)}
          type={this.state.tipos[index].selected ? 'primary' : 'default'}
        >
          {item.tipo}
        </Button>
      );
    })

    return (
      <div className='stepContainer'>
        <div className='textContainer'>
          <div className='title'>
            Tipo de conta
          </div>

          <div className='description'>
            Primeiro, informe se você é gestor ou gerente da plataforma.
          </div>

          <div className='description bold'>
            Selecione uma das opções a seguir:
          </div>
        </div>

        <div className='tiposContainer'>
          {tiposSelect}
        </div>

        <Button
          className='buttonConfirm'
          type='primary'

          loading={this.props.loading}
          onClick={() => this.onClickButton(this.handleConfirm)}
        >
          Próximo
        </Button>
      </div>
    )
  }
}


export default withRouter(SelecionarTipoConta);
