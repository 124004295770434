import React from 'react';
import {
    Result,
    Steps,
    Button,
    message,
    Card,
    Table,
    Upload,
    Icon,
    notification,
    Progress,
    Modal,
    Spin
} from 'antd';
import "antd/dist/antd.css";
import './ImportarProdutos.css';
import XLSX from 'xlsx';
import { getGestorId } from '../../components/auth/auth-provider';
import produtoDB from '../../dataManager/dtmProduto';
import BluveLayout from '../../components/layout/BluveLayout'
import bluveStorage from '../../components/localstorage/localstorage'
import { H_48_HORAS } from '../../components/funcs/constants';
import history from '../../history';

const { Step } = Steps;

const openNotificationWithIcon = (type, title, description, duration) => {
    if (!duration) {
        duration = 4; // this is the default time
    }
    notification[type]({
        message: [title],
        description: [description],
        duration,
    });
};

class ImportarProdutos extends React.Component {
    state = {
        current: 0,
        listaProdutos: [],
        file: [],
        //percent: 0,
        showModal: false,
        modalTitle: "Salvando...",
        modalText: "Por favor, não feche a janela do navegador.",
        progressBarStatus: "normal",
        loading: false,
    }

    constructor(props) {
        super(props);

        this.setProducts = this.setProducts.bind(this);
        this.onOk = this.onOk.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    next() {
        if (this.state.listaProdutos.length === 0) {
            message.error("Selecione um arquivo para continuar.");
            return;
        }
        const current = this.state.current + 1;
        this.setState({ current })
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({ current })
    }

    setProducts(listaProdutos, file) {
        this.setState({ listaProdutos, file });
    }

    async onSave() {
        this.setState({ showModal: true, loading: true }, async () => {
            let listaProdutos = this.state.listaProdutos;

            const start = window.performance.now();

            const novaListaProdutos = await produtoDB.addBatch(listaProdutos)

            if (!novaListaProdutos.recorded) {
                const modalText = "Os produtos não foram salvos. Por favor, verifique a sua conexão e tente novamente."
                const modalTitle = "Erro!"
                const progressBarStatus = "error";

                this.setState({ progressBarStatus, modalText, modalTitle, loading: false })
                return;
            }

            const tempoFinal = (window.performance.now() - start) / 1000

            const modalText = "Os produtos foram salvos!"
            const modalTitle = "Perfeito!"
            const progressBarStatus = "success";

            this.setState({ progressBarStatus, modalText, modalTitle, loading: false })
        })
    }

    onOk() {
        this.setState({ showModal: false })
        bluveStorage.set('produtos', undefined);
        history.push('/produtos');
        history.go();
    }

    render() {

        const steps = [
            {
                title: "Seleção de Arquivo",
                content: <SelecionarArquivo setProducts={this.setProducts} file={this.state.file}></SelecionarArquivo>
            },
            {
                title: "Visualização",
                content: <Visualizacao listaProdutos={this.state.listaProdutos}></Visualizacao>
            }
        ];

        const current = this.state.current;
        return (
            <BluveLayout>
                <div>
                    <Steps
                        current={current}
                    >
                        {
                            steps.map((item) => (
                                <Step key={item.title} title={item.title} />
                            ))}
                    </Steps>
                    <div className="steps-content">{steps[current].content}</div>
                    <div className="steps-action">
                        {current < steps.length - 1 && (
                            <Button type="primary" onClick={() => this.next()}>
                                Próximo
                            </Button>
                        )}
                        {current === steps.length - 1 && (
                            <Button type="primary" onClick={() => this.onSave()}>
                                Salvar
                            </Button>
                        )}
                        {current > 0 && (
                            <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                                Voltar
                            </Button>
                        )}
                    </div>

                    <Modal
                        visible={this.state.showModal}
                        footer={[
                            <Button
                                disabled={this.state.progressBarStatus !== 'success'}
                                onClick={this.onOk}
                            >Ok</Button>
                        ]}
                        centered={true}
                        closable={false}
                        title={this.state.modalTitle}
                        className="modalBarraProgresso"
                        width={320}
                        bodyStyle={{ height: "300px", display: "flex", flexDirection: "column", verticalAlign: "middle" }}

                    >
                        {/*<span className="modalBarraProgressoText">{this.state.modalText}</span>*/}
                        {this.state.progressBarStatus !== 'normal' ?
                            <Result
                                status={this.state.progressBarStatus}
                                title={this.state.modalTitle}
                                subTitle={this.state.modalText}
                                style={{ margin: "auto" }}
                            ></Result>
                            :
                            <Spin size="large" style={{ margin: "auto", textAlign: "center" }} spinning={this.state.loading} tip="Esse processo pode levar alguns minutos. Por favor, não feche a janela do navegador."></Spin>
                        }
                        {/*
                        <Progress steps={1} width={80} style={{ display: "block", textAlign: "center" }} status={this.state.progressBarStatus} type="circle" percent={this.state.percent.toFixed(1)} />
                        */}
                    </Modal>

                </div>
            </BluveLayout>
        )
    }
}


class SelecionarArquivo extends React.Component {
    state = {
        tipoArquivo: '',
        file: this.props.file,
        acceptedFormats: [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/xlsx ",
            "application/xml",
            "application/xls",
            "application/vnd.ms-excel"
        ]
    }

    constructor(props) {
        super(props);
        this.onFileChange = this.onFileChange.bind(this);
        this.onFileRemove = this.onFileRemove.bind(this);
    }

    onFileChange(file) {
        if (file.file.status !== "removed") {
            if (!this.state.acceptedFormats.includes(file.file.type)) {
                message.error("Esse tipo de arquivo não é suportado.");
                return;
            }

            this.readFile(file.file);

            this.setState({ file: [file.file] })
        }
    };

    onFileRemove(file) {
        this.setState({ file: [] })
    }

    readFile(file) {
        const fileReader = new FileReader();
        const gestorId = getGestorId();
        fileReader.readAsBinaryString(file);
        fileReader.onload = (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary', cellNF: false });
            const sheet_name_list = workbook.SheetNames;

            //Achar células que contêm os cabeçalhos
            const columns = Object.entries(workbook.Sheets[sheet_name_list[0]]).filter((cell) =>
                cell[1].v && (cell[1].v === "Código" || cell[1].v === "Produto"));

            if (columns.length === 0) {
                message.error('Os cabeçalhos não foram encontrados, ou estão incorretos. Por favor, verifique se o arquivo está seguindo o padrão requisitado.', 6);
                this.onFileRemove();
                return;
            }

            const lastRow = workbook.Sheets[sheet_name_list[0]]["!ref"].split(":")[1].match(/\d+/g);
            workbook.Sheets[sheet_name_list[0]]["!ref"] = columns[0][0] + ":" + columns[1][0].match(/[a-zA-Z]+/g) + lastRow;

            let listaProdutos = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet_name_list[0]]);

            let productsArray = [];

            listaProdutos.forEach((produto) => {
                if (!produto.Código || !produto.Produto) {
                    return;
                }

                const item = {
                    codigo: produto.Código.toString(),
                    nome: produto.Produto,
                    ativo: true,
                    gestorId: gestorId
                }

                productsArray.push(item);
            });

            this.props.setProducts(productsArray, [file]);
        };
    }

    render() {
        const value = this.state.tipoArquivo;
        const file = this.state.file;
        return (
            <>
                <Card
                    title="Informações"
                >
                    <strong>Formatos Permitidos:</strong>
                    <ul>
                        <li>*.xml</li>
                        <li>*.xlsx</li>
                        <li>*.xls</li>
                    </ul>
                    <p>
                        <strong>OBS: Os códigos e produtos devem ser identificados pelos cabeçalhos "Código" e "Produto", respectivamente.</strong>
                    </p>
                </Card>
                <Card
                    title="Selecione o Arquivo"
                >
                    <Upload
                        accept=".xml, .xlsx, .xls"
                        name='file'
                        onRemove={this.onFileRemove}
                        beforeUpload={() => false}
                        onChange={this.onFileChange}
                        fileList={file}
                    >
                        <Button disabled={file.length > 0}>
                            <Icon type="download" />
                            Importar
                        </Button>
                    </Upload>
                </Card>
            </>
        )
    }
}

class Visualizacao extends React.Component {
    state = {
        listaProdutos: this.props.listaProdutos,
    }

    constructor(props) {
        super(props);
    }

    render() {
        const columns = [
            {
                title: 'Código',
                dataIndex: 'codigo',
                key: 'codigo',
            },
            {
                title: 'Produto',
                dataIndex: 'nome',
                key: 'produto',
            },
        ]
        return (
            <Card
                bodyStyle={{ height: "70Vh", overflowY: "scroll" }}
            >

                <Table
                    size="small"
                    columns={columns}
                    dataSource={this.state.listaProdutos}
                    bordered
                    scrollY={true}
                    rowKey={"codigo"}
                >
                </Table>
            </Card>
        )
    }
}

export default (ImportarProdutos);