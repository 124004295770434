import React from "react";
import { FieldArray, useFormikContext } from "formik";
import { FormItem, InputNumber } from "formik-antd";
import { currencyFormatter, currencyParser, } from "../helperFunctions";
import "./labelOverwrite.css"

const PremiacaoStep = () => {
  const { values, errors, setFieldValue } = useFormikContext();
  const styleMargin = { marginLeft: 20 }


  const handleChange = (value, index) => {
    const valueName = `premiacaoPorPeriodo[${index}]`;
    setFieldValue(valueName, value);
  };

  return (<>
    <FormItem
      name="premiacaoMetaPrincipal"
      label="Meta Principal"
      min={0}
      hasFeedback={false}
      style={styleMargin}
    >
      <InputNumber name="premiacaoMetaPrincipal"
        min={0}
        formatter={currencyFormatter()}
        parser={currencyParser}
        style={{
          width: 140,
          marginLeft: 5
        }}
      ></InputNumber>
    </FormItem>

    <FormItem
      name="premiacaoMetaAdicional"
      label="Meta Adicional"
      hasFeedback={false}
      style={styleMargin}
    >
      <InputNumber name="premiacaoMetaAdicional"
        formatter={currencyFormatter()}
        parser={currencyParser}
        style={{
          width: 140,
          marginLeft: 5
        }}
      ></InputNumber>
    </FormItem>

    <FormItem
      name="premiacaoTicketMedio"
      label="Ticket Médio"
      hasFeedback={false}
      style={styleMargin}
    >
      <InputNumber name="premiacaoTicketMedio"
        formatter={currencyFormatter()}
        parser={currencyParser}
        style={{
          width: 140,
          marginLeft: 5
        }}
      ></InputNumber>
    </FormItem>

    <FormItem
      name="premiacaoNumeroAtendimentos"
      label="Nº de Atendimentos"
      hasFeedback={false}
      style={styleMargin}
    >
      <InputNumber name="premiacaoNumeroAtendimentos"
        formatter={currencyFormatter()}
        parser={currencyParser}
        style={{
          width: 140,
          marginLeft: 5
        }}
      ></InputNumber>
    </FormItem>

    <span style={{ marginTop: 10 }} ><strong>Premiação por Período (semanas)</strong></span>

    <div style={{
      marginLeft: 20,
      display: 'flex'
    }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 10
        }}
      >
        <FormItem
          name={`premiacaoPorPeriodo[0]`}
          min={0}
          hasFeedback={false}
        >
          <span style={{ marginTop: 10 }} >Período 1:</span>
          <InputNumber
            name={`premiacaoPorPeriodo[0]`}
            min={0}
            formatter={currencyFormatter()}
            parser={currencyParser}
            style={{
              width: 140,
              marginLeft: 5,
              marginBottom: 5
            }}
            onChange={(value) => handleChange(value, 0)}
          />
        </FormItem>

        <FormItem
          name={`premiacaoPorPeriodo[1]`}
          min={0}
          hasFeedback={false}
        >
          <span style={{ marginTop: 10 }} >Período 2:</span>
          <InputNumber
            name={`premiacaoPorPeriodo[1]`}
            min={0}
            formatter={currencyFormatter()}
            parser={currencyParser}
            style={{
              width: 140,
              marginLeft: 5,
              marginBottom: 5
            }}
            onChange={(value) => handleChange(value, 1)}
          />
        </FormItem>

        <FormItem
          name={`premiacaoPorPeriodo[2]`}
          min={0}
          hasFeedback={false}
        >
          <span style={{ marginTop: 10 }} >Período 3:</span>
          <InputNumber
            name={`premiacaoPorPeriodo[2]`}
            min={0}
            formatter={currencyFormatter()}
            parser={currencyParser}
            style={{
              width: 140,
              marginLeft: 5,
              marginBottom: 5
            }}
            onChange={(value) => handleChange(value, 2)}
          />
        </FormItem>

        <FormItem
          name={`premiacaoPorPeriodo[3]`}
          min={0}
          hasFeedback={false}
        >
          <span style={{ marginTop: 10 }} >Período 4:</span>
          <InputNumber
            name={`premiacaoPorPeriodo[3]`}
            min={0}
            formatter={currencyFormatter()}
            parser={currencyParser}
            style={{
              width: 140,
              marginLeft: 5,
              marginBottom: 5
            }}
            onChange={(value) => handleChange(value, 3)}
          />
        </FormItem>
      </div>
    </div>
  </>
  )
}


export default PremiacaoStep;
