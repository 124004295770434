import { getGestorId, getUserType, getGerenteId } from '../../../components/auth/auth-provider';
import empresaDB from "../../../dataManager/dtmEmpresa";
import { GERENTE } from "../../../components/funcs/constants";
import gerenteDB from "../../../dataManager/dtmGerente";
import motivoDB from "../../../dataManager/dtmMotivo";
import conversaoDB from "../../../dataManager/dtmConversao";
import { salvarXlsx } from '../metas/Funcs-Metas';


let listaDeMotivos = undefined;


export async function getEmpresas() {
    const gestorId = getGestorId();
    const empresas = await empresaDB.getByGestorIdCustom(gestorId, 'login.login');
    const newEmpresas = [];
    const userType = getUserType();

    if (userType === GERENTE) {
        const gerenteId = getGerenteId();
        const empresasGerente = await gerenteDB.getEmpresasGerente(gerenteId, 'razaoSocial');

        for (let i = 0; i < empresasGerente.length; i++) {
            const empresa = empresas.filter((item) => {
                return item.key === empresasGerente[i].id;
            });

            if (empresa && empresa.length > 0) {
                newEmpresas.push(empresa[0]);
            }
        }
    }

    const sortedNewEmpresas = sortEmpresasAlphabetically(newEmpresas);
    const sortedEmpresas = sortEmpresasAlphabetically(empresas);
    const displayedEmpresas = sortedNewEmpresas.length > 0 ? sortedNewEmpresas : sortedEmpresas;
  
    return displayedEmpresas;
  }
  
  function sortEmpresasAlphabetically(empresas) {
    if (!empresas) {
      return [];
    }
    return empresas.sort((a, b) => a.nomeFantasia.localeCompare(b.nomeFantasia));
  }
  
export async function getMotivos() {

    if (!listaDeMotivos || listaDeMotivos.length === 0) {
        listaDeMotivos = [];
        const gestorId = getGestorId();
        const lista = await motivoDB.getByGestorIdWithoutOrderBy(gestorId);
        lista.forEach(motivo => {
            listaDeMotivos.push({
                id: motivo.key,
                nome: motivo.nome,
                quantidade: 0,
            });
        });
    }
    return listaDeMotivos;
}

export async function getData(empresaId, dataInicial, dataFinal) {
    const inicio = dataInicial.startOf("day").toDate();
    const fim = dataFinal.endOf("day").toDate();
    const snapshot = await conversaoDB.getVendasPerdidasByPeriodo(inicio, fim, empresaId);
    return snapshot;
}

export async function exportToExcel(data, empresaNome, periodo) {

    if (!data || data.length === 0) {
        return;
    }

    const dataInicial = periodo.inicial.toDate().toLocaleDateString();
    const dataFinal = periodo.final.toDate().toLocaleDateString();

    const infoExcel = [];
    infoExcel.push([`Empresa: ${empresaNome}`]);
    infoExcel.push([`Período: ${dataInicial} - ${dataFinal}`, '', 'Motivos']);
    infoExcel.push(['']);

    infoExcel.push([
        'Produtos',
        '',
    ]);

    data[0].motivos.forEach(motivo => {
        infoExcel[3] = infoExcel[3].concat(motivo.nome);
    });

    data.forEach(produto => {
        infoExcel.push([
            produto.nome,
            ''
        ]);

        const index = infoExcel.length -1;
        produto.motivos.forEach(motivo => {
            infoExcel[index] = infoExcel[index].concat(motivo.quantidade);
        });
    })

    const fileName = `Relatório de Produtos x Motivos - Período de ${dataInicial} até ${dataFinal}`;
    await salvarXlsx(infoExcel, fileName);
}