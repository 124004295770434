import React from 'react';
import { withRouter } from 'react-router'
import { Table, Icon, Button, Modal, Input, Tooltip, Checkbox, message } from 'antd';
import { getGestorId, getUserType } from '../../components/auth/auth-provider';
import './Gerente.css';
import "antd/dist/antd.css";
import GerenteCRUD from "../../components/cruds/gerentes/GerenteCRUD";
import BluveLayout from "../../components/layout/BluveLayout";
import history from "../../history";
import empresaDB from "../../dataManager/dtmEmpresa";
import listObj from "../../components/listSearch/listSearch";
import mensagem from "components/message/Message";
import API from 'services/api/api';

const { warning } = Modal;
const { Search } = Input;
const api = new API();

class Gerente extends React.Component {
  state = {
    gerentes: [],
    gerentesOriginal: [],
    gerentesLista: [],
    loading: true,
    userType: 'Gerente',
    checked: undefined
  }

  constructor(props) {
    super(props);
    this.headerGerenteElement = React.createRef();
    this.state.userType = getUserType();

    this.updateList = this.updateList.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.getCheckboxState = this.getCheckboxState.bind(this);
    this.checkboxUpdate = this.checkboxUpdate.bind(this);
  }

  componentDidMount() {
    document.title = `Bluve`;
    if (this.state.userType === 'Gestor') {
      document.addEventListener('tokenAvailable', () => this.getGerentes());
    } else {
      this.setState({ loading: false });
      if (this.state.userType !== 'Gestor') {
        warning({
          title: 'Atenção',
          content: 'Você não tem permissão para acessar esta área',
          async onOk() {
            history.push('/dashboard');
            history.go();
          },
          async onCancel() {
            history.push('/dashboard');
            history.go();
          },
        });
      }
    }
  }

  async applyFilter(text) {
    if (text === '') {
      this.setState({ gerentes: this.state.gerentesOriginal });
      return;
    };

    const keys = [
      'nome',
      'sobrenome',
      'cpf',
      'ativoStr',
      'email',
      'endereco.telefone',
      'empresas'
    ];

    let gerentes = [];
    if (!this.state.checked && this.state.gerentesLista) {
      gerentes = listObj.search(this.state.gerentesLista, text, keys);
    } else {
      gerentes = listObj.search(this.state.gerentesOriginal, text, keys);
    }

    this.setState({ gerentes });
  }

  updateList(record) {
    record['ativoStr'] = record['ativo'] ? 'Sim' : 'Não';
    let registroEncontrado = false;
    let listaAtivos = [];
    let listaInativos = [];

    if (this.state.checked) {
      listaInativos = this.state.gerentes;
      listaAtivos = this.state.gerentesLista;
    } else if (this.state.gerentesLista.length) {
      listaAtivos = this.state.gerentes;
      listaInativos = this.state.gerentesLista;
    } else {
      listaAtivos = this.state.gerentes;
    }

    if (listaAtivos) {
      listaAtivos.forEach((item, index) => {
        if (item.key === record.key) {
          if (!record['ativo']) {
            listaAtivos.splice(index, 1);
          } else {
            listaAtivos[index] = record;
          }
          registroEncontrado = true;
        }
      });
    }

    if (listaInativos.length) {
      listaInativos.forEach((item, index) => {
        if (item.key === record.key) {
          if (record['ativo']) {
            listaAtivos.push(record);
          }

          listaInativos[index] = record;
          registroEncontrado = true;
        }
      });
    }

    if (!registroEncontrado && record['ativo']) {
      listaAtivos.unshift(record);
      if (listaInativos.length) listaInativos.unshift(record);
    } else if (!registroEncontrado && !record['ativo'] && listaInativos.length) {
      listaInativos.push(record)
    }

    if (this.state.checked) {
      this.setState({ gerentes: listaInativos, gerentesOriginal: listaInativos, gerentesLista: listaAtivos });
    } else {
      this.setState({ gerentes: listaAtivos, gerentesOriginal: listaAtivos, gerentesLista: listaInativos });
    }
  }

  async getGerentes() {
    const gestorId = getGestorId();

    api.get({ ativo: true, gestorId }, '/gerente')
      .then(async (gerentes) => {
        let empresas;

        for (let i in gerentes) {
          gerentes[i].empresas = [];

          empresas = await api.get({
            gerenteId: gerentes[i].key
          }, '/gerente/empresas');

          for (let j in empresas) {
            const nomeEmpresa = empresas[j].nomeFantasia ? empresas[j].nomeFantasia : empresas[j].razaoSocial;
            gerentes[i].empresas += nomeEmpresa + ', ';
          }

          let listaEmpresas = gerentes[i].empresas;
          gerentes[i].empresas = listaEmpresas.substr(0, listaEmpresas.length - 2);

          if (gerentes[i].ativo === undefined) {
            await api.put({
              key: gerentes[i].key,
              updateList: {
                ativo: true
              }
            }, '/gerente');

            gerentes[i].ativoStr = 'Sim';
          } else {
            gerentes[i].ativoStr = gerentes[i].ativo === true ? 'Sim' : 'Não'
          }
        }

        this.setState({ gerentes, gerentesOriginal: gerentes, loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        message.error('Erro ao buscar gerentes.');
      });
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };

  async editarGerenteElement(record) {
    const empresas = await api.get({
      gerenteId: record.key
    }, '/gerente/empresas');

    record["empresas"] = empresas;
    this.headerGerenteElement.current.editarGerente(record);
  }

  getCheckboxState(checked) {
    this.setState({ checked }, () => {
      this.checkboxUpdate();
    });
  }

  async checkboxUpdate() {
    if (this.state.gerentesLista.length || !this.state.checked && this.state.gerentes) {
      this.setState({ loading: true });
      const gerentesLista = this.state.gerentesLista;
      const gerentes = this.state.gerentes;

      this.setState({
        gerentes: gerentesLista,
        gerentesOriginal: gerentesLista,
        gerentesLista: gerentes,
        loading: false
      })
    } else if (this.state.checked) {
      this.setState({ loading: true });
      let gerentes = this.state.gerentes;
      const gestorId = getGestorId();

      api.get({ ativo: false, gestorId }, '/gerente')
        .then(async (novaLista) => {

          if (gerentes && novaLista) {
            this.setState({ gerentesLista: gerentes });
            gerentes = gerentes.concat(novaLista);
          } else if (novaLista) {
            gerentes = novaLista;
          } else {
            this.setState({ gerentesLista: gerentes });
          }

          let empresas;
          for (let i in gerentes) {
            gerentes[i].empresas = [];

            empresas = await api.get({
              gerenteId: gerentes[i].key
            }, '/gerente/empresas');

            for (let j in empresas) {
              const nomeEmpresa = empresas[j].nomeFantasia ? empresas[j].nomeFantasia : empresas[j].razaoSocial;
              gerentes[i].empresas += nomeEmpresa + ', ';
            }

            let listaEmpresas = gerentes[i].empresas;
            gerentes[i].empresas = listaEmpresas.substr(0, listaEmpresas.length - 2);

            if (gerentes[i].ativo === undefined) {
              await api.put({
                key: gerentes[i].key,
                updateList: {
                  ativo: true
                }
              }, '/gerente');

              gerentes[i].ativoStr = 'Sim';
            } else {
              gerentes[i].ativoStr = gerentes[i].ativo === true ? 'Sim' : 'Não'
            }
          }

          this.setState({ gerentes: gerentes, gerentesOriginal: gerentes, loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
          message.error('Erro ao buscar gerentes.');
        });
    }
  }

  render() {
    const columns = [
      {
        title: 'Nome',
        dataIndex: 'nome',
        key: 'nome',
        editable: false,
      },
      {
        title: 'Sobrenome',
        dataIndex: 'sobrenome',
        key: 'sobrenome',
      },
      {
        title: 'CPF',
        dataIndex: 'cpf',
        key: 'cpf',
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Telefone',
        dataIndex: 'endereco.telefone',
        key: 'telefone',
      },
      {
        title: "Ativo?",
        dataIndex: "ativoStr",
        key: "ativo"
      },
      {
        title: "Empresa/Loja",
        dataIndex: 'empresas',
        key: "empresaLoja"
      },
      {
        title: '',
        width: 'auto',
        key: 'action',
        render: (text, record) => (
          <span>
            <Tooltip placement="topLeft" title="Editar Cadastro">
              <a onClick={() => this.editarGerenteElement(record)}>
                <Icon type="edit" />
              </a>
            </Tooltip>


          </span>
        ),
      },
    ];

    return (
      <div>
        <BluveLayout selectItem={'gerentes'}>
          <Table pagination={{
            defaultPageSize: 100,
            position: "both",
            pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
          }}
            title={() =>
              <HeaderGerente
                ref={this.headerGerenteElement}
                userType={this.state.userType}
                applyFilter={this.applyFilter}
                updateList={this.updateList}
                getCheckboxState={this.getCheckboxState}
              />}
            columns={columns}
            dataSource={this.state.gerentes}
            bordered
            loading={this.state.loading}
          />
        </BluveLayout>
      </div>
    );
  }
}

class HeaderGerente extends React.Component {
  constructor(props) {
    super(props);
    this.novoGerenteElement = React.createRef();

    this.state = {
      visible: false,
      checked: false,
      loading: false
    };

    this.novoGerente = this.novoGerente.bind(this);
    this.filterTable = this.filterTable.bind(this);
    this.onCheck = this.onCheck.bind(this);

    let timer;
  }

  novoGerente() {
    this.novoGerenteElement.current.show(false);
  }

  editarGerente(record) {
    this.novoGerenteElement.current.show(true, record);
  }

  filterTable(value) {
    this.setState({ loading: true });
    const { applyFilter } = this.props;

    const text = typeof value === "object" ? value.target.value : value;
    let time = text ? 3000 : 500;

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      applyFilter(text);
      this.setState({ loading: false });
    }, time);
  }

  onCheck(e) {
    const checked = e.target.checked;
    this.setState({ checked }, () => {
      this.props.getCheckboxState(checked);
    });
  }

  render() {
    return (
      <div>
        <NovoGerente
          ref={this.novoGerenteElement}
          updateList={this.props.updateList}
        ></NovoGerente>
        <Button
          disabled={this.props.userType === 'Gerente'}
          type="primary"
          onClick={this.novoGerente}
        >
          <Icon className="icon" type="plus" /> Novo Gerente
        </Button>

        <Search
          placeholder="Procurar"
          onSearch={this.filterTable}
          onChange={this.filterTable}
          loading={this.state.loading}
          style={{ width: 200, marginLeft: '10px', marginRight: '1rem' }}
        />

        <Checkbox checked={this.state.checked} onChange={this.onCheck}>
          Listar Inativos
        </Checkbox>

      </div>
    );
  }
}

class NovoGerente extends React.Component {
  state = {
    closable: true,
    visible: false,
    confirmLoading: false,
    editMode: false,
    record: [],
    empresas: [],
  };

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onCloseHandle = this.onCloseHandle.bind(this);
  }

  async componentDidMount() {
    const gestorId = getGestorId();
    if (!gestorId) {
      return;
    }
    const empresas = await empresaDB.getByGestorId(gestorId);
    this.setState({ empresas });
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record,
    });
  }

  handleOk(record) {
    this.props.updateList(record);
    this.setState({ visible: false, closable: true });
  }

  onCloseHandle(canClose) {
    this.setState({ closable: canClose });
  }

  async handleCancel() {
    if (!this.state.closable) return;
    if (!(await mensagem.confirmar("Cancelar alterações?"))) return;
    this.setState({ visible: false, closable: true });
  }

  render() {
    return (
      <Modal
        title="Gerente"
        visible={this.state.visible}
        destroyOnClose={true}
        confirmLoading={this.state.confirmLoading}
        centered={true}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        closable={this.state.closable}
      >
        <GerenteCRUD
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          editMode={this.state.editMode}
          record={this.state.record}
          empresas={this.state.empresas}
          onCloseHandle={this.onCloseHandle}
        ></GerenteCRUD>
      </Modal>
    );
  }
}

export default withRouter(Gerente);
