import React, { useState } from "react";
import { Formik } from "formik";
import FormikForm from "./FormikForm";
import { Steps } from "antd";
import { useFormikContext } from "formik";

const { Step } = Steps;

/**
 * Wizard is a single Formik instance whose children are each page of the
 * multi-step form. The form is submitted on each forward transition (can only
 * progress with valid input), whereas a backwards step is allowed with
 * incomplete data. A snapshot of form state is used as initialValues after each
 * transition. Each page has an optional submit handler, and the top-level
 * submit is called when the final page is submitted.
 * @param {*} props
 */
const Wizard = ({ children, initialValues, onSubmit }) => {
  const [stepNumber, setStepNumber] = useState(0);
  const [snapshot, setSnapshot] = useState(initialValues);

  const steps = React.Children.toArray(children);
  const totalSteps = steps.length;
  const step = steps[stepNumber];

  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previousHandle = (values) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (event, values, bag) => {
    event.preventDefault();

    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag);
    }

    if (isLastStep) {
      return onSubmit(values, bag);
    } else {
      // bag.setTouched({});
      next(values);
    }
  };

  const formProps = { step, stepNumber, previousHandle, isLastStep, handleSubmit };

  return (
    <div className="meta">
      <Steps current={stepNumber}>
        {steps.map((item, index) => (
          <Step key={"step-" + index} title={item.props.title} />
        ))}
      </Steps>

      <div className="steps-content">
        <Formik
          initialValues={snapshot}
          children={props => <FormikForm {...formProps} />}
        />
      </div>
    </div>
  );
};

export default Wizard;
