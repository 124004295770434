import React from "react";
import BluveLayout from "../../../components/layout/BluveLayout";
import { withRouter } from "react-router";
import { Select, Table, Button, message } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import {
  getEmpresas,
  buildReport,
  exportToExcel,
} from "./TaxaConversaoVendedorF.jsx";
import { getGestorId } from "../../../components/auth/auth-provider";
import { Bar, Pie } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "chart.piecelabel.js";
import "./TaxaConversaoVendedor.css";
import bluveStorage from "../../../components/localstorage/localstorage";
import locale from 'antd/es/date-picker/locale/pt_BR';

const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
const { RangePicker } = DatePicker;

const backgroundColorsSchema = [
  "rgb(153, 102, 255)",
  "rgb(255, 159, 64)",
  "rgb(0, 163, 51)",
  "rgb(244, 180, 0)",
  "rgb(54, 162, 235)",
  "rgb(240, 53, 53)",
  "rgb(201, 203, 207)",
  "rgb(0,0,255)",
  "rgb(255, 0, 255)",
  "rgb(128,128,0)",
  "rgb(0,139,139)",
];

class FluxoOportunidadeVendedor extends React.Component {
  state = {
    empresas: [],
    empresaId: '',
    dataSelecionada: moment(),
    reportData: [],
    lineChart: {},
    pieChartAtendimentos: {},
    pieChartConversoes: {},
    barChart: {},
    loading: false,
    periodo: {},
    alterouConfiguracaoRelatorio: false,
  };

  options = [];

  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
  }

  async componentDidMount() {
    const gestorId = await getGestorId();

    const storage = bluveStorage.getEmpresasGestor('@gestorEmpresas');
    let empresas = [];

    if (storage && storage.gestorId === gestorId) {
      empresas = storage.empresas;
    } else {
      empresas = await getEmpresas();

      const data = {
        gestorId: gestorId,
        empresas: empresas
      };

      bluveStorage.setEmpresasGestor(data);
    }

    for (const item of empresas) {
      this.options.push(
        <Option key={item.key} value={item.key}>
          {item.nomeFantasia}
        </Option>
      );
    }


    if (this.options.length > 1) {
      this.options.push(
        <Option key='todas' value='todas'>
          Todas
        </Option>
      );
    }

    if (!empresas.length) {
      empresas.push({
        id: "Nenhuma empresa cadastrada",
        razaoSocial: "Nenhuma empresa cadastrada",
      });
    }

    let empresaId = bluveStorage.getReportEmpresaId();
    if (!empresaId) {
      empresaId = empresas[0].key
    }

    let periodo = bluveStorage.getReportPeriodo();
    if (!periodo) {
      periodo = {
        inicial: moment().subtract(7, "days"),
        final: moment(),
      };
    }
    else {
      periodo.inicial = moment(periodo.inicial);
      periodo.final = moment(periodo.final);
    }

    const report = bluveStorage.getReportTaxaConversaoIndividual();
    if (report.lineData) {
      this.mountChart(report.lineData);
    }

    this.setState({
      empresaId,
      empresas: empresas,
      periodo,
    });
  }

  onSelectChange(value) {
    this.setState({ empresaId: value, alterouConfiguracaoRelatorio: true, reportData: [] });
  }

  onDateChange(value) {
    const periodo = {
      inicial: value[0],
      final: value[1]
    }
    this.setState({ periodo, alterouConfiguracaoRelatorio: true });
  }

  async onFilterClick() {
    if (this.state.empresaId === ('' || undefined)) {
      message.warning("Selecione uma empresa", 2);
      return;
    }

    if (!this.state.periodo.inicial || !this.state.periodo.final) {
      message.warning("Selecione o período para gerar o gráfico", 2);
      return;
    }

    this.setState({ loading: true });

    if (this.state.alterouConfiguracaoRelatorio) {
      bluveStorage.clearAllReportsData();
    }

    bluveStorage.setReportEmpresaId(this.state.empresaId);
    bluveStorage.setReportPeriodo(this.state.periodo);

    setTimeout(async () => {
      const lineData = await buildReport(
        this.state.empresaId,
        this.state.periodo.inicial,
        this.state.periodo.final,
        this.state.empresas
      );
      bluveStorage.setReportTaxaConversaoIndividual(lineData);
      this.mountChart(lineData);
    }, 500);
  }

  onExportClick() {
    let empresaFiltrada = this.state.empresas.filter(item => item.key === this.state.empresaId)[0];
    if (!empresaFiltrada && this.state.empresaId === 'todas') {
      empresaFiltrada = {
        razaoSocial: 'Todas',
        login: {
          login: 'Todas'
        }
      }
    }

    exportToExcel(
      this.state.reportData,
      `${empresaFiltrada.razaoSocial} (${empresaFiltrada.nomeFantasia})`,
      this.state.periodo,
      this.state.empresaId === 'todas'
    );
  }

  removerValoresZerados(lista) {
    lista = lista.filter((item) => {
      return item.atendimentos > 0 || item.convertidos > 0;
    });
    return lista;
  }

  mountChart(loja) {
    if (!loja) {
      message.info("Sem movimentos nesse período");

      const emptyChart = {
        labels: [],
        datasets: [],
      };

      this.setState({
        loading: false,
        reportData: [],
        lineChart: emptyChart,
        barChart: emptyChart,
        pieChartAtendimentos: emptyChart,
        pieChartConversoes: emptyChart,
      });
      return;
    }

    if (loja === undefined) {
      loja = [];
    }

    const labels = [];
    const chartAtendimentos = [];
    const chartConversoes = [];
    const backgroundColors = [];
    const chartPercConversoesLoja = [];

    loja.forEach((item) => {
      if (item.nome !== "Total") {
        labels.push(item.nome);
        chartAtendimentos.push(item.atendimentos);
        chartConversoes.push(item.convertidos);
        chartPercConversoesLoja.push(
          (item.convertidos / item.atendimentos) * 100
        );
        backgroundColors.push("rgba(75,192,192,0.7)");
      }
    });

    //==========  Line Chart
    const dtsLineChart = [];
    dtsLineChart.push({
      label: "Atendimentos",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgb(153, 102, 255)",
      borderColor: "rgb(153, 102, 255)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "#ffa39e",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "#ffa39e",
      pointHoverBorderColor: "#ffa39e   ",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: chartAtendimentos,
    });

    dtsLineChart.push({
      label: "Convertidos",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgb(54, 162, 235)",
      borderColor: "rgb(54, 162, 235)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgb(54, 162, 235)",
      // pointBorderColor: 'red',
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgb(54, 162, 235)",
      pointHoverBorderColor: "rgb(54, 162, 235)",
      // pointHoverBackgroundColor: 'red',
      // pointHoverBorderColor: 'red',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: chartConversoes,
    });

    const lineChartData = {
      labels: labels,
      datasets: dtsLineChart,
    };

    //==========  Line Chart

    const dtsBarChart = [];
    dtsBarChart.push({
      label: "Conversões",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgb(0, 163, 51)",
      borderColor: "rgb(0, 163, 51)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgb(0, 163, 51)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgb(0, 163, 51)",
      pointHoverBorderColor: "rgb(0, 163, 51)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: chartPercConversoesLoja,
    });

    const barChartData = {
      labels: labels,
      datasets: dtsBarChart,
    };

    // Pie Chart Atendimento
    const dtsPieChartAtendimentos = [];
    dtsPieChartAtendimentos.push({
      fill: false,
      lineTension: 0.1,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      backgroundColor: backgroundColorsSchema,
      data: chartAtendimentos,
    });

    const pieChartAttData = {
      labels: labels,
      datasets: dtsPieChartAtendimentos,
    };

    // Pie Chart Atendimento
    const dtsPieChartConversoes = [];
    dtsPieChartConversoes.push({
      fill: false,
      lineTension: 0.1,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      backgroundColor: backgroundColorsSchema,
      data: chartConversoes,
    });

    const pieChartConvData = {
      labels: labels,
      datasets: dtsPieChartConversoes,
    };

    loja = this.removerValoresZerados(loja);
    this.setState({
      loading: false,
      reportData: loja,
      lineChart: lineChartData,
      barChart: barChartData,
      pieChartAtendimentos: pieChartAttData,
      pieChartConversoes: pieChartConvData,
    });
  }


  onTableHandleChange = (pagination, filters, sorter) => {

    const columnSortMap = {
      nomeLoja: 'nomeLoja',
      nome: 'nome',
      atendimentos: 'atendimentos',
      convertidos: 'convertidos',
      encerramentosManuais: 'encerramentosManuais',
      percentual: 'percentual',
    };
    let reportData = this.state.reportData.slice();

    const filteredData = reportData.filter((item) => item.nome !== 'Total');
    const totalData = reportData.find((item) => item.nome === 'Total');

    const { columnKey, order } = sorter;
    if (columnKey) {
      const field = columnSortMap[columnKey];
      const sortOrder = order === 'descend' ? -1 : 1;

      filteredData.sort((a, b) => {
        const aValue = a[field];
        const bValue = b[field];
      
        if (aValue === null || bValue === null) {
          return sortOrder * (aValue === null ? -1 : 1);
        }
      
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return aValue.localeCompare(bValue, undefined, { numeric: true }) * sortOrder;
        }
      
        return sortOrder * (aValue - bValue);
      });
    }

    reportData = filteredData;
    reportData.push(totalData);


    this.setState({ reportData });
  }


  render() {
    let columns = [];

    if (this.state.empresaId === 'todas') {
      columns.push({
        title: 'Loja',
        dataIndex: 'nomeLoja',
        key: 'nomeLoja',
        sorter: true,
        sortDirections: ["descend", "ascend"],
      })
    }

    columns = columns.concat([
      {
        title: "Vendedor",
        dataIndex: "nome",
        key: "nome",
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Atendimentos",
        dataIndex: "atendimentos",
        key: "atendimentos",
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Convertidos",
        dataIndex: "convertidos",
        key: "convertidos",
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      // {
      //   title: "Encerramentos Manuais",
      //   dataIndex: "encerramentosManuais",
      //   key: "encerramentosManuais",
      //   sorter: true,
      //   sortDirections: ["descend", "ascend"],
      // },
      {
        title: "Taxa de conversão",
        dataIndex: "percentual",
        key: "percentual",
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
    ]);

    return (
      <div>
        <BluveLayout selectItem={"relatorio/taxa-conversao-vendedor"}>
          <div className="containerFluxoOportunidadesVendedor">
            <label>Empresa:</label>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "20%", marginTop: '50px', marginBottom: '15px' }}
              placeholder="Selecione uma empresa"
              onChange={this.onSelectChange}
              value={this.state.empresaId}
            >
              {this.options}
            </Select>
            <label>Período:</label>
            <div className="rangePickerDuplo">
              <RangePicker
                locale={locale}
                format={dateFormat}
                placeholder={["Data Inicial", "Data Final"]}
                onChange={this.onDateChange}
                value={[
                  this.state.periodo.inicial,
                  this.state.periodo.final,
                ]}
              />
            </div>
            <Button
              type="primary"
              loading={this.state.loading}
              onClick={this.onFilterClick}
            >
              Filtrar
            </Button>

            <Button
              className='excel-button'
              disabled={this.state.loading || this.state.reportData.length === 0}
              onClick={this.onExportClick}
              style={{ marginLeft: '1rem' }}
            >
              Exportar para Excel
            </Button>

          </div>

          <div className="left">
            <Bar
              data={this.state.barChart}
              width={100}
              height={280}
              options={{
                responsive: true,
                maintainAspectRatio: false,

                plugins: {
                  datalabels: {
                    anchor: "end",
                    align: "top",
                    font: {
                      weight: "bold",
                    },

                    formatter: function (value, context) {
                      return `${value
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")}%`;
                    },
                  },
                },

                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        min: 0,
                        stepSize: 20,
                        callback: function (value) {
                          return value + "%";
                        },
                      },
                    },
                  ],
                },
                legend: {
                  display: true,
                },
                title: {
                  display: false,
                },

                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      var dataset = data.datasets[tooltipItem.datasetIndex];
                      var currentValue = dataset.data[tooltipItem.index];
                      return currentValue.toString().replace(".", ",") + " %";
                    },
                    title: function (tooltipItem, data) {
                      return data.labels[tooltipItem[0].index];
                    },
                  },
                },
              }}
            />
          </div>

          <div className="right">
            <Bar
              data={this.state.lineChart}
              width={100}
              height={280}
              options={{
                responsive: true,
                maintainAspectRatio: false,

                plugins: {
                  datalabels: {
                    anchor: "end",
                    align: "top",
                    font: {
                      weight: "bold",
                    },
                  },
                },

                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        min: 0,
                      },
                    },
                  ],
                },
                legend: {
                  display: true,
                },
                title: {
                  display: false,
                },
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      var dataset = data.datasets[tooltipItem.datasetIndex];
                      var currentValue = dataset.data[tooltipItem.index];
                      return currentValue.toString().replace(".", ",") + " %";
                    },
                    title: function (tooltipItem, data) {
                      return data.labels[tooltipItem[0].index];
                    },
                  },
                },
              }}
            />
          </div>

          <div className="left">
            <Pie
              data={this.state.pieChartAtendimentos}
              width={100}
              height={380}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    display: false,
                  },
                },
                pieceLabel: {
                  render: "value",
                  position: "outside",
                },
                legend: {
                  display: true,
                },
                title: {
                  display: true,
                },

                scales: {
                  yAxes: [
                    {
                      ticks: {
                        display: false,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: "Atendimentos",
                      },

                      gridLines: {
                        drawOnChartArea: false,
                      },
                    },
                  ],
                },
              }}
            />
          </div>

          <div className="right">
            <Pie
              data={this.state.pieChartConversoes}
              width={100}
              height={380}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    display: false,
                  },
                },
                pieceLabel: {
                  render: "value",
                  position: "outside",
                },
                legend: {
                  display: true,
                },
                title: {
                  display: true,
                },

                scales: {
                  yAxes: [
                    {
                      ticks: {
                        display: false,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: "Conversões",
                      },

                      gridLines: {
                        drawOnChartArea: false,
                      },
                    },
                  ],
                },
              }}
            />
          </div>

          <div className="divTableGrafico">
            <Table
              columns={
                this.state.reportData.find(item => item.atendimentos != item.encerramentosManuais)
                  ? columns
                  : columns.filter(item => item.key !== "encerramentosManuais")
              }
              rowClassName={(record) => record.color.replace("#", "")}
              pagination={{ hideOnSinglePage: true }}
              dataSource={this.state.reportData}
              bordered
              rowKey={(record) => record.id}
              onChange={this.onTableHandleChange}
            />
          </div>
        </BluveLayout>
      </div>
    );
  }
}

export default withRouter(FluxoOportunidadeVendedor);
