import React from 'react';
import API from 'services/api/api';
import historicoDB from 'dataManager/dtmHistorico';
import dayjs from 'dayjs';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

import {
  getGestorId,
  getUserType,
  getGerenteId,
} from "../../../components/auth/auth-provider";

import empresaDB from "../../../dataManager/dtmEmpresa";
import { GERENTE } from "../../../components/funcs/constants";
import gerenteDB from "../../../dataManager/dtmGerente";

import { Select } from 'antd';

const { Option } = Select;
const api = new API();


// export async function getEmpresas(gestorId) {
//   debugger;
//   const empresas = await api.getEmpresas(gestorId);
//   return empresas;
// }

// export async function getEmpresasGerente(gerenteId) {
//   debugger;
//   const empresas = await api.getEmpresasGerente(gerenteId);
//   return empresas;
// }


export async function getEmpresas() {

  const gestorId = getGestorId();
  const empresas = await empresaDB.getByGestorIdCustom(gestorId, 'razaoSocial');
  const newEmpresas = [];
  const userType = getUserType();

  if (userType === GERENTE) {
    const gerenteId = getGerenteId();
    const empresasGerente = await gerenteDB.getEmpresasGerente(gerenteId, 'razaoSocial');

    for (let i = 0; i < empresasGerente.length; i++) {
      const empresa = empresas.filter((item) => {
        return item.key === empresasGerente[i].id;
      });

      if (empresa && empresa.length > 0 && (empresa[0].ativo === true)) {
        newEmpresas.push(empresa[0]);
      }
    }
  }

  const sortedNewEmpresas = sortEmpresasAlphabetically(newEmpresas);
  const sortedEmpresas = sortEmpresasAlphabetically(empresas);
  const displayedEmpresas = sortedNewEmpresas.length > 0 ? sortedNewEmpresas : sortedEmpresas;

  return displayedEmpresas;
}

function sortEmpresasAlphabetically(empresas) {
  if (!empresas) {
    return [];
  }
  return empresas.sort((a, b) => a.nomeFantasia.localeCompare(b.nomeFantasia));
}




export async function getVendedores(gestorId, empresaId) {
  const vendedores = await api.getVendedores(gestorId, empresaId, true);
  return vendedores;
}

export function getMonths() {
  const strings = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ];

  const months = [];

  strings.forEach((month, index) => {
    months.push({
      name: month,
      value: index
    })
  });

  return months;
}

export function getYears() {
  const firstYear = 2020;
  const lastYear = new Date().getFullYear() + 1;
  const years = [];

  for (let i = 0; i < (lastYear - firstYear); i++) {
    const year = firstYear + i;

    years.push({
      name: year.toString(),
      value: year
    })
  }

  return years;
}

export async function getRotinaVendedor(dataAno, dataMes, vendedorId) {
  const dayjsIni = dayjs(`${dataAno}-${dataMes + 1}`).startOf('month');
  const dayjsFim = dayjs(`${dataAno}-${dataMes + 1}`).endOf('month');

  let dataIni = dayjsIni.toDate();
  let dataFim = dayjsFim.toDate();

  const historico = await historicoDB.getHistoricoByPeriodo(
    dataIni,
    dataFim,
    'vendedorId',
    vendedorId,
    'Saida',
    true
  );

  dataIni = dayjsIni.subtract(2, 'month').toDate();
  dataFim = dayjsFim.subtract(1, 'month').toDate();

  const {
    dataRetorno: entradaUltimoHistorico
  } = await historicoDB.getVendedorUltimoHistoricoByPeriodo(dataIni, dataFim, vendedorId) ?? { dataRetorno: undefined };

  const { rotinaVendedor, linhaDoTempo } = await createRotinaAndLinhaDoTempo(historico, entradaUltimoHistorico, dataAno, dataMes);

  return {
    rotinaVendedor,
    linhaDoTempo
  };
}

export function createOptions(list) {
  const options = [];

  list.forEach((element) => {
    const {
      name,
      value
    } = element;

    options.push(
      <Option key={value} value={value}>
        {name}
      </Option>
    );
  });

  return options;
}

export async function exportarRotinaDoVendedor(empresa, nomeVendedor, rotinaVendedor, linhaDoTempo, dataAno, dataMes) {
  const diaFinal = dayjs(`${dataAno}-${dataMes + 1}`).endOf('month').date();
  
  
  const infoExcel = [];

  debugger;
  infoExcel.push(['Empresa:', empresa.nomeFantasia ? empresa.nomeFantasia : empresa.razaoSocial]);
  
  infoExcel.push(['Vendedor:', nomeVendedor], []);

  for (let i = 1; i <= diaFinal; i++) {
    if (!rotinaVendedor[i] && !linhaDoTempo[i]) continue;

    infoExcel.push(['Data', 'Horário de Entrada', 'Horário de Saída', 'Carga Horária', 'Marcações', 'Horário']);

    const {
      entradaF: entrada,
      saidaF: saida,
      cargaHoraria
    } = rotinaVendedor[i];

    infoExcel.push([
      dayjs(`${dataAno}-${dataMes + 1}`).date(i + 1).format('DD/MM/YYYY'),
      entrada,
      saida,
      cargaHoraria
    ]);

    const historico = linhaDoTempo[i];

    if (historico && historico.length > 0) {
      historico.forEach((item) => {
        const { motivo, horario } = item;

        infoExcel.push(['', '', '', '', motivo, horario]);
      });
    }
  }

  await salvarXlsx(infoExcel, nomeVendedor);
}

async function createRotinaAndLinhaDoTempo(historico, entradaUltimoHistorico, dataAno, dataMes) {
  const rotinaVendedor = {};
  const linhaDoTempo = {};

  if (
    entradaUltimoHistorico &&
    dayjs((
      typeof entradaUltimoHistorico === 'string' ?
        moment(entradaUltimoHistorico) :
        entradaUltimoHistorico
    ).toDate()).month() === dataMes
  ) {
    entradaUltimoHistorico = typeof entradaUltimoHistorico === 'string' ?
      dayjs(moment(entradaUltimoHistorico).toDate()) :
      dayjs(entradaUltimoHistorico.toDate());

    await adicionarEntrada(rotinaVendedor, entradaUltimoHistorico);
    await adicionarMarcacaoRetorno(linhaDoTempo, 'casa', entradaUltimoHistorico);
  }

  for (let i = 0; i < historico.length; i++) {
    let {
      dataRetorno: entrada,
      dataSaida: saida,
      motivo
    } = historico[i];

    if (entrada) {
      entrada = typeof entrada === 'string' ?
        dayjs(moment(entrada).toDate()) :
        dayjs(entrada.toDate());
    }

    if (saida) {
      saida = typeof saida === 'string' ?
        dayjs(moment(saida).toDate()) :
        dayjs(saida.toDate());
    }

    await adicionarEntrada(rotinaVendedor, entrada);
    await adicionarSaida(rotinaVendedor, saida);

    const marcacaoRetorno = await adicionarMarcacaoSaida(linhaDoTempo, motivo, saida, entrada);

    if (marcacaoRetorno) {
      await adicionarMarcacaoRetorno(linhaDoTempo, motivo, entrada);
    }
  }

  const diaFinal = dayjs(`${dataAno}-${dataMes + 1}`).endOf('month').date();
  await calcularCargaHoraria(linhaDoTempo, rotinaVendedor, diaFinal);

  return { rotinaVendedor, linhaDoTempo };
}

async function adicionarEntrada(rotinaVendedor, entrada) {
  if (!entrada) return;

  const dia = entrada.date();

  if (!rotinaVendedor[dia]) {
    rotinaVendedor[dia] = {
      entrada,
      saida: undefined,
      entradaF: entrada.format('HH:mm'),
      saidaF: '--:--',
      cargaHoraria: '--:--'
    }
  } else if (!rotinaVendedor[dia].entrada || entrada < rotinaVendedor[dia].entrada) {
    rotinaVendedor[dia].entrada = entrada;
    rotinaVendedor[dia].entradaF = entrada.format('HH:mm');
  }
}

async function adicionarSaida(rotinaVendedor, saida) {
  if (!saida) return;

  const dia = saida.date();

  if (!rotinaVendedor[dia]) {
    rotinaVendedor[dia] = {
      saida,
      entrada: undefined,
      entradaF: '--:--',
      saidaF: saida.format('HH:mm'),
      cargaHoraria: '--:--'
    }
  } else if (!rotinaVendedor[dia].saida || saida > rotinaVendedor[dia].saida) {
    rotinaVendedor[dia].saida = saida;
    rotinaVendedor[dia].saidaF = saida.format('HH:mm');
  }
}

async function adicionarMarcacaoSaida(linhaDoTempo, motivo, saida, entrada) {
  if (!saida || motivo.includes('fila')) return;

  const dia = saida.date();
  let marcacaoRetorno = true;

  if (!linhaDoTempo[dia]) {
    linhaDoTempo[dia] = [{
      retorno: entrada,
      motivo: 'Entrada',
      cardColor: getCardColor(motivo),
      horario: entrada ? entrada.format('HH:mm:ss') : '--:--',
    }]

    marcacaoRetorno = false;
  }

  linhaDoTempo[dia].push({
    saida,
    motivo: motivo === 'casa' ? 'Saída' : `${motivo.charAt(0).toUpperCase() + motivo.slice(1)} - Saída`,
    cardColor: getCardColor(motivo),
    horario: saida.format('HH:mm:ss'),
  });

  return marcacaoRetorno;
}

async function adicionarMarcacaoRetorno(linhaDoTempo, motivo, retorno) {
  if (!retorno || motivo.includes('fila')) return;

  const dia = retorno.date();

  if (!linhaDoTempo[dia]) {
    linhaDoTempo[dia] = [{
      retorno,
      motivo: 'Entrada',
      cardColor: getCardColor(motivo),
      horario: retorno.format('HH:mm:ss'),
    }]
  } else {
    linhaDoTempo[dia].push({
      retorno,
      motivo: `${motivo.charAt(0).toUpperCase() + motivo.slice(1)} - Retorno`,
      cardColor: getCardColor(motivo),
      horario: retorno.format('HH:mm:ss'),
    });
  }
}

async function calcularCargaHoraria(linhaDoTempo, rotinaVendedor, diaFinal) {
  for (let i = 1; i <= diaFinal; i++) {
    if ((!rotinaVendedor[i] && !linhaDoTempo[i]) || !linhaDoTempo[i]) continue;

    let primeiroHorario = undefined;
    let segundoHorario = undefined;
    let cargaHoraria = undefined;

    linhaDoTempo[i].forEach((item) => {
      const { motivo, saida, retorno } = item;

      if (
        !motivo.includes('Banheiro') &&
        !motivo.includes('Café') &&
        !motivo.includes('Outro')
      ) {
        primeiroHorario = primeiroHorario ?? saida;
        segundoHorario = segundoHorario ?? retorno;

        if (primeiroHorario && segundoHorario) {
          const min = primeiroHorario.diff(segundoHorario, 'minutes', true);

          let totalHours = dayjs().hour(parseInt(min / 60)).format('HH');
          let totalMins = dayjs().minute(min).format('mm');

          if (cargaHoraria) {
            let [horas, minutos] = cargaHoraria.split(':');
            const cargaHorariaMinutos = Number(minutos) + Number(totalMins) + ((Number(horas) + Number(totalHours)) * 60);

            const duration = moment.duration(cargaHorariaMinutos, 'minutes');
            totalHours = duration.get('hour');
            totalMins = duration.get('minute');

            cargaHoraria = `${('0' + totalHours).slice(-2)}:${('0' + totalMins).slice(-2)}`;
          } else {
            cargaHoraria = `${totalHours}:${totalMins}`;
          }

          primeiroHorario = undefined;
          segundoHorario = undefined;
        }
      }
    });

    rotinaVendedor[i].cargaHoraria = cargaHoraria ?? '--:--';
  }
}

async function salvarXlsx(infoExcel, nomeVendedor) {
  const fileName = `Relatório de Rotina do Vendedor - ${nomeVendedor}.xlsx`;
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const worksheet = XLSX.utils.aoa_to_sheet(infoExcel);
  worksheet['!cols'] = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }];

  const woorkbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

  const excelBuffer = XLSX.write(woorkbook, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, fileName);
}

function getCardColor(motivo) {
  switch (motivo) {
    case 'almoço' || 'café':
      return '#096DD9'

    case 'banheiro':
      return '#4D7FBA'

    case 'outro':
      return '#2B6BAC'

    default:
      return '#1890ff';
  }
}
