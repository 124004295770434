import React from "react";
import { withRouter } from 'react-router';
import { REDES_SOCIAIS } from '../../components/funcs/constants';

// CSSs
import "../../assets/template/assets/css/bootstrap.min.css";
import "../../assets/template/assets/css/font-awesome.min.css";
import "../../assets/template/assets/css/themify-icons.css";
import "../../assets/template/assets/css/magnific-popup.css";
import "../../assets/template/assets/css/owl.carousel.css";
import "../../assets/template/assets/css/owl.transitions.css";
import "../../assets/template/assets/css/plyr.css";
import "../../assets/template/assets/css/swiper.min.css";
import "../../assets/template/assets/css/slick.css";
import "../../assets/template/css/scheme/blue-green.css";
import "../../assets/template/css/style.css";
import "../../assets/template/css/responsive.css";


import '../../assets/template/assets/js/jquery.min.js';
import '../../assets/template/assets/js/bootstrap.min.js';
import '../../assets/template/assets/js/jquery.nav.js';
import '../../assets/template/assets/js/owl.carousel.js';
import '../../assets/template/assets/js/visible.js';
import '../../assets/template/assets/js/jquery.stellar.min.js';
import '../../assets/template/assets/js/jquery.countTo.js';
import '../../assets/template/assets/js/imagesloaded.pkgd.min.js';
import '../../assets/template/assets/js/isotope.pkgd.min.js';
import '../../assets/template/assets/js/jquery.magnific-popup.min.js';
import '../../assets/template/assets/js/jquery.ajaxchimp.min.js';
import '../../assets/template/assets/js/plyr.js';
import '../../assets/template/assets/js/swiper.min.js';
import '../../assets/template/assets/js/slick.min.js';


class Sobre extends React.Component {

  constructor(props) {
    super(props);
    document.title = `Bluve - Sobre nós`;
  }

  render() {
    return (
      <div className="blue-green">

        {/* Header */}
        <nav className="navbar navbar-default style-10" data-spy="affix" data-offset-top="60">
          <div className="container-fluid">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                data-target="#navbar-collapse" aria-expanded="false">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand" href="/home">
                <img src="https://storage.googleapis.com/bluve-bucket/logo_oficial_branca_com_azul.png"
                  className="bluveLogoTopo"
                  alt="Bluve Logo"></img>
              </a>
            </div>

            <div className="collapse navbar-collapse" id="navbar-collapse">
              <ul className="nav navbar-nav navbar-left onepage-nav">
                <li><a href="/home">Home</a></li>
                {/* <li><a href="https://blog.bluve.com.br" target="_blank" rel="noopener noreferrer">Blog</a></li> */}
              </ul>
            </div>
          </div>
        </nav>
        {/* Header End */}

        {/* Page Header */}
        <div className="page-header style-9">
          <div className="container">
          </div>
        </div>
        {/* Page Header End  */}

        <div className="cps-main-wrap">

          {/* About US */}

          <div class="cps-section cps-section-padding" id="about">
            <div class="container">
              <div class="row">
                <div class="col-xs-12">
                  <div class="cps-about-img">
                    <img
                      className="bluveLogoTopo"
                      src="https://storage.googleapis.com/bluve-bucket/bluve-site/c2b-logo-assinatura.png"
                      alt="Pessoas no fundo"></img>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8 col-md-offset-2">
                  <div class="cps-section-header text-center">
                    <h3 class="cps-section-title">Quem somos?</h3>
                    <p class="cps-section-text">Somos uma startup focada em melhorar o setor varejista, tornando-o cada vez mais competitivo e eficiente, enfrentando os mais diversos desafios do mercado.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* About US End */}


          {/* Services */}

          <div class="cps-section cps-section-padding cps-bottom-0" id="service-box">
            <div class="container">
              <div class="row">
                <div class="cps-service-boxs">
                  <div class="col-sm-4">
                    <div class="cps-service-box">
                      <div class="cps-service-icon">
                        <span class="ti-medall"></span>
                      </div>
                      <h4 class="cps-service-title">Valores</h4>
                      <p class="cps-service-text">- Inovação</p>
                      <p class="cps-service-text">- Valorização de pessoas </p>
                      <p class="cps-service-text">- Melhoria contínua</p>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="cps-service-box">
                      <div class="cps-service-icon">
                        <span class="ti-pin-alt"></span>
                      </div>
                      <h4 class="cps-service-title">Missão</h4>
                      <p class="cps-service-text">Desenvolver produtos que deixem as pessoas seguras e confiantes de que através deles, obterão resultados melhores em suas análises e controles.</p>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="cps-service-box">
                      <div class="cps-service-icon">
                        <span class="ti-eye"></span>
                      </div>
                      <h4 class="cps-service-title">Visão</h4>
                      <p class="cps-service-text">Manter a empresa enxuta, podendo assim focar em oferecer sistemas de alto desempenho, porém de baixa complexidade, sempre mantendo um ambiente de trabalho alegre e descontraído, tornando-se assim referência para colaboradores, fornecedores e clientes.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Services End */}

          <br></br>
          <br></br>
        </div>

        <footer>
          <div className="cps-footer-upper">
            <div className="container">
              <div className="cps-footer-widget-area">
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="cps-widget about-widget">
                      <a className="cps-footer-logo" href="/home">
                        <img
                          className="bluveLogoTopo"
                          alt="Logo Bluve Rodapé"
                          src="https://storage.googleapis.com/bluve-bucket/b_oficial.png"
                        ></img>
                      </a>

                      <p>{REDES_SOCIAIS}</p>
                      <div className="cps-socials">
                        <a href="https://www.facebook.com/bluvebr" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>
                        <a href="https://www.instagram.com/bluvebr" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a>
                      </div>
                    </div>
                  </div>


                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="cps-widget custom-menu-widget">
                      <h4 className="cps-widget-title">Mais</h4>
                      <ul className="widget-menu">
                        <li><a href="/sobre">Sobre nós</a></li>
                        {/* <li><a href="/produtos">Nossos produtos</a></li> */}
                        <li><a href="/time">Nosso time</a></li>
                        {/* <li><a href="/clientes">Nossos clientes</a></li> */}
                        <li><a href="/contato">Contato</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cps-footer-lower">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-xs-12 xs-text-center">
                  <p className="copyright">Copyright {new Date().getFullYear()} - C2b Software do Brasil Ltda.,{" "}
                    <a href="/home">Bluve</a>. Todos os direitos reservados
                  </p>
                </div>
                <div className="col-sm-6 col-xs-12 text-right xs-text-center">
                  <ul className="footer-menu">
                    <li><a href="/privacidade">Política de privacidade</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }

}

export default withRouter(Sobre);
