import React from "react";
import BluveLayout from "../../../components/layout/BluveLayout";
import { withRouter } from "react-router";
import { Select, Table, Button, Checkbox, message } from "antd";
import { DatePicker } from 'antd';
import moment from 'moment';
import { getEmpresas, buildReport, exportToExcel } from './ForaMixF.jsx';
import './ForaMix.css';
import { getUserType, getGestorId } from '../../../components/auth/auth-provider';
import bluveStorage from "../../../components/localstorage/localstorage";
import funcoes from "../../../components/funcs/funcoes";
import locale from 'antd/es/date-picker/locale/pt_BR';

const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;


class ForaMix extends React.Component {
    state = {
        empresas: [],
        empresaId: '',
        dataSelecionada: moment(),
        reportData: [],
        loading: false,
        periodo: {},
        alterouConfiguracaoRelatorio: false,
        showCheckBox: false,
        checked: false,
        columns: [
            {
                title: 'Produto',
                dataIndex: 'nome',
                key: 'nome',
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
            },
        ]
    }

    options = [];

    constructor(props) {
        super(props);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.buildColumns = this.buildColumns.bind(this);
        this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
        this.onExportClick = this.onExportClick.bind(this);

    }

    async componentDidMount() {
        const gestorId = await getGestorId();

        //const storage = bluveStorage.getEmpresasGestor('@gestorEmpresas');
        const storage = undefined;
        let empresas = [];

        if (storage && storage.gestorId === gestorId) {
            empresas = storage.empresas;
        } else {
            empresas = await getEmpresas();

            const data = {
                gestorId: gestorId,
                empresas: empresas
            };

            bluveStorage.setEmpresasGestor(data);
        }

        for (const item of empresas) {
            this.options.push(<Option key={item.key} value={item.key}>{item.nomeFantasia}</Option>);
        }

        const userType = await getUserType();

        //if (userType === 'Gestor') {
        this.options.push(<Option key={'todas'} value={'todas'}>Todas</Option>);
        //}

        if (!empresas.length) {
            empresas.push({
                id: 'Nenhuma empresa cadastrada',
                razaoSocial: 'Nenhuma empresa cadastrada'
            });
        }

        let empresaId = bluveStorage.getReportEmpresaId();
        //get('@reports/foraMix/empresaId');
        if (!empresaId) {
            empresaId = empresas[0].key
        }
        else {
            if (empresaId !== 'todas') {
                empresaId = bluveStorage.getReportEmpresaId();
            }
        }

        let periodo = bluveStorage.getReportPeriodo();
        if (!periodo) {
            periodo = {
                inicial: moment().subtract(7, "days"),
                final: moment(),
            };
        }
        else {
            periodo.inicial = moment(periodo.inicial);
            periodo.final = moment(periodo.final);
        }

        const report = bluveStorage.getReportForaMix();
        let reportData = report.data ? report.data : [];
        let columns = report.columns ? report.columns : [];
        let showCheckBox = bluveStorage.get('@reports/foraMix/showCheckBox');
        if (!showCheckBox) {
            showCheckBox = false;
        } else {
            showCheckBox = funcoes.strToBool(showCheckBox);
        }

        let checked = bluveStorage.get('@reports/foraMix/checked');
        if (!checked) {
            checked = false;
        } else {
            checked = funcoes.strToBool(checked);
        }

        this.setState({
            empresaId,
            empresas: empresas,
            periodo,
            reportData,
            columns,
            showCheckBox,
            checked,
        });
    }

    onSelectChange(value) {
        if (value !== 'todas') {
            this.setState({ alterouConfiguracaoRelatorio: true });
        }
        this.setState({
            empresaId: value,
            showCheckBox: value === 'todas',
            checked: value === 'todas'
        });
    }

    onCheckBoxChange(e) {
        this.setState({ checked: e.target.checked });
    }

    onExportClick() {
        const empresaFiltrada = this.state.empresas.filter(item => item.key === this.state.empresaId)[0];
        const empresa =  empresaFiltrada ? `${empresaFiltrada.razaoSocial} (${empresaFiltrada.nomeFantasia})` : 'Todas as empresas'
        exportToExcel(
            this.state.reportData,
            empresa,
            this.state.periodo,
            this.state.columns
        );
    }

    onDateChange(value) {
        const periodo = {
            inicial: value[0],
            final: value[1]
        }
        this.setState({ periodo, alterouConfiguracaoRelatorio: true });
    }

    buildColumns() {
        let columns = [];
        if (this.state.empresaId === 'todas' && this.state.checked) {
            columns = [
                {
                    title: 'Produto',
                    dataIndex: 'nome',
                    key: 'nome',
                },
                {
                    title: 'Empresa',
                    dataIndex: 'empresa',
                    key: 'empresa',
                },
                {
                    title: 'Total',
                    dataIndex: 'total',
                    key: 'total',
                },
            ];
        }
        else {
            columns = [
                {
                    title: 'Produto',
                    dataIndex: 'nome',
                    key: 'nome',
                },
                {
                    title: 'Total',
                    dataIndex: 'total',
                    key: 'total',
                },
            ];
        }
        return columns;
    }

    async onFilterClick() {
        if (!this.state.periodo.inicial || !this.state.periodo.final) {
            message.warning('Selecione o período para gerar o gráfico', 2);
            return;
        }

        this.setState({ loading: true });
        setTimeout(async () => {
            const columns = this.buildColumns();
            const data = await buildReport(
                this.state.empresaId,
                this.state.periodo.inicial,
                this.state.periodo.final,
                this.state.checked);

            if (this.state.alterouConfiguracaoRelatorio) {
                bluveStorage.clearAllReportsData();
            }

            bluveStorage.setReportPeriodo(this.state.periodo);
            bluveStorage.set('@reports/foraMix/empresaId', this.state.empresaId);
            bluveStorage.set('@reports/foraMix/showCheckBox', this.state.showCheckBox);
            bluveStorage.set('@reports/foraMix/checked', this.state.checked);
            if (this.state.empresaId !== 'todas') {
                bluveStorage.setReportEmpresaId(this.state.empresaId);
            }
            bluveStorage.setReportForaMix(data, columns);
            this.setState({ loading: false, reportData: data, columns });

            if (!data) {
                message.info('Sem movimentos nesse período');
                this.setState({ loading: false, reportData: [], columns });
                return;
            }
        }, 500);

    }

    render() {

        return (
            <div>
                <BluveLayout selectItem={"relatorio/fora-mix"}>

                    <div className="containerExtratoAtendimentos">
                        <div className="child">

                            <label>Empresa:</label>
                            <br></br>
                            <Select
                                disabled={this.state.loading}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: "200px" }}
                                placeholder='Selecione uma empresa'
                                onChange={this.onSelectChange}
                                value={this.state.empresaId}
                            >
                                {this.options}
                            </Select>

                            {this.state.showCheckBox ?
                                <Checkbox disabled={this.state.loading} onChange={this.onCheckBoxChange} checked={this.state.checked}>Separar por Empresa?</Checkbox>
                                : ''
                            }

                        </div>

                        <div className="child">

                            <label>Período:</label>
                            <br></br>
                            <div className="rangePickerDuplo">
                                <RangePicker
                                    locale={locale}
                                    disabled={this.state.loading}
                                    format={dateFormat}
                                    placeholder={['Data Inicial', 'Data Final']}
                                    onChange={this.onDateChange}
                                    value={[this.state.periodo.inicial, this.state.periodo.final]}
                                />
                            </div>

                        </div>

                        <div className="child">
                            <Button type='primary' loading={this.state.loading} onClick={this.onFilterClick}>Filtrar</Button>
                            <br></br>
                            <br></br>
                            <Button
                                className='excel-button-v2'
                                disabled={this.state.loading || this.state.reportData && this.state.reportData.length === 0}
                                onClick={this.onExportClick}
                                style={{ marginLeft: '1rem' }}
                            >
                                Exportar para Excel
                            </Button>

                        </div>

                    </div>

                    <div className="divTableGrafico">
                        <Table
                            columns={this.state.columns}
                            rowClassName={(record) => record.color.replace('#', '')}
                            pagination={{ hideOnSinglePage: true }}
                            dataSource={this.state.reportData}
                            bordered
                            rowKey={record => record.key}
                        />
                    </div>

                </BluveLayout>
            </div>
        );
    }
}


export default withRouter(ForaMix);
