import { DatePicker, Modal } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';

const EditarVendas = (props) => {
    const [loading, setLoading] = useState(false);
    const [registro, setRegistro] = useState(props.registroEdicao);
    
    const dataInicialMoment = moment(registro.dataInicial, 'DD/MM/YYYY HH:mm');
    const dataFinalMoment = moment(registro.dataFinal, 'DD/MM/YYYY HH:mm');


    console.clear();
    console.log('registro', registro);   



    const handleCancel = () => {
        props.onCancel();
    };

    const handleOk = () => {
        props.onCancel();
    };

    return (
        <div>
            <Modal
                title={`Editar Venda de ${registro.vendedor}`}
                visible={props.visible}
                width={800}
                style={{ maxWidth: '800px' }}
                centered={true}
                maskClosable={true}
                closable={true}
                onCancel={handleCancel}
                onOk={handleOk}
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                        <label style={{ marginBottom: '5px', display: 'block' }}>Data e Hora Inicial:</label>
                        <DatePicker
                            showTime
                            placeholder="Data e Hora Inicial"
                            defaultValue={dataInicialMoment}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <label style={{ marginBottom: '5px', display: 'block' }}>Data e Hora Final:</label>
                        <DatePicker
                            showTime
                            placeholder="Data e Hora Final"
                            defaultValue={dataFinalMoment}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default EditarVendas;
