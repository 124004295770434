import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button, Input } from 'antd';


class CodigoConfirmacao extends Component {
  state = {
    token: '',
  }

  constructor(props) {
    super(props);

    this.kba = this.props.kba;
    this.historicoId = this.props.historicoId;
    this.onClickButton = this.props.onClickButton;

    this.handleConfirm = this.handleConfirm.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
  }

  async handleConfirm() {
    return new Promise(async (resolve, reject) => {
      const { token } = this.state;

      if (token === '') {
        reject({
          type: 'error',
          title: 'Token Não Informado',
          description: 'Digite-o no campo abaixo.'
        });

        return;
      }

      try {
        const {
          valid,
          message
        } = await this.kba.validateAccessToken(token, this.historicoId);

        if (valid) {
          resolve({
            message: 'Código de confirmação validado com sucesso.'
          });
        } else {
          reject({
            type: 'error',
            title: 'Código de confirmação inválido',
            description: message
          })
        }
      } catch (error) {
        reject({
          type: 'error',
          title: 'Erro',
          description: error.message
        })
      }
    })
  }

  async onChangeInput(e) {
    const { value } = e.target;
    this.setState({ token: value });
  }

  render() {
    return (
      <div className='stepContainer'>
        <div className='textContainer'>
          <div className='title'>
            Código de confirmação
          </div>

          <div className='description'>
            Enviamos um código para o seu e-mail.
          </div>

          <div className='description bold'>
            Digite-o no campo a seguir:
          </div>
        </div>

        <Input
          className='stepInput'
          placeholder='Código de Confirmação'

          value={this.state.token}
          onChange={this.onChangeInput}
        />

        <Button
          className='buttonConfirm'
          type='primary'

          loading={this.props.loading}
          onClick={() => this.onClickButton(this.handleConfirm)}
        >
          Próximo
        </Button>
      </div>
    )
  }
}

export default withRouter(CodigoConfirmacao);
