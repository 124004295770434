import {
  getGestorId,
  getUserType,
  getGerenteId,
} from "../../../components/auth/auth-provider";
import empresaDB from "../../../dataManager/dtmEmpresa";
import { GERENTE } from "../../../components/funcs/constants";
import gerenteDB from "../../../dataManager/dtmGerente";
import rankingDB from "../../../dataManager/dtmRanking";
import vendedorDB from "../../../dataManager/dtmVendedor";
import moment from 'moment';
import { salvarXlsx } from "../metas/Funcs-Metas";

export async function getVendedores(empresaId) {
  const vendedores = await vendedorDB.getByEmpresaId(empresaId);

  return vendedores;
}

export async function getEmpresas() {
  const gestorId = getGestorId();
  const empresas = await empresaDB.getByGestorIdCustom(gestorId, 'login.login');
  const newEmpresas = [];
  const userType = getUserType();

  if (userType === GERENTE) {
    const gerenteId = getGerenteId();
    const empresasGerente = await gerenteDB.getEmpresasGerente(gerenteId, 'razaoSocial');

    for (let i = 0; i < empresasGerente.length; i++) {
      const empresa = empresas.filter((item) => {
        return item.key === empresasGerente[i].id;
      });

      if (empresa && empresa.length > 0) {
        newEmpresas.push(empresa[0]);
      }
    }
  }

  const sortedNewEmpresas = sortEmpresasAlphabetically(newEmpresas);
  const sortedEmpresas = sortEmpresasAlphabetically(empresas);
  const displayedEmpresas = sortedNewEmpresas.length > 0 ? sortedNewEmpresas : sortedEmpresas;

  return displayedEmpresas;
}

function sortEmpresasAlphabetically(empresas) {
  if (!empresas) {
    return [];
  }
  return empresas.sort((a, b) => a.nomeFantasia.localeCompare(b.nomeFantasia));
}

export async function buildReport(empresaId, dataInicial, dataFinal, vendedorId) {

  const inicio = dataInicial.startOf('day').toDate();
  const fim = dataFinal.endOf('day').toDate();

  const ranking = await rankingDB.getByVendedorIdAndPeriodo(vendedorId, inicio, fim);

  let infoVendedor = ranking.map((item) => {
    const data = moment(item.data.seconds * 1000);
    const element = {
      vendedorId: item.vendedorId,
      atendimentos: item.atendimentos.quantidade,
      conversoes: item.atendimentos.conversoes,
      taxaConversao: calcularPorcentagem(item.atendimentos.conversoes, item.atendimentos.quantidade),
      data: data
    }

    return element;
  })

  infoVendedor = fillEmptyDays(dataInicial, dataFinal, infoVendedor);

  return infoVendedor
}


function fillEmptyDays(inicio, fim, info) {

  const days = fim.diff(inicio, "days");
  let novoInfo = [];

  for (let i = 0; i <= days; i++) {
    let data = moment(fim).startOf("day").subtract(i, "days");
    let item = info.find((element) => (moment(element.data.toDate()).startOf("day")).diff(data) === 0)

    if (item) {
      novoInfo.push({
        vendedorId: item.vendedorId,
        atendimentos: item.atendimentos,
        conversoes: item.conversoes,
        taxaConversao: item.taxaConversao,
        data: item.data.toDate()
      })
    } else {
      const momentData = moment(data)
      novoInfo.push({
        vendedorId: '',
        atendimentos: 0,
        conversoes: 0,
        taxaConversao: 0,
        data: data.toDate()
      })
    }
  }
  novoInfo = novoInfo.sort((a, b) => a.data > b.data ? 1 : -1)
  return novoInfo;
}

function calcularPorcentagem(value1, value2) {
  return ((value1 * 100) / value2).toFixed(0);
}

export async function exportToExcel(data, empresaNome, vendedorNome, periodo) {
  const dataInicial = periodo.inicial.toDate().toLocaleDateString();
  const dataFinal = periodo.final.toDate().toLocaleDateString();

  const infoExcel = [];
  infoExcel.push([`Empresa: ${empresaNome}`]);
  infoExcel.push([`Vendedor: ${vendedorNome}`]);
  infoExcel.push(['']);
  infoExcel.push(['Data', 'Atendimentos', 'Conversões', 'Taxa de Conversão']);

  let atendimentos = 0;
  let conversoes = 0;

  data.forEach((item) => {
    atendimentos += item.atendimentos ? item.atendimentos : 0;
    conversoes += item.conversoes ? item.conversoes : 0;

    const conversao = item.taxaConversao === 'NaN' ? '--' : item.taxaConversao;

    infoExcel.push([
      new Date(item.data).toLocaleDateString(),
      item.atendimentos,
      item.conversoes,
      conversao,
    ]);
  });

  infoExcel.push(['']);

  infoExcel.push([
    `Totais (${data.length}) registros`,
    atendimentos,
    conversoes,
    ''
  ]);

  const fileName = `Desempenho Individual - ${vendedorNome} - Período de ${dataInicial} até ${dataFinal}`;
  await salvarXlsx(infoExcel, fileName);
}
