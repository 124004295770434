import { Component } from 'react';
import { fbDatabase, fbFirestore, fireBase } from '../firebase/firebase';
import { currentUser, logout } from 'components/auth/auth-provider';

import API from 'services/api/api';
import history from '../history';

const api = new API();


class PrivateMethods extends Component {
  async getByParamValue(collection, param, value) {
    const snapshot = await fbDatabase
      .collection(collection)
      .where(param, "==", value)
      .get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmJSX-PrivateMethods-getByParamValue');
    xRef.set({
      collection: 'gerentes',
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(snapshot.size),
      lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    if (snapshot.empty === true) {
      return undefined;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
    }));
    return data;
  }

  interceptMethodCalls(_this) {
    const obj = Object.getPrototypeOf(_this);

    Object.getOwnPropertyNames(obj).forEach(methodName => {
      const method = obj[methodName];

      if (
        typeof method === 'function' &&
        methodName !== 'constructor' &&
        method.constructor.name === 'AsyncFunction'
      ) {
        const originalMethod = method;

        obj[methodName] = async (...args) => {
          await this.checkRefreshToken();
          return Reflect.apply(originalMethod, _this, args);
        }
      }
    });
  }

  async checkRefreshToken() {
    const { uid, stsTokenManager } = currentUser();
    const { refreshToken } = stsTokenManager;

    const revoked = await api.checkRefreshToken(uid, refreshToken);

    if (revoked) {
      logout();

      await fireBase.auth().signOut();

      history.push('/home/?sessionRevoked=true');
      history.go();
    }
  }
}

export default class DataManager extends Component {
  state = {
    collection: "",
    orderBy: [],
  };

  constructor(props) {
    super(props);
    new PrivateMethods().interceptMethodCalls(this);
  }

  formatarMoeda(valor) {
    valor = parseFloat(valor).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
    return valor;
  }

  async getById(id) {
    let snapshot = await fbDatabase
      .collection(this.state.collection)
      .doc(id)
      .get();

    if (!snapshot.docs && snapshot.data()) {
      snapshot.size = 1;
    }

    if (!snapshot.exists) {
      return undefined;
    }

    return {
      key: snapshot.id,
      ...snapshot.data(),
    };
  }

  async getByGestorId(id) {
    let snapshot = fbDatabase
      .collection(this.state.collection)
      .where("gestorId", "==", id);

    if (this.state.orderBy.length > 0) {
      for (let index = 0; index < this.state.orderBy.length; index++) {
        const order = this.state.orderBy[index];
        snapshot = snapshot.orderBy(order);
      }
    }

    snapshot = await snapshot.get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmJSX-DataManager-getByGestorId-' + this.state.collection);
    xRef.set({
      collection: this.state.collection,
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(snapshot.size),
      lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    if (snapshot.empty) {
      return undefined;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
    }));
    return data;
  }

  async add(item) {
    return new Promise(async (resolve, reject) => {
      await fbDatabase
        .collection(this.state.collection)
        .add({
          ...item,
        })
        .then((res) => {
          resolve({
            recorded: true,
            id: res.id,
          });
        })
        .catch((err) => {
          console.log(err);
          reject({
            recorded: false,
            err,
          });
        });
    });
  }

  async update(key, item) {
    return new Promise((resolve, reject) => {
      fbDatabase
        .collection(this.state.collection)
        .doc(key)
        .update({
          ...item,
        })
        .then((res) => {
          resolve({
            updated: true,
            message: "Ok",
          });
        })
        .catch((err) => {
          console.log(err);
          reject({
            updated: false,
            message: "Erro",
            err,
          });
        });
    });
  }

  async getByParamValue(param, value) {
    const privateMethods = new PrivateMethods();
    const data = await privateMethods.getByParamValue(
      this.state.collection,
      param,
      value
    );
    return data;
  }
}
