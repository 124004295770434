import React from 'react';
import { withRouter } from 'react-router'
import { Table, Icon, Button, Modal, Input, Tooltip, Checkbox, message } from 'antd';
import { getGestorId, getUserType } from '../../components/auth/auth-provider';

import ProdutoCRUD from '../../components/cruds/produtos/ProdutoCRUD';
import BluveLayout from '../../components/layout/BluveLayout';
import history from '../../history';
import listObj from '../../components/listSearch/listSearch';
import mensagem from 'components/message/Message';
import API from 'services/api/api';

import 'antd/dist/antd.css';
import './Produto.css';

const { warning } = Modal;
const { Search } = Input;
const api = new API();
let interval;

class Produto extends React.Component {
  state = {
    loading: true,
    isSearching: false,
    checked: undefined,
    qtdProdutosInicial: 50,

    produtos: [],
    produtosOriginal: [],
    produtosInativos: [],
    produtosInativosOriginal: [],

    searchStr: '',
    searchKeyWords: [],
    searchResult: [],

    selectedKey: 'todos'
  };

  constructor(props) {
    super(props);

    this.headerProdutoElement = React.createRef();

    this.getProdutosByLetra = this.getProdutosByLetra.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.updateList = this.updateList.bind(this);
    this.allProducts = this.allProducts.bind(this);
    this.checkboxUpdate = this.checkboxUpdate.bind(this);
    this.loadMore = this.loadMore.bind(this);

    this.getCheckboxState = this.getCheckboxState.bind(this);
    this.changeLoading = this.changeLoading.bind(this);
    this.updateKey = this.updateKey.bind(this);
  }

  componentDidMount() {
      document.addEventListener('tokenAvailable', () => {
        this.retornarProdutos(true)
          .then((produtos) => {
            this.setState({
              produtos,
              produtosOriginal: produtos,
              loading: false
            });
          })
          .catch((error) => {
            message.error("Erro ao buscar produtos.");
            this.setState({ loading: false });
            console.log(error);
          });
      });
  }

  retornarProdutos(ativo, lastDocId, limiteCustom) {
    return new Promise((resolve, reject) => {
      const { qtdProdutosInicial } = this.state;
      const gestorId = getGestorId();
      const limite = limiteCustom ?? qtdProdutosInicial;
      api.getProdutos(gestorId, limite, ativo, lastDocId)
        .then((produtos) => {
          resolve(produtos);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getProdutosByLetra(ativo, lastDocId, limiteCustom) {
    const {
      qtdProdutosInicial,
      selectedKey
    } = this.state;

    const gestorId = getGestorId();

    const limite = limiteCustom ?
      Math.floor(limiteCustom / 2) :
      Math.floor(qtdProdutosInicial / 2);

    const produtos = await api.getProdutosByLetra(
      gestorId,
      selectedKey,
      limite,
      ativo,
      lastDocId
    );

    return produtos;
  }

  async applyFilter(text, finalText, words, selectedKey) {
    this.setState({ searchStr: finalText, searchKeyWords: words });

    const keys = ['codigo', 'nome', 'ativoStr'];

    let result = listObj.search(this.state.produtosOriginal, text, keys);

    result = result.length > 10 ? result.slice(0, 10) : result;

    const gestorId = getGestorId();
    const codigos = result.map((p) => p.key);
    const limite = 150 - result.length;

    const produtosDatabase = await api.getProdutosByPalavraChave(
      gestorId,
      words.join(),
      codigos.join(),
      limite
    );

    const produtos = produtosDatabase.concat(result);

    produtos.sort((a, b) => (a.nome > b.nome ? 1 : -1));

    this.setState({ produtos, isSearching: true, searchResult: produtos });

    if (selectedKey !== 'todos') {
      const filteredProducts = produtos.filter(
        (p) => p.busca.charAt(0) === selectedKey
      );
      this.setState({ produtos: filteredProducts });
    }

    if (produtos.length === 0) {
      return {
        content: 'Sua busca não retornou nenhum resultado. Por favor utilize palavras-chave para encontrar o produto que deseja.',
        checkbox: true
      };
    } else {
      return {
        content: 'Caso não encontre o produto desejado, por favor, refine sua busca.',
        checkbox: false
      };
    }
  }

  clearFilter(selectedKey) {
    let produtos = this.state.produtosOriginal;

    if (selectedKey !== 'todos') {
      produtos = produtos.filter((p) => p.busca.charAt(0) === selectedKey);
    }

    this.setState({
      isSearching: false,
      produtos,
      searchStr: '',
      searchKeyWords: [],
    });
  }

  updateList(record, merge = true) {
    let lista = [];

    if (merge) {
      record['ativoStr'] = record['ativo'] ? 'Sim' : 'Não';
      let registroEncontrado = false;
      let listaAtivos = this.state.produtosOriginal;
      let listaInativos = this.state.produtosInativosOriginal;

      if (listaAtivos) {
        listaAtivos.forEach((item, index) => {
          if (item.key === record.key) {
            if (!record['ativo']) {
              listaAtivos.splice(index, 1);
            } else {
              listaAtivos[index] = record;
            }
            registroEncontrado = true;
          }
        });
      }

      if (listaInativos.length) {
        listaInativos.forEach((item, index) => {
          if (item.key === record.key) {
            if (record['ativo']) {
              listaAtivos.push(record);
            }

            listaInativos[index] = record;
            registroEncontrado = true;
          }
        });
      }

      if (!registroEncontrado && record['ativo']) {
        listaAtivos.unshift(record);
        if (listaInativos.length) listaInativos.unshift(record);
      } else if (!registroEncontrado && !record['ativo'] && listaInativos.length) {
        listaInativos.push(record)
      }

      this.setState({
        produtos: listaAtivos,
        produtosOriginal: listaAtivos,
        produtosInativos: listaInativos,
        produtosInativosOriginal: listaInativos
      });
    } else {
      const produtosInativos = this.state.selectedKey === 'todos' ?
        this.state.produtosInativosOriginal :
        this.state.produtosInativos;

      this.setState({
        produtosInativos,
        produtos: record,
        checked: false
      });
    }
  }

  allProducts() {
    const produtos = this.state.produtosOriginal;
    return produtos;
  }

  async checkboxUpdate() {
    this.setState({ loading: true });

    const {
      checked,
      selectedKey,
      produtosInativosOriginal
    } = this.state;

    if (!checked && selectedKey !== 'todos') {
      this.updateList(await this.getProdutosByLetra(true), false);
    } else if (selectedKey !== 'todos') {
      const produtosInativos = await this.getProdutosByLetra(false);
      this.setState({ produtosInativos });
    } else if (checked && produtosInativosOriginal.length === 0) {
      this.retornarProdutos(false)
        .then((produtosInativos) => {
          this.setState({
            produtosInativos,
            produtosInativosOriginal: produtosInativos
          });
        })
        .catch((error) => {
          console.log(error);
          message.error("Erro ao buscar produtos.");
        });
    }

    this.setState({ loading: false });
  }

  async loadMore() {
    this.setState({ loading: true });

    const {
      produtos,
      produtosOriginal,
      produtosInativos,
      produtosInativosOriginal,
      selectedKey,
      checked
    } = this.state;

    const isTodos = selectedKey === 'todos';
    const limite = checked ? 25 : 50;

    let dataAtivos = [];
    let dataInativos = [];

    if (produtos.length) {
      const lastDocIdAtivos = produtos[produtos.length - 1].key;

      dataAtivos = isTodos ?
        await this.retornarProdutos(true, lastDocIdAtivos, limite) :
        await this.getProdutosByLetra(true, lastDocIdAtivos, limite);
    }

    if (produtosInativos.length) {
      const lastDocIdInativos = produtosInativos[produtosInativos.length - 1].key;

      dataInativos = isTodos ?
        await this.retornarProdutos(false, lastDocIdInativos, limite) :
        await this.getProdutosByLetra(false, lastDocIdInativos, limite);
    }

    if (dataAtivos.length === 0 && dataInativos.length === 0) {
      mensagem.avisar('Não existe mais produtos a serem carregados');
      this.setState({ loading: false });
      return;
    }

    const newTableAtivos = dataAtivos.length > 0 ? produtos.concat(dataAtivos) : produtos;
    const newTableInativos = dataInativos.length > 0 ? produtosInativos.concat(dataInativos) : produtosInativos;

    this.setState({
      loading: false,
      produtos: newTableAtivos,
      produtosOriginal: isTodos ? newTableAtivos : produtosOriginal,
      produtosInativos: newTableInativos,
      produtosInativosOriginal: isTodos ? newTableInativos : produtosInativosOriginal
    });
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };

  editarProdutoElement(record) {
    this.headerProdutoElement.current.editarProduto(record);
  }

  getCheckboxState(checked) {
    this.setState({ checked })
    this.checkboxUpdate();
  }

  changeLoading(loading) {
    this.setState({ loading });
  }

  async updateKey(key) {
    this.setState({ selectedKey: key });
  }

  render() {
    const columns = [
      {
        title: 'Código',
        dataIndex: 'codigo',
        key: 'codigo',
      },
      {
        title: 'Nome',
        dataIndex: 'nome',
        key: 'nome',
      },
      {
        title: 'Ativo?',
        dataIndex: 'ativoStr',
        key: 'ativo',
      },
      {
        title: '',
        width: '20px',
        key: 'action',
        render: (text, record) => (
          <span>
            <Tooltip placement='topLeft' title='Editar Cadastro'>
              <a onClick={() => this.editarProdutoElement(record)}>
                <Icon type='edit' />
              </a>
            </Tooltip>
          </span>
        ),
      },
    ];

    return (
      <div className='divTable'>
        <BluveLayout selectItem={'produtos'}>
          <Table
            title={() => (
              <HeaderProduto
                ref={this.headerProdutoElement}

                isSearching={this.state.isSearching}
                produtos={this.state.searchResult}
                limit={this.state.qtdProdutosInicial}

                applyFilter={this.applyFilter}
                clearFilter={this.clearFilter}
                getCheckboxState={this.getCheckboxState}

                allProducts={this.allProducts}
                changeLoading={this.changeLoading}
                updateList={this.updateList}
                updateKey={this.updateKey}
              />
            )}

            footer={() => (
              <Button
                type='primary'
                loading={this.state.loading}
                onClick={this.loadMore}
                disabled={this.state.isSearching}
              >
                Carregar mais Produtos
              </Button>
            )}

            dataSource={
              this.state.checked ?
                this.state.produtos.concat(this.state.produtosInativos) :
                this.state.produtos
            }

            columns={columns}
            scrollHeight='160'
            loading={this.state.loading}
            pagination={false}
            sticky
          />
        </BluveLayout>
      </div>
    );
  }
}

class HeaderProduto extends React.Component {
  constructor(props) {
    super(props);

    this.novoProdutoElement = React.createRef();
    this.readFromFileElement = React.createRef();
    this.todosBtn = React.createRef();

    this.novoProduto = this.novoProduto.bind(this);
    this.filter = this.filter.bind(this);
    this.resetTime = this.resetTime.bind(this);
    this.importarProdutos = this.importarProdutos.bind(this);
    this.onAlphabetClick = this.onAlphabetClick.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.resetSelectedKey = this.resetSelectedKey.bind(this);

    this.state = {
      visible: false,
      checked: false,
      selectedKey: 'todos'
    };
  }

  novoProduto() {
    this.novoProdutoElement.current.show(false);
  }

  editarProduto(record) {
    this.novoProdutoElement.current.show(true, record);
  }

  async filter(text) {
    clearInterval(interval);
    this.setState({ searchStr: text });

    if (text === '') {
      this.props.clearFilter(this.state.selectedKey);
      return;
    }

    if (text.length < 3) {
      mensagem.avisar('A busca deve ser feita por palavras inteiras que possuam ao menos 3 caracteres.');
      return;
    }

    let finalText = text.replace(/_/g, ' ');
    finalText = finalText.replace(/\//g, ' ');
    finalText = finalText.replace(/-/g, ' ');
    finalText = finalText.normalize('NFD').replace(/[^\w\s!?]/g, ' ');
    const words = finalText.split(' ');

    let finalWords = [];

    words.map((word) => {
      if (word.length > 2) {
        finalWords.push(word);
      }
    });

    const searchWarning = await this.props.applyFilter(
      text,
      finalText,
      finalWords,
      this.state.selectedKey
    );

    if (searchWarning) {
      mensagem[searchWarning.checkbox ? 'avisar' : 'avisarCheckbox'](searchWarning.content);
    }
  }

  async resetTime(obj) {
    clearInterval(interval);
    interval = setInterval(this.filter, 3000, obj.target.value);
  }

  importarProdutos() {
    history.push('/importar-produtos');
    history.go();
  }

  async onAlphabetClick(e) {
    this.props.changeLoading(true);
    this.setState({ checked: false });

    e.persist();

    const key = e.target.value.toLowerCase();
    let produtos = [];

    if (!this.props.isSearching) {
      if (key === 'todos') {
        produtos = await this.props.allProducts();
      } else {
        const { limit } = this.props;
        const gestorId = getGestorId();

        try {
          const promises = [];
          promises.push(api.getProdutosByLetra(gestorId, key, Math.floor(limit / 2), true));

          const [produtosByLetra] = await Promise.all(promises);

          produtos = produtosByLetra;
        } catch (error) {
          console.log(error);
          message.error("Erro ao buscar produtos.");
          this.props.changeLoading(false);
          return;
        }
      }
    } else {
      this.props.produtos.forEach((p) => {
        if (key === 'todos') {
          produtos = this.props.produtos;
        } else {
          if (p.nome.charAt(0).toLowerCase() === key) {
            produtos.push(p);
          }
        }
      });
    }

    this.props.changeLoading(false);
    this.props.updateList(produtos, false);
    this.props.updateKey(key);
    this.setState({ selectedKey: key });
  }

  prepareAlphabets = () => {
    let result = [];
    result.push(
      <Button
        ref={this.todosBtn}
        type={this.state.selectedKey === 'todos' ? 'primary' : 'outline'}
        key='todos'
        value={'todos'}
        onClick={this.onAlphabetClick}
      >
        {'Todos'}
      </Button>
    );
    for (let i = 65; i < 91; i++) {
      result.push(
        <Button
          type={this.state.selectedKey === String.fromCharCode(i).toLowerCase() ? 'primary' : 'outline'}
          key={i}
          onClick={this.onAlphabetClick}
          value={String.fromCharCode(i)}
        >
          {String.fromCharCode(i)}
        </Button>
      );
    }
    return result;
  };

  async onCheck(e) {
    await this.setState({ checked: e.target.checked });
    this.props.getCheckboxState(this.state.checked);
  }

  resetSelectedKey() {
    this.setState({ selectedKey: 'todos' });
  }

  render() {
    return (
      <div>
        <NovoProduto
          ref={this.novoProdutoElement}
          updateList={this.props.updateList}
          resetSelectedKey={this.resetSelectedKey}
        />

        <div className='noteMessageBox'>
          Os produtos são públicos para todas as empresas
        </div>

        <div className='searchContainer'>
          <Button
            type='primary'
            onClick={this.novoProduto}
          >
            <Icon className='icon' type='plus' /> Novo Produto
          </Button>

          <Button
            onClick={this.importarProdutos}
            style={{ marginLeft: '1rem' }}
          >
            <Icon type='download' />
            Importar
          </Button>

          <Search
            allowClear
            placeholder='Procurar por Palavra-Chave'
            onSearch={(value) => this.filter(value)}
            onChange={this.resetTime}
            style={{ marginLeft: '1rem', marginRight: '1rem', width: 300 }}
          />

          <div className='alphabet'>
            {this.prepareAlphabets()}
          </div>
        </div>

        <div className='checkboxContainer'>
          <Checkbox checked={this.state.checked} onChange={this.onCheck}>
            Listar Inativos
          </Checkbox>
        </div>
      </div>
    );
  }
}

class NovoProduto extends React.Component {
  state = {
    closable: true,
    visible: false,
    confirmLoading: false,
    editMode: false,
    record: [],
  };

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onCloseHandle = this.onCloseHandle.bind(this);
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record,
    });
  }

  async handleOk(record) {
    this.props.updateList(record);
    this.props.resetSelectedKey();
    this.setState({ visible: false, closable: true });
  }

  onCloseHandle(canClose) {
    this.setState({ closable: canClose });
  }

  async handleCancel() {
    if (!this.state.closable) return;
    if (!(await mensagem.confirmar('Cancelar alterações?'))) return;
    this.setState({ visible: false, closable: true });
  }

  render() {
    return (
      <Modal
        title='Produto'
        visible={this.state.visible}
        destroyOnClose={true}
        confirmLoading={this.state.confirmLoading}
        centered={true}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        closable={this.state.closable}
      >
        <ProdutoCRUD
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          editMode={this.state.editMode}
          record={this.state.record}
          onCloseHandle={this.onCloseHandle}
        ></ProdutoCRUD>
      </Modal>
    );
  }
}

export default withRouter(Produto);
