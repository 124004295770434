import React from 'react';
import { withRouter } from 'react-router'
import { Table, Icon, Button, Modal, Input, Tooltip, Checkbox, message } from 'antd';
import { getGestorId, getUserType } from '../../components/auth/auth-provider';
import './ForaDeMix.css';
import "antd/dist/antd.css";
import ForaMixCRUD from "../../components/cruds/foramix/ForaMixCRUD";
import BluveLayout from "../../components/layout/BluveLayout";
import history from "../../history";
import listObj from "../../components/listSearch/listSearch";
import mensagem from "components/message/Message";
import API from 'services/api/api';

const { warning } = Modal;
const { Search } = Input;
const api = new API();


class ForaDeMix extends React.Component {
  state = {
    itens: [],
    itensOriginal: [],
    itensLista: [],
    loading: true,
    checked: undefined
  };

  constructor(props) {
    super(props);
    this.applyFilter = this.applyFilter.bind(this);
    this.updateList = this.updateList.bind(this);
    this.getCheckboxState = this.getCheckboxState.bind(this);
    this.checkboxUpdate = this.checkboxUpdate.bind(this);
    this.headerItemElement = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('tokenAvailable', () => this.getItens());
  }

  async applyFilter(text) {
    if (text === "") {
      this.setState({ itens: this.state.itensOriginal });
      return;
    }

    const keys = ["nome", "ativoStr"];

    let itens = [];
    if (!this.state.checked && this.state.itensLista && this.state.itensLista.length > 0) {
      itens = listObj.search(this.state.itensLista, text, keys);
    } else {
      itens = listObj.search(this.state.itensOriginal, text, keys);
    }

    this.setState({ itens });
  }

  updateList(record) {
    record["ativoStr"] = record["ativo"] ? "Sim" : "Não";
    let registroEncontrado = false;
    let listaAtivos = [];
    let listaInativos = [];

    if (this.state.checked) {
      listaInativos = this.state.itens;
      listaAtivos = this.state.itensLista;
    } else if (this.state.itensLista.length) {
      listaAtivos = this.state.itens;
      listaInativos = this.state.itensLista;
    } else {
      listaAtivos = this.state.itens;
    }

    if (listaAtivos) {
      listaAtivos.forEach((item, index) => {
        if (item.key === record.key) {
          if (!record['ativo']) {
            listaAtivos.splice(index, 1);
          } else {
            listaAtivos[index] = record;
          }
          registroEncontrado = true;
        }
      });
    }

    if (listaInativos.length) {
      listaInativos.forEach((item, index) => {
        if (item.key === record.key) {
          if (record['ativo']) {
            listaAtivos.push(record);
          }

          listaInativos[index] = record;
          registroEncontrado = true;
        }
      });
    }

    if (!registroEncontrado && record['ativo']) {
      listaAtivos.unshift(record);
      if (listaInativos.length) listaInativos.unshift(record);
    } else if (!registroEncontrado && !record['ativo'] && listaInativos.length) {
      listaInativos.push(record)
    }

    if (this.state.checked) {
      this.setState({ itens: listaInativos, itensOriginal: listaInativos, itensLista: listaAtivos });
    } else {
      this.setState({ itens: listaAtivos, itensOriginal: listaAtivos, itensLista: listaInativos });
    }
  }

  getCheckboxState(checked) {
    this.setState({ checked }, () => {
      this.checkboxUpdate();
    });
  }

  async checkboxUpdate() {
    if (this.state.itensLista.length || !this.state.checked && this.state.itens) {
      this.setState({ loading: true });
      const itensLista = this.state.itensLista;
      const itens = this.state.itens;

      this.setState({ itens: itensLista, itensOriginal: itensLista, itensLista: itens, loading: false })
    } else if (this.state.checked) {
      this.setState({ loading: true });
      let itens = this.state.itens;
      const gestorId = getGestorId();

      api.get({ gestorId, ativo: false }, '/fora-mix')
        .then((novaLista) => {
          if (itens && novaLista) {
            this.setState({ itensLista: itens });
            itens = itens.concat(novaLista);
          } else if (novaLista) {
            itens = novaLista;
          } else {
            this.setState({ itensLista: itens });
          }

          this.setState({ itens: itens, itensOriginal: itens, loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
          message.error("Erro ao buscar itens fora de mix");
        });
    }
  }

  async getItens() {
    const gestorId = getGestorId();

    api.get({ gestorId, ativo: true }, '/fora-mix')
      .then((itens) => {
        this.setState({ itens, itensOriginal: itens, loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        message.error("Erro ao buscar itens fora de mix");
      });
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };

  editarItemElement(record) {
    this.headerItemElement.current.editarItem(record);
  }

  render() {
    const columns = [
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
      },
      {
        title: "Ativo?",
        dataIndex: "ativoStr",
        key: "ativo",
      },
      {
        title: "",
        width: "20px",
        key: "action",
        render: (text, record) => (
          <span>
            <Tooltip placement="topLeft" title="Editar Cadastro">
              <a onClick={() => this.editarItemElement(record)}>
                <Icon type="edit" />
              </a>
            </Tooltip>
          </span>
        ),
      },
    ];

    return (
      <div className="divTable">
        <BluveLayout selectItem={"fora-de-mix"}>
          <div className="noteMessageBox">
            <p>Se houver um ou mais itens cadastrados aqui, os vendedores da rede não estarão 
              livres para digitar o que quiserem na seção "Fora de Mix" no controle de Fila da Vez. 
              Em vez disso, precisarão pesquisar neste cadastro, como ocorre com produtos. 
              Tenha em mente também, que esta opção afeta todas as lojas da sua rede.</p>{" "}
          </div>

          <Table
            pagination={{
              defaultPageSize: 100,
              position: "both",
              pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
              showSizeChanger: true,
              locale: { items_per_page: "" },
            }}
            title={() => (
              <HeaderItem
                ref={this.headerItemElement}
                applyFilter={this.applyFilter}
                updateList={this.updateList}
                getCheckboxState={this.getCheckboxState}
              />
            )}
            columns={columns}
            dataSource={this.state.itens}
            loading={this.state.loading}
            bordered
          />
        </BluveLayout>
      </div>
    );
  }
}

class HeaderItem extends React.Component {
  constructor(props) {
    super(props);
    this.novoItemElement = React.createRef();

    this.state = {
      visible: false,
      checked: false,
      loading: false
    };

    this.novoItem = this.novoItem.bind(this);
    this.filterTable = this.filterTable.bind(this);
    this.onCheck = this.onCheck.bind(this);

    let timer;
  }

  novoItem() {
    this.novoItemElement.current.show(false);
  }

  editarItem(record) {
    this.novoItemElement.current.show(true, record);
  }

  filterTable(value) {
    const { applyFilter } = this.props;

    const text = typeof value === "object" ? value.target.value : value;
    if (text && text.length < 3) return;

    let time = 1000;


    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.setState({ loading: true });
      applyFilter(text);
      this.setState({ loading: false });
    }, time);
  }

  onCheck(e) {
    const checked = e.target.checked;
    this.setState({ checked }, () => {
      this.props.getCheckboxState(checked);
    });
  }
  
  render() {
    return (
      <div>
        <NovoItem
          ref={this.novoItemElement}
          updateList={this.props.updateList}
        ></NovoItem>
        <Button type="primary" onClick={this.novoItem}
        ><Icon className="icon" type="plus" /> Novo Item
        </Button>

        <Search
          placeholder="Procurar"
          onSearch={this.filterTable}
          onChange={this.filterTable}
          loading={this.state.loading}
          style={{ width: 200, marginLeft: "10px", marginRight: '1rem' }}
        />

        <Checkbox checked={this.state.checked} onChange={this.onCheck}>
          Listar Inativos
        </Checkbox>

      </div>
    );
  }
}

class NovoItem extends React.Component {
  state = {
    closable: true,
    visible: false,
    confirmLoading: false,
    editMode: false,
    record: [],
  };

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onCloseHandle = this.onCloseHandle.bind(this);
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record,
    });
  }

  handleOk(record) {
    this.props.updateList(record);
    this.setState({ visible: false, closable: true });
  }

  onCloseHandle(canClose) {
    this.setState({ closable: canClose });
  }

  async handleCancel() {
    if (!this.state.closable) return;
    if (!(await mensagem.confirmar("Cancelar alterações?"))) return;
    this.setState({ visible: false, closable: true });
  }

  render() {
    return (
      <Modal
        title="Fora de Mix"
        visible={this.state.visible}
        destroyOnClose={true}
        confirmLoading={this.state.confirmLoading}
        centered={true}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        closable={this.state.closable}
      >
        <ForaMixCRUD
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          editMode={this.state.editMode}
          record={this.state.record}
          onCloseHandle={this.onCloseHandle}
        ></ForaMixCRUD>
      </Modal>
    );
  }
}

export default withRouter(ForaDeMix);
