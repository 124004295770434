import React from 'react';
import { withRouter } from 'react-router';
import BluveLayout from '../../../components/layout/BluveLayout';
import { Select, Table, Button, Checkbox, message, DatePicker } from "antd";
import { getEmpresas, buildReport, getVendedores, exportToExcel } from './Funcs-EvolucaoVendedores';
import { getGestorId } from '../../../components/auth/auth-provider';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import bluveStorage from '../../../components/localstorage/localstorage'

import { Bar } from "react-chartjs-2";
import { Chart } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "chart.piecelabel.js";

import './EvolucaoVendedores.css'
import { COLUMNS_WITHOUT_MANAGER } from 'pages/parceiro/loja/LojaConsts';
import Item from 'antd/lib/list/Item';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY'
const { Option } = Select;

const ATENDIMENTOS = "ATENDIMENTOS";
const CONVERTIDOS = "CONVERTIDOS";
const TAXACONVERSAO = "TAXA DE CONVERSÃO"

const CINZA = "#9d9e9d";
const VERDE = "#9966ff";
const LARANJA = '#ebb134';


class EvolucaoVendedores extends React.Component {

  state = {
    reportData: [],
    empresas: [],
    empresaId: '',
    vendedor: {
      id: '',
      nome: '',
    },
    dataSelecionada: moment(),
    loading: false,
    periodo: {},
    alterouConfiguracaoRelatorio: false,
    chartData: {},
    showVendedoresSelect: false
  }

  vendedoresOptions = [];
  options = [];

  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.onSelectVendedorChange = this.onSelectVendedorChange.bind(this);
    this.getVendedoresLocal = this.getVendedoresLocal.bind(this);
  }

  async componentDidMount() {
    const gestorId = await getGestorId();

    const storage = bluveStorage.getEmpresasGestor('@gestorEmpresas');
    let empresas = [];

    if (storage && storage.gestorId === gestorId) {
      empresas = storage.empresas;
    } else {
      empresas = await getEmpresas();

      const data = {
        gestorId: gestorId,
        empresas: empresas
      };

      bluveStorage.setEmpresasGestor(data);
    }

    this.setState({ empresas })

    for (const item of empresas) {
      this.options.push(<Option key={item.key} value={item.key}>{item.nomeFantasia}</Option>);
    }

    let empresaId = bluveStorage.getReportEmpresaId();

    if (!empresaId && empresas.length > 0) {
      empresaId = empresas[0].key;
    }

    let periodo = bluveStorage.getReportPeriodo();
    if (!periodo) {
      periodo = {
        inicial: moment().subtract(7, "days"),
        final: moment(),
      };
    } else {
      periodo.inicial = moment(periodo.inicial);
      periodo.final = moment(periodo.final);
    }

    const data = bluveStorage.getReportEvolucaoVendedores();

    let listVendedores = [];
    let reportData = [];

    if (data && data.reportData) {
      reportData = data.reportData;
    }

    if (data && data.chartData) {
      const vendedor = data.vendedor;
      listVendedores = data.listVendedores;

      const chartData = data.chartData;
      if (chartData) {
        this.mountChart(chartData);
      }

      this.setState({
        vendedor,
      })
    }

    if (listVendedores.length === 0) {
      listVendedores = await this.getVendedoresLocal(empresaId)
    }

    if (listVendedores) {
      for (let v of listVendedores) {
        v.nome = v.props ? v.props.children : v.nome;
        this.vendedoresOptions.push(<Option key={v.key} value={v.key}>{v.nome}</Option>)
      }
    }

    this.setState({
      empresaId,
      periodo,
      reportData
    });
  }

  onDateChange(value) {
    const periodo = {
      inicial: value[0],
      final: value[1]
    }
    this.setState({ periodo, alterouConfiguracaoRelatorio: true });
  }

  getColor(position) {
    //let color = ['#1a535c', '#ffe66d', '#cad2c5', '#4ecdc4', '#ff6b6b', ]
    let color = ["#00a7e1", '#7C848D', '#ebb134', /*'#0A172A'*/ '#A8B3BF', /*'#0D74D6',*/  '#ffe66d']

    if (position > color.length - 1) {
      const sobra = ((position / color.length) - Math.floor(position / color.length)).toFixed(1);
      position = Math.round(sobra * color.length);
    }

    return color[position]
  }

  onSelectVendedorChange(value, object) {
    const vendedor = {
      id: value,
      nome: object.props.children
    }
    this.setState({ vendedor });
  }

  async getVendedoresLocal(empresaId) {
    const empresas = this.state.empresas;

    if (!empresas.length > 0) {
      return;
    }

    const empresaIndex = empresas.findIndex((empresa) => empresa.key === empresaId);
    let vendedores = [];

    if (!empresaIndex || (empresas[empresaIndex] && !empresas[empresaIndex].vendedores)) {
      vendedores = await getVendedores(empresaId);
      empresas[empresaIndex].vendedores = vendedores;
    } else if (empresas[empresaIndex]) {
      vendedores = empresas[empresaIndex].vendedores;
    }

    return vendedores;
  }

  async onSelectChange(value) {

    this.vendedoresOptions = [];

    if (value !== 'todas') {
      this.setState({ alterouConfiguracaoRelatorio: true });
    }

    const vendedores = await this.getVendedoresLocal(value);

    if (vendedores) {
      for (const item of vendedores) {
        this.vendedoresOptions.push(<Option key={item.key} value={item.key}>{item.nome}</Option>)
      }
    }

    this.setState({
      empresaId: value,
      showCheckBox: value === 'todas',
      checked: value === 'todas',
      vendedor: {}
    });
  }

  mountChart(chartData) {
    //const labels = data.datas;
    const dataSets = [
      {
        label: ATENDIMENTOS,
        data: [],
        backgroundColor: CINZA
      },
      {
        label: CONVERTIDOS,
        data: [],
        backgroundColor: VERDE
      },
      {
        label: TAXACONVERSAO,
        data: [],
        backgroundColor: LARANJA
      },

    ]

    let labels = [];

    chartData.forEach((item) => {
      dataSets[0].data.push(item.atendimentos);
      dataSets[1].data.push(item.conversoes);
      dataSets[2].data.push(item.taxaConversao);
      const date = new Date(item.data);
      labels.push(date.getDate() + "/" + (date.getMonth() + 1))
    })

    const x = {
      labels: labels,
      datasets: dataSets
    }

    this.setState({ chartData: x, loading: false })

  }

  async onFilterClick() {

    if (!this.state.periodo.inicial || !this.state.periodo.final) {
      message.warning("Selecione o período para gerar o gráfico.");
      return;
    }

    this.setState({ loading: true });
    bluveStorage.setReportEmpresaId(this.state.empresaId);

    if (this.state.alterouConfiguracaoRelatorio) {
      bluveStorage.clearAllReportsData();
    }

    bluveStorage.setReportPeriodo(this.state.periodo);

    const reportData = await buildReport(
      this.state.empresaId,
      this.state.periodo.inicial,
      this.state.periodo.final,
      this.state.vendedor.id);

    this.setState({ reportData });
    this.mountChart(reportData);

    bluveStorage.setReportEvolucaoVendedores(
      reportData, 
      this.state.vendedor, 
      this.vendedoresOptions,
      reportData);

    this.setState({ loading: false, showVendedoresSelect: true })
  }

  onExportClick() {
    const empresaFiltrada = this.state.empresas.filter(item => item.key === this.state.empresaId)[0];
    exportToExcel(
      this.state.reportData,
      `${empresaFiltrada.razaoSocial} (${empresaFiltrada.nomeFantasia})`,
      this.state.vendedor.nome, 
      this.state.periodo);
  }

  render() {
    return (
      <BluveLayout selectItem={"relatorio/desempenho-individual"}>

        <div className="containerEvolucao">
          <label>Empresas: </label>
          <Select
            disabled={this.state.loading}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "180px", marginTop: '50px', marginBottom: '15px' }}
            placeholder='Selecione uma empresa'
            onChange={this.onSelectChange}
            value={this.state.empresaId}
          >
            {this.options}
          </Select>

          <label>Vendedor: </label>
          <Select
            disabled={this.state.loading || this.vendedoresOptions.length === 0}
            showSearch
            optionFilterProp="children"
            style={{ width: '180px' }}
            placeholder='Selecione um vendedor'
            onChange={this.onSelectVendedorChange}
            value={this.state.vendedor.nome}>{this.vendedoresOptions}
          </Select>

          <label>Período: </label>
          <div className="rangePickerDuplo">
            <RangePicker
              style={{ width: '100%' }}
              locale={locale}
              disabled={this.state.loading}
              format={dateFormat}
              placeholder={['Data Inicial', 'Data Final']}
              onChange={this.onDateChange}
              value={[this.state.periodo.inicial, this.state.periodo.final]}
            />
          </div>
          <Button type='primary' loading={this.state.loading} onClick={this.onFilterClick}>Filtrar</Button>

          <Button
              className='excel-button'
              disabled={this.state.loading || this.state.reportData.length === 0}
              onClick={this.onExportClick}
              style={{ marginLeft: '1rem' }}
            >
              Exportar para Excel
            </Button>


        </div>

        <div style={{ marginTop: 20 }}>

          <Bar
            data={this.state.chartData}

            options={{
              plugins: {
                datalabels: {
                  display: true,
                  anchor: "end",
                  align: "top",
                  formatter: function (value, context) {
                    if (context.dataset.label === TAXACONVERSAO) {
                      return value + "%"
                    }
                  }
                },
              },

              tooltips: {
                callbacks: {
                  label: function (tooltipItem, data) {

                    let label = data.datasets[tooltipItem.datasetIndex].label;
                    let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                    const index = data.datasets.findIndex((item) => item.label === TAXACONVERSAO);
                    if (tooltipItem.datasetIndex === index) {
                      value = value + '%';
                    }

                    return ' ' + label + ': ' + value
                  }
                }
              },

              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                      stepSize: 10
                    },
                    gridLines: {
                      display: false
                    },
                  },
                ],
                /*xAxes: [{
                    offset: true,
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            day: 'DD/MM'
                        },
                        distribution: 'series',
                        unitStepSize: 1,
                        ticks: {
                            source:"labels",
                        },


                    },

                }]     */

              },
              title: {
                display: true,
                text: this.state.vendedor.nome ? this.state.vendedor.nome : '',
                fontSize: 20,
                padding: 25
              },
              legend: {
                display: true,
                position: 'top',

              },
            }}
          />
        </div>

      </BluveLayout>
    )
  }
}

export default withRouter(EvolucaoVendedores);
