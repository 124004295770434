import { message } from 'antd';
import { fireBase, fbDatabase, FILA_URL } from '../../firebase/firebase';
import { authenticate, currentUser } from '../../components/auth/auth-provider';
import { isEmail } from 'components/funcs/utils';
import mensagem from 'components/message/Message';
import API from '../../services/api/api';

const api = new API();

async function getUsuario(uid) {
  const usuario = await fbDatabase.collection("usuarios")
    .where('uid', '==', uid)
    .get();
  return usuario;
}


export async function autenticar(email, senha) {
  try {
    if (!isEmail(email)) {
      if ((await mensagem.confirmarCustom(
        'O Login informado não é um e-mail válido para gerentes! Caso você esteja tentando entrar no sistema de Fila, clique no botão "Conectar a Fila da Vez".',
        'Login Inválido',
        'Conectar ao Fila da Vez',
        'Voltar'
      ))) {
        const url = `${FILA_URL}?login=${email}`;
        window.open(url, '_blank');
      }

      return false;
    }

    const obj = await fireBase.auth().signInWithEmailAndPassword(email, senha);

    // Controle de acesso
    // O controle de acesso irá registrar o novo idToken
    const { uid, refreshToken } = obj.user;
    await api.accessControl(uid, refreshToken);

    if (obj) {
      let userType = 'Gestor';

      // Estamos passando a ter muitas tabelas (Gerente, Indicador, etc).
      // Ficar lendo de cada tabela é prejudicial ao sistema.
      // Mas para manter a compatibilidade com o código antigo,
      // Farei essa validação aqui no login. Se permanecer lento, será somente na primeira vez

      let usuario = await getUsuario(obj.user.uid);

      // Se não encontrar o usuário, faço as buscas
      if (usuario.empty) {
        let item = undefined;

        // Primeiro, verifico se é um gerente
        usuario = await fbDatabase.collection("gerentes")
          .where('email', '==', email).get();

        // Se não for, vejo se é um indicador
        if (usuario.empty) {
          usuario = await fbDatabase.collection("indicadores")
            .where('email', '==', email).get();

          if (!usuario.empty) {
            userType = 'Indicador';
          }

          item = {
            uid: obj.user.uid,
            tipo: userType,
          }
        }
        else {
          userType = 'Gerente';
          item = {
            id: usuario.docs[0].id,
            ativo: usuario.docs[0].data().ativo,
            uid: obj.user.uid,
            tipo: userType,
            gestorId: usuario.docs[0].data().gestorId,
            lastPasswordUpdate: new Date()
          }
        }

        await fbDatabase.collection('usuarios').add({
          ...item
        });
      }
      else {
        userType = usuario.docs[0].data().tipo;
      }

      if (userType === 'Indicador') {
        // eslint-disable-next-line no-throw-literal
        throw { code: 'auth/user-not-found' };
      } else if (userType === 'Gestor') {
        authenticate(obj.user, userType, undefined, undefined, usuario.docs[0].data().is4CAdmin);
      } else {
        const ativo = usuario.docs[0].data().ativo;
        if (ativo !== false) {
          authenticate(
            obj.user,
            userType,
            usuario.docs[0].data().gestorId,
            usuario.docs[0].data().id ? usuario.docs[0].data().id : usuario.docs[0].id,
            usuario.docs[0].data().is4CAdmin);
        } else {
          // eslint-disable-next-line no-throw-literal
          throw { code: 'auth/user-disabled' };
        }
      }

      return true;
    }

  } catch (error) {
    await fireBase.auth().signOut();

    // Handle Errors here.
    if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password' || error.code === 'auth/user-disabled') {
      message.error('Senha ou email incorretos. Verifique.');
    }

    if (error.code === 'auth/too-many-requests') {
      message.error('Você excedeu o número de tentativas de login. Por favor, tente novamente mais tarde.');
    }

    return false;
  }
}

export async function loginPrimeiroAcesso(token) {
  const { user } = await fireBase
    .auth()
    .signInWithCustomToken(token);

  if (user) {
    const usuario = await getUsuario(user.uid);
    const userType = usuario.docs[0].data().tipo;

    authenticate(user, userType, undefined, undefined, usuario.docs[0].data().is4CAdmin);
  }
}

export async function redefinePassword(email) {
  return new Promise((resolve) => {
    fireBase.auth().sendPasswordResetEmail(email)
      .then(function () {
        resolve({
          success: true,
          message: 'Ok',
        });
      }).catch(function (error) {
        if (error.code === "auth/invalid-email") {
          error.message = 'Email inválido';
        }
        // An error happened.
        resolve({
          success: false,
          message: error.message,
        });
      });
  });
}

export function redirectJustIfUserIsAuthenticated() {
  const user = currentUser();
  if (user) {
    this.props.history.push('/dashboard');
  }
}
