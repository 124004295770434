import DataManager from "./dtm";
import { fbDatabase, fbFirestore } from "../firebase/firebase";

async function queryBy10(ids, dataIni, dataFim) {
  const snapshot = await fbDatabase
    .collection("atendimentos")
    .where("data", ">=", dataIni)
    .where("data", "<=", dataFim)
    .where("empresaId", "in", ids)
    .get();

  /** X-TUDO */
  let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmAtendimentoJSX-queryBy10');
  xRef.set({
    collection: 'atendimentos',
    lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
    count: fbFirestore.FieldValue.increment(snapshot.size),
    lastIncrement: snapshot.size
  }, { merge: true }).catch(error => console.error(error));

  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map((doc) => doc.get("horas"));
}

class AtendimentoDB extends DataManager {
  state = {
    collection: "atendimentos",
    orderBy: [], // usado na classe pai
  };

  atendimentos = fbDatabase.collection(this.state.collection);

  async getHorasByPeriodo(dataIni, dataFim, customParamName, customParamValue) {
    let query = this.queryBase(customParamName, customParamValue);
    query = this.filterByPeriodo(query, dataIni, dataFim);

    let result = await query.get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmAtendimentoJSX-AtendimentoDB-getHorasByPeriodo');
    xRef.set({
      collection: 'atendimentos',
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(result.size),
      lastIncrement: result.size
    }, { merge: true }).catch(error => console.error(error));

    return result !== undefined
      ? result.docs.map((doc) => doc.get("horas"))
      : result;
  }

  async getHorasByPeriodoAndEmpresas(dataIni, dataFim, empresasIds) {
    let horas = [];
    let ids = [];
    for (let index = 0; index < empresasIds.length; index++) {
      ids.push(empresasIds[index].key);
      if (ids.length >= 10) {
        const data = await queryBy10(ids, dataIni, dataFim);
        horas.push.apply(horas, data);
        ids = [];
      }
    }
    if (ids.length > 0) {
      const data = await queryBy10(ids, dataIni, dataFim);
      horas.push.apply(horas, data);
    }

    return horas;
  }

  async _getAtendimentos(filter, value, inicio, fim, limit = 0) {
    let query = this.queryBase(filter, value);
    query = this.filterByPeriodo(query, inicio, fim);
    query = query.orderBy("data", "desc");

    query = limit > 0 ? query.limit(limit) : query;

    let result = await query.get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmAtendimentoJSX-AtendimentoDB-_getAtendimentos');
    xRef.set({
      collection: 'atendimentos',
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(result.size),
      lastIncrement: result.size
    }, { merge: true }).catch(error => console.error(error));

    if (result.empty) {
      return false;
    }

    return result.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  }

  async getEmpresaAtendimentos(empresaId, inicio, fim) {
    const result = await this._getAtendimentos(
      "empresaId",
      empresaId,
      inicio,
      fim
    );
    return result;
  }

  async getAtendimentos(gestorId, fim, inicio = null, limit = 0) {
    const result = await this._getAtendimentos(
      "gestorId",
      gestorId,
      inicio,
      fim,
      limit
    );
    return result;
  }

  queryBase(customParamName, customParamValue) {
    return this.atendimentos.where(customParamName, "==", customParamValue);
  }

  filterByPeriodo(query, inicio = null, fim = null) {
    query = inicio ? query.where("data", ">=", inicio) : query;
    query = fim ? query.where("data", "<=", fim) : query;

    return query;
  }

  async get(query) {
    const snapshot = await query.get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmAtendimentoJSX-AtendimentoDB-get');
    xRef.set({
      collection: 'atendimentos',
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(snapshot.size),
      lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    if (snapshot.empty) {
      return undefined;
    }

    return snapshot;
  }
}

const atendimentoDB = new AtendimentoDB();
export default atendimentoDB;
