import axios from 'axios';
import { API_ROUTE } from '../../components/constants.js';

const instance = axios.create({
    baseURL: API_ROUTE,
    headers: {
        'Content-Type': 'application/json'
    }
});

export default instance;